import React from 'react';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import {createAd} from "../api";
import Cookies from 'js-cookie';


class SaljaVirkeButton extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            visibleModal:false,
            show_thanks:false,
            loading: false,
            selectedArrangement: null
        }
    }

    save(){
        if(this.state.selectedArrangement.value == "grot"){
            this.setState({loading:false, show_thanks:false, visibleModal: false});
            window.open("/biobransle/", "_blank");
        }
        else{
            this.setState({loading:true});
        
            let req = createAd({"arrangement": this.state.selectedArrangement.value});
            req.then(resp => resp.json()).then((json) => {
                if(this.props.extra_ad_data){
                    const headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    }
                
                    fetch("/auctions-api/auctions/" + json.ad_id + "/", {
                        method: 'PATCH',
                        credentials: 'same-origin',
                        headers: headers,
                        body: JSON.stringify(this.props.extra_ad_data)
                    }).then(resp => resp.json()).then((json) => {
                        this.setState({loading:false, show_thanks:true});
                    });
                }
                else{
                    this.setState({loading:false, show_thanks:true});
                }
            });
        }
    }


    render(){

        return (<React.Fragment>
            <VBLoadingScreen show_loading={this.state.loading} />

            {!this.state.visibleModal ? <a href="#" style={this.props.style} onClick={() => {this.setState({visibleModal:true})}} className={this.props.className}>{this.props.children}</a> :null}
            <Modal show={this.state.visibleModal} onHide={() => this.setState({ visibleModal: false })} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
                <Modal.Header className="align-items-start border-0" closeButton>
                    <h2 className="supp-forest-green n-h4">Är det dags att sälja virke?</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    {this.state.show_thanks ? <p className="neutral-charcoal n-fs-5">Tack, vi kontaktar dig inom kort!</p> :
                <div className="container-flex">
                    <div className="row">
                          <div className="col-12">
                            
                            <p className="neutral-charcoal">Att sälja virke på Treebula är helt gratis och du har helt fri prövningsrätt. Det vill säga är du inte nöjd med anbuden kan du bara tacka nej. Tusentals skogsägare använder Treebula när de ska ska sälja virke. </p>
                            <p className="neutral-charcoal">Vi berättar gärna mer om hur det funkar, ange bara vad det är du ska göra nedan så ringer vi upp dig och berättar mer. Du binder dig inte till något utan får bara mer information om hur du säljer virke på Treebula</p>
                          </div>
                          <div className="col-12"><a className="neutral-charcoal" href="/referenser/">Läs en artikel om en av våra kunder här</a></div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-12">
                          <VBDropdown onChange={(val) => {this.setState({selectedArrangement:val})}} options={[{"value": "slutavverkning", "label": "Slutavverkning"}, {"value": "gallring", "label": "Gallring"}, {"value": "grot", "label": "Biobränsle eller GROT"}]} label={<p className='charcoal py-3'>Vilken åtgärd är det dags för?</p>}/>
                          </div>
                      </div>
                    </div> }
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end">
                    {!this.state.show_thanks ? <button type="button" className="n-btn btn-forest-green" data-bs-dismiss="modal" onClick={() => {this.save()}}>Skapa annons</button> :null}
                    <button type="button" className="n-btn btn-forest-green btn-outline text-nowrap w-auto" data-bs-dismiss="modal" onClick={() => {this.setState({visibleModal:false, show_thanks:false})}}>{this.state.show_thanks ? "Okej" : "Avbryt"}</button>
                </Modal.Footer> 

            </Modal>
        </React.Fragment>);
    }
}


export default SaljaVirkeButton;