import React, { Component } from 'react';
import VBBasicLeaflet from '@virkesborsen/vb-js-commons/lib/components/new/vbBasicLeaflet';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import lang_obj from '../../../treebula/price-map-translations.json';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';
import TextEditor from '../editor/fields/TextEditor';

class PricingMap extends Component  {
    constructor(props){
        super(props);

        this.state={
            core_data: [],
            leaflet_data: [],
            currency: Window.react_data.lists.language_code == 'se' ? 'SEK' : Window.react_data.lists.language_code == 'no' ? 'NOK' : Window.react_data.lists.language_code == 'pl' ? 'PLN' : 'EUR',
            treeSort: 'soft_timber',
        }
    }

    handleInputChange(value, name) {
        this.setState({ [name]: value });

        setTimeout(() => { this.updateMapData() }, 100)
    }

    selectCountryName(country) {
        if(lang_obj[country] && lang_obj[country][this.props.language.toUpperCase()]) {
            return lang_obj[country][this.props.language.toUpperCase()]
        } else {
            return country
        } 
    }

    updateMapData() {
        let data = [];

        Object.entries(this.state.core_data).forEach(([name, values]) => {
            let country = {}
            country.name = this.selectCountryName(name);
            country.coords = values.coords
            country.currency = this.state.currency
            country.price = values.prices[this.state.treeSort][this.state.currency]
            data.push(country)
        })
        this.setState({ leaflet_data: data });
    }

    markerFunc(item, container) {
        window.L.popup({autoClose:false, closeButton:false, closeOnClick: false})
            .setLatLng(item.coords)
            .setContent('<p class="my-0 text-center"><b>'+item.name+'</b><br />'+item.currency+' <span class="fw-bold blue h4">'+item.price+'</span></p>')
            .addTo(container);
    }

    componentDidMount() {
        // Get the prices from the main prices.json file
        fetch('https://treebula-bucket.s3.eu-west-1.amazonaws.com/current/prices.json').then(resp => resp.json()).then(data => { 
            this.setState({ core_data: data });
            setTimeout(() => { this.updateMapData() }, 100)
        })
    }

    render() {
        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${this.props.backgroundColor}`}>
                <div className='container'>
                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4 px-0 px-md-4">
                                <p className={`${this.props.labelColor} n-fs-5 mb-1 mt-3`}>Rundvirke sortiment:</p>
                                <VBDropdown 
                                initial={this.state.treeSort}
                                options={[{'label': ('Softwood timber'), 'value': 'soft_timber'},{'label': ('Softwood pulpwood'), 'value': 'soft_pulp'},{'label': ('Birch/leaf pulpwood'), 'value': 'hard_pulp'}]}
                                onChange={(e) => {this.handleInputChange(e.value, 'treeSort');}} />
                            </div>
                            <div className="col-12 col-md-4 px-0 px-md-4 mt-2 mt-md-0">
                                <p className={`${this.props.labelColor} n-fs-5 mb-1 mt-3`}>Valuta:</p>
                                <VBDropdown 
                                initial={this.state.currency} 
                                options={[{'label': 'EUR - Euro', 'value': 'EUR'},{'label': 'NOK - Norsk krone', 'value': 'NOK'},{'label': 'SEK - Svenska kronor', 'value': 'SEK'},{'label': 'PLN - Zloty', 'value': 'PLN'}]} 
                                onChange={(e) => {this.handleInputChange(e.value, 'currency');}} />
                            </div>
                        </div>
                    </div>
                {this.state.leaflet_data.length > 0 ? <div className="col-12 mt-5">
                    <div style={{height: '500px'}}>
                        <VBBasicLeaflet className="shadow rounded" mapid={this.props.id} mapOptions={{maxZoom: 7, minZoom: 4}} setView={[60.591688, 16.962891]} setZoomView={4} basemapName="Mapbox Plain" markers={this.state.leaflet_data} markerFunc={this.markerFunc}/>
                    </div>
                </div> : null }
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center mt-3">
                        <div className='d-block quill-text my-3' >{this.props.priceCalc}</div>
                        <div className='d-block quill-text my-3' >{this.props.copySources}</div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

PricingMap.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    labelColor: 'neutral-charcoal-500',
    priceCalc:'*Pris per solide kubikkmeter ved skogen ved siden av vegen. Priser vises som gjennomsnitt over dimensjoner og kvaliteter. Priser kan være forskjellig avhengig av treets målings metodologi, kvalitets fordeling og andre faktorer. Priser blander auksjoner, spotpris etc.',
    copySources: '**Vi får dataen vår fra flere kilder globalt og vi legger stor vekt på å være sikker på at de kommer fra store troverdige organisasjoner eller byråer. Vår vekslingskurs er hentet fra Eurostat med den daværende prisen på den tiden vi oppdaterte prisene våre.',
    language: "SE"
};

PricingMap.fields = {
    backgroundColor: {
      type: "custom",
      render: (props) => <ColorSelector {...props} />
    },
    visibility: {
      type: "select",
      options: Opt.visibilityOptions
    },
    labelColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
    },
    priceCalc: { 
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} /> 
    },
    language: {
        type: "select",
        options: [
            {label: "English", value:"EN"},
            {label: "Estonian", value: "ET"},
            {label: "Latvian", value: "LV"},
            {label: "Lithuanian", value: "LT"},
            {label: "Polish", value:"PL"},
            {label: "German", value: "DE"},
            {label: "Norwegian", value: "NO"},
            {label: "Swedish", value: "SE"},
            {label: "Finnish", value: "FI"}
        ]
    },
    copySources: { 
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} /> 
    },
};

export default PricingMap;