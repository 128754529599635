import React from "react";
import { CalculateSpacing } from "../editor/Helpers";
import * as Opt from "../editor/Helpers"
import MediaSelector from '../editor/fields/MediaSelector';

class Button extends React.Component {
    constructor() {
        super();

        this.state = {

        };
    }

    render() {
        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : ''} d-flex flex-wrap w-100 ${this.props.horizontalAlignContent} align-items-start`}>
                {this.props.buttons.map((button) => {
                    return <a href={button.href} style={{ minWidth: button.minWidth }} className={`n-btn d-inline-block ${button.fullWidthOnMobile} ${button.size} ${button.variant} ${button.style} ${CalculateSpacing(button.spacing)} `} target={`${button.target}`} >
                            <span>{button.iconBefore && <img className={`me-2 ${button.iconColor}`} style={{ width: "18px", height: "18px" }} src={button.iconBefore} />}</span>{button.text}
                            <span>{button.iconAfter && <img className={`ms-2 ${button.iconColor}`} style={{ width: "18px", height: "18px" }} src={button.iconAfter} />}</span>
                        </a>
                })}
            </div>
            )
    }
}

Button.defaultProps = {
    visibility: "",
    horizontalAlignContent: "justify-content-between",
    buttons: [{
        text: "Action",
        href: "/",
        variant: "btn-core",
        target: "_self",
        style: "",
        size: "",
        spacing: [],
        iconBefore: null,
        iconAfter: null,
        iconColor: "white-filter",
        minWidth: "",
        fullWidthOnMobile: ""
    }]
};

Button.fields = {
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    horizontalAlignContent: {
        type: "select",
        options: Opt.contentJustify
    },
    buttons: {
        type: "array",
        arrayFields: {
            href: { type: "text" },
            text: { type: "text" },
            variant: { type: "select", options: Opt.buttonOptions },
            target: { type: "select", options: Opt.targetOptions },
            style: { type: "select", options: Opt.buttonStyle },
            size: { type: "select", options: Opt.buttonSize },
            spacing: Opt.spacingOptions,
            iconBefore: {
                type: "custom",
                render: ({ name, onChange, value }) => <MediaSelector name={'iconBefore'} type={'icon'} onChange={onChange} value={value} />,
            },
            iconAfter: {
                type: "custom",
                render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
            },
            iconColor: {
                type: "select",
                options: Opt.filterOptions
            },
            minWidth: {
                type: "text"
            },
            fullWidthOnMobile: {
                type: "radio",
                  options: [
                    { label: "No", value: "" },
                    { label: "Yes", value: "w-100 w-lg-auto" },
                  ],
            }
        },
        defaultItemProps: Button.defaultProps.buttons[0],
        getItemSummary: (button) => "Button",
    }

}
export default Button;