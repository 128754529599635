import React from 'react';
import { CalculateSpacing } from '../editor/Helpers';
import * as Opt from '../editor/Helpers'
import MediaSelector from '../editor/fields/MediaSelector';
import RadiusEditor from '../editor/fields/RadiusEditor';

class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            clicked: (props.thumbnail == ""),
            video: null
        }
    }

    componentDidMount(){
        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        var inter = setInterval(() => {
            try{
                var player;

                player = new YT.Player(this.props.id, {
                height: '390',
                width: '640',
                videoId: this.props.src.replace("https://www.youtube.com/embed/", ""),
                playerVars: {
                    'playsinline': 1
                },
                events: {
                    'onReady': () => {this.setState({video: player})}
                }
                }); 
                clearInterval(inter);
            } catch(err){}
        }, 50)
        
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.thumbnail != prevProps.thumbnail){
            this.setState({
                clicked: (this.props.thumbnail == "")
            })
        }
    }

    
    render() {
        return (
            <div className={`position-relative ${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-block'} ${(Array.isArray(this.props.borderRadius)?this.props.borderRadius:[]).reduce((acum, itm) => acum+itm.radius+" ", "")} ratio ratio-16x9`}>
                <div className="w-100 h-100" id={this.props.id}></div>
                {this.state.clicked ? null :<img src={this.props.thumbnail} alt='Click to play' onClick={() => {this.state.video.playVideo(); this.setState({clicked:true})}} style={{cursor:"pointer"}} className="img-fluid h-100 w-100 position-absolute top-0 start-0" />}
                </div>
        );
    }
}


Video.defaultProps = {
    src: "https://www.youtube.com/embed/yc4Y9ODQbd0?si=oU8cY71OoTn9CcgP",
    visibility: "",
    title: "video",
    align: "justify-content-center",
    spacing: [],
    borderRadius: [],
    thumbnail: "",
}

Video.fields={
    src: {
        type:"text"
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    title: {
        type:"text"
    },
    borderRadius: {
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    },
    align: {
        type: "select",
        options: Opt.contentJustify
    },
    spacing: Opt.spacingOptions,
    thumbnail: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
    }
}

export default Video;