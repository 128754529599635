import React from 'react';
import MediaSelector from '../editor/fields/MediaSelector';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class ImgContentCard extends React.Component {
    constructor() {
        super();

        this.state = {

        };

    }

    render() {
        return (
                
                    <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} `}>
                    <div className='container'>
                    
                        <div className={`d-flex flex-column-reverse flex-lg-row rounded-5 overflow-hidden bg-${this.props.backgroundColor}`}>
                            {/* Text here */}
                            <div className={`d-flex align-items-start flex-column col-12 col-lg-6 p-lg-5 p-3 ${this.props.reverseOnDt === "yes" ? 'order-lg-1' : 'order-lg-2'}`}>
                                <p className={`mb-0 n-fs-6 fw-medium ${this.props.subheadingColor}`}>{`${this.props.subheading}`}</p>
                                <p className={`${this.props.headingColor} n-fs-2 mt-3 mb-4`}>{`${this.props.heading}`}</p>
                                <p className={`n-fs-5 mb-0 ${this.props.textColor}`}>{`${this.props.text}`}</p>

                                {/* Button here */}
                                {this.props.showButton === "yes" && (
                                    <div className=' mt-auto mb-3 mt-4 pt-4'>
                                        <a href={this.props.href} className={`n-btn ${this.props.buttonSize} ${this.props.buttonStyle} ${this.props.buttonOptions}`}>
                                            {this.props.buttonText}
                                            <span>{this.props.buttonIconAfter && <img className={`ms-1 ${this.props.buttonIconColor}`} style={{ width: "18px", height: "18px" }} src={this.props.buttonIconAfter} />}</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                            {/* Image here */}
                            <div className={`col-12 col-lg-6 ${this.props.reverseOnDt === "yes" ? 'order-lg-2' : 'order-lg-1'}`}>
                                <img alt={this.props.alt} src={`${this.props.src}`} className={`img-fluid vb-rounded ${this.props.reverseOnDt === "yes" ? 'rounded-sb' : 'rounded-eb'}  opacity-${this.props.imageOpacity}`} style={{ filter: this.props.imageGrayScale === "yes" ? "grayscale(100%)" : "", width: "100%", height: "100%" }}></img>
                            </div>
                            </div>
                        </div>
                    </div>
                
        )

    }

}

ImgContentCard.defaultProps = {
    visibility: "",
    backgroundColor: "neutral-stone-500",
    src: "https://placehold.co/1200x1200",
    imageOpacity: "75",
    imageGrayScale: "yes",
    reverseOnDt: "no",
    alt: "scenic image",
    subheading: "Tagline goes here",
    heading: "Long heading goes here and can also extend all the way to here.",
    text: "At eu sit integer fermentum diam pellentesque pulvinar ipsum senectus. Purus vulputate congue eu aliquam massa elementum. Quis amet tempor egestas vel in.",
    showButton: "yes",
    buttonText: "Action",
    href: "/",
    buttonSize: "",
    buttonStyle: "btn-outline",
    buttonOptions: "btn-charcoal",
    buttonIconAfter: null,
    buttonIconColor: "neutral-charcoal-filter",
    subheadingColor: "neutral-charcoal",
    headingColor: "neutral-charcoal",
    textColor: "neutral-charcoal",
}

ImgContentCard.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
    },
    src: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
    },
    imageOpacity: {
        type: "select",
        options: Opt.opacityOptions
    },
    imageGrayScale: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    },
    reverseOnDt: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    },
    alt: {
        type: "text"
    },
    subheading: {
        type: "text"
    },
    subheadingColor: {
        type: "select",
        options: Opt.colorVariables
    },
    heading: {
        type: "text"
    },
    headingColor: {
        type: "select",
        options: Opt.colorVariables
    },
    text: {
        type: "text"
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    showButton: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    },
      buttonText: { type: "text" },
      href: { type: "text" },
      buttonSize: {
        type: "select",
        options: Opt.buttonSize
      },
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      }, 
      buttonOptions: {
        type: "select",
        options: Opt.buttonOptions
      },
      buttonIconAfter: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
      },
      buttonIconColor: {
        type: "select",
        options: Opt.filterOptions
      },
}
export default ImgContentCard;