import React from 'react';
import Joyride from 'react-joyride';

class HelpRisk extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            helpMapTools: false,
            run:this.props.visible,
            steps: [
                {
                    target: '#leaflet-layer-button',
                    content: 'Klicka här för att ändra karttyp samt lägga till kartdata och se observationer.',
                    disableBeacon: true,
                },
                {
                    target: "#risk-filter-button",
                    content: "Klicka här för att byta mellan Brandrisk och Granbarkborre risk i kartan.",
                    disableBeacon: true,
                }
            ]

        }
    }
    handleJoyrideClose = () => {
        this.props.onClose();
      };
    
    render() {
        const customLocale = {
            back: 'Tillbaka',
            close: 'Stäng',
            last: 'Kom igång',
            next: 'Nästa',
            open: 'öppna',
            skip: (
                <button style={{borderRadius:'4px', borderColor: 'black', backgroundColor: 'white', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px'}} onClick={this.handleJoyrideClose}>Hoppa över</button>
            )
        };
        return (<React.Fragment>
            <Joyride
                continuous={true}
                floaterProps={{ placement: "left" }}
                steps={this.state.steps}
                close={this.handleJoyrideClose}
                showSkipButton={true}
                scrollDuration={0}
                showProgress={true}
                spotlightClicks={true}
                locale={customLocale}
                styles={{
                    options: {
                        textColor: '#2a2e33',
                        primaryColor:'#023436',
                        overlayColor:'rgba(0, 0, 0, 0.6)',
                        zIndex:10000,
                    }
                }}
                callback={(data) => {
                    if (data.status === 'ready') {
                      this.handleJoyrideClose();
                    }
                    if (data.action === 'close'){
                        this.handleJoyrideClose();
                    }
                  }} />
   
        </React.Fragment>)
    }
}
export default HelpRisk;



