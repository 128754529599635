import React from 'react';
import * as Comp from './PuckComps';

import Alert from 'react-bootstrap/Alert';

class PuckPageView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alert: Window.react_data.alert
        }

    }

    render() {
        return (
            <>  
                {/* We show this alert if the page is not live but an Admin is viewing the page for review */}
                {this.state.alert ? <Alert className="text-center mx-3 mt-2 mb-0" variant="danger" onClose={() => this.setState({alert: false})} dismissible>
                    <Alert.Heading>Warning!</Alert.Heading>
                    <p className="mb-0">{this.state.alert}</p>
                </Alert>: null}

                {Window.react_data.content.map((component) => {
                    // Fetch the correct component from name (type) of the Puck data to render in the return
                    let Component = Comp[component.type]
                    if (component.type == 'Columns' || component.type == 'ColumnsMasonry') { //
                        // Fetch components from dropzones and place them inside the content prop for the columns component to render them within each column
                        component.props.columns.map((c, i) => {
                            c.content = Window.react_data.zones[component.props.id+':'+i]
                            c.content.map((cont) => {
                                if(cont.type == 'CardComponent'){
                                cont.props.content = Window.react_data.zones[cont.props.id+':'+cont.props.id]
                            }})
                        })  
                    }

                    if (component.type == 'CardComponent' || component.type == 'Container'){
                        component.props.content = Window.react_data.zones[component.props.id+':'+component.props.id]
                    }
                    
                    return <Component {...component.props} />
                })}
            </>
        )
    }
}

export default PuckPageView;