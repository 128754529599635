import React from 'react';
import * as Opt from '../components/editor/Helpers';
import { CalculateSpacing } from '../components/editor/Helpers';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBTextarea from "@virkesborsen/vb-js-commons/lib/components/new/vbTextarea";
import VBCheckbox from "@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox";
import { validateEmail } from '../api';
import Modal from 'react-bootstrap/Modal';
import ColorSelector from '../components/editor/fields/ColorSelector';
import MediaSelector from '../components/editor/fields/MediaSelector';
import Cookies from 'js-cookie';


class ContactForm extends React.Component{
    constructor(props){
        super(props);

        this.state={
            formData:{terms:false},
            messageSentModal: false,
            reloadForm:false
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let data = this.state.formData
        let validation = {}
        if (!data.name) {
            validation.name = "Du har inte angett ditt namn."
        }
        if ((!data.email || !validateEmail(data.email))) {
            validation.email = "Den här e-postadressen ser inte ut att vara korrekt";
        }
        if (!data.message) {
            validation.message = "Du har inte skrivit ditt meddelande"
        }
        if (!data.terms) {
            validation.terms = "Du måste acceptera våra villkor"
        }
        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        }
        else {

            try {
                let target = "/om-oss/kontakt/"
                data.to_email = this.props.to_email + "@virkesborsen.se"

                fetch(target, {
                    "method": "POST",
                    "body": JSON.stringify({ "data": data }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    },
                }).then(res => {
                    if (res.ok) {
                        this.setState({ messageSentModal: true, reloadForm: true , errors:{terms:false}})
                    } else {
                        alert('Oops! Något gick fel...')
                    }
                });
            } catch (error) {
                alert('Oops! Något gick fel...')
                console.log(error);
            }
        }
    }


    render(){
        return <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} p-0 bg-${this.props.backgroundColor} w-100`}>
            <div className={`container ${CalculateSpacing(this.props.spacing)}`}>
                <div className="row">
                    <div className="col-12">
                        {!this.state.reloadForm && <form onSubmit={this.handleSubmit.bind(this)}>
                            <div className="mb-3">
                                <VBInput 
                                    inputClass={`vb-rounded rounded-all ${this.props.fontColor}`}
                                    placeholder="Namn"
                                    onChange={(val) => {this.setState({formData:{...this.state.formData, name: val}})}}
                                    error={this.state.errors?.name}
                                />
                                </div>
                            <div className="mb-3">
                                <VBInput 
                                    inputClass={`vb-rounded rounded-all ${this.props.fontColor}`} 
                                    placeholder="E-post"
                                    onChange={(val) => {this.setState({formData:{...this.state.formData, email: val}})}}
                                    error={this.state.errors?.email}
                                />
                            </div>
                            <div className="mb-3">
                                    <VBTextarea 
                                    height="96px" 
                                    inputClass={`vb-rounded rounded-all ${this.props.fontColor}`} 
                                    placeholder="Ditt meddelande"
                                    onChange={(val) => {this.setState({formData:{...this.state.formData, message: val}})}}
                                    error={this.state.errors?.message}
                                />
                            </div>
                            <div className="mb-3">
                                <VBCheckbox 
                                    label={<label className={`${this.props.fontColor} n-fs-7`}>Jag har läst och godkänner Treebulas integritetspolicy och användarvillkor.{this.state.errors?.terms && <span className="highlight">({this.state.errors?.terms})</span>}</label>} 
                                    onClick={() => {this.setState({formData:{...this.state.formData, terms: !this.state.formData.terms}})}}
                                />
                            </div>
                            <button className={`n-btn w-100 w-lg-auto n-fs-6 ${this.props.buttonColor} ${this.props.buttonStyle}`} type="submit"><span>{this.props.iconBefore && <img className={`me-2 ${this.props.iconColor}-filter`} style={{ width: "18px", height: "18px" }} src={this.props.iconBefore} />}</span>Skicka
                            <span>{this.props.iconAfter && <img className={`ms-2 ${this.props.iconColor}-filter`} style={{ width: "18px", height: "18px" }} src={this.props.iconAfter} />}</span></button>
                        </form>}
                    </div>
                </div>
            </div>
            <Modal show={this.state.messageSentModal}
                onHide={() => { this.setState({ messageSentModal: false, formData: {}, reloadForm: false }) }}
                size="md" contentClassName="bg-white" centered>
                <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                    <h2 className="supp-charcoal mb-0">Skickat</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className="supp-charcoal mb-0">Ditt meddelande har skickats och vi kontaktar dig inom kort.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <button className="n-btn btn-forest-green btn-sm" onClick={() => this.setState({ messageSentModal: false, formData: {}, reloadForm: false })}>Stäng</button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

ContactForm.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    fontColor: 'neutral-charcoal',
    spacing: [],
    buttonStyle: "",
    buttonColor: "btn-forest-green",
    iconBefore: null,
    iconAfter: null,
    iconColor: "primary-core",
    to_email: "info"
}
ContactForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
      fontColor: {
        type: "select",
        options: Opt.colorVariables
      },
      spacing: Opt.spacingOptions,
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      },
      buttonColor: {
        type: "select",
        options: Opt.buttonOptions
      },
      iconBefore: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconBefore'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconAfter: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconColor: {
        type: "select",
        options: Opt.colorVariables
      },
      to_email: {
        label: 'To Email (Only part before @virkesborsen.se)',
        type:"text"
      }
}

export default ContactForm;