import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";

import Offcanvas from 'react-bootstrap/Offcanvas';

const SectionPopupDesktop = styled(Offcanvas)`
    transition: all ease 300ms;
    left: 0px !important;

    &.show {
        left: 270px !important;
    }
`

const SectionPopupMobile = styled(Offcanvas)`
    height: 80% !important;
    max-width: 500px !important;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    
    @media (min-width: 500px) {
        left: 50% !important;
        margin-left: -250px !important;
    }
`

const CustomScrollbar = styled.div`
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #023436;
        border-radius: 10px;
    }
`

class UpdateMap extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentSectionData: {},
            showPopup: false,
            errors: false
        }
    }

    updateData(data) {
        this.setState({
            currentSectionData: data,
            huggningKlass: data.felling_class,
            volume: data.volume,
            tall: data.tall,
            gran: data.gran,
            lov: data.lov,
            age: data.age,
            si: data.si,
            height: data.height,
            diameter: data.diameter,
            grun: data.grun,
            avdelningsnr:data.localID
        })
    }

    showPopupFunc(bool) {
        this.setState({ showPopup: bool })
    }

    //validate TGL
    validateTGL() {
        let sum = parseInt(this.state.tall) + parseInt(this.state.lov) + parseInt(this.state.gran);

        if (!(sum === 100)) { this.setState({ errors: true }) }
        else if (!(this.state.tall <= 100) && (this.state.tall >= 0)) { this.setState({ errors: true }) }
        else if (!(this.state.gran <= 100) && (this.state.gran >= 0)) { this.setState({ errors: true }) }
        else if (!(this.state.lov <= 100) && (this.state.lov >= 0)) { this.setState({ errors: true }) }
        else { this.setState({ errors: false }) }
    }

    //update to database
    handleSubmit(event) {
        event.preventDefault();
        const { huggningKlass, volume, tall, gran, lov, age, si, height, diameter, grun,avdelningsnr } = this.state

        if (this.state.errors === false) {
            try {
                let target = "/map-api/user-update-sections/"
                let nr = this.state.currentSectionData.nr;

                fetch(target, {
                    method: "POST",
                    body: JSON.stringify({
                        PropID: this.props.currentSectionData.lm_id,
                        nr: nr,
                        age: age,
                        si: si,
                        tall: tall,
                        gran: gran,
                        lov: lov,
                        volume: volume,
                        diameter: diameter,
                        height: height,
                        felling_class: huggningKlass,
                        grun: grun,
                        localID: avdelningsnr
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    },
                }).then(res => {
                    if (res.ok) {
                        res.json().then(json => {
                            if (this.props.onSave) {
                                this.props.onSave(json);
                            }
                        });
                    } else {
                        alert('Oops! Något gick fel...')
                    }

                });
            } catch (error) {
                console.log(error);
            }
        } else {
            alert('Summan av TGL kan inte vara över 100')
        }
    }

    populateSectionEdit() {
        let majorTree = "B"
        if (this.state.currentSectionData.tall > this.state.currentSectionData.gran && this.state.currentSectionData.tall > this.state.currentSectionData.lov) {
            majorTree = "T"
        } else if (this.state.currentSectionData.gran > this.state.currentSectionData.tall && this.state.currentSectionData.gran > this.state.currentSectionData.lov) {
            majorTree = "G"
        }

        return <CustomScrollbar className="ms-4 me-2 pe-2">
            <div className="bg-neutral-cloud border border-2 border-secondary-main subtitle rounded my-2 pb-1">
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row align-items-center g-0 p-2">
                    <div className="col-6 p-2">Avdelnings nr</div>
                    <div className="col-6 p-2">
                            <VBInput
                                value={this.state.avdelningsnr}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.localID}
                                type="number"
                                onChange={(val) =>  this.setState({ avdelningsnr: val })}/>
                        </div>

                        <div className="col-6 p-2">Huggningsklass</div>
                        <div className="col-6 p-2">
                            <VBDropdown
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.felling_class}
                                options={Window.react_data.lists.huggningklass}
                                onChange={(val) => { this.setState({ huggningKlass: val.value }) }} />
                        </div>

                        <div className="col-6 p-2">Totalvolym (m³sk)</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.volume}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.volume}
                                type="number"
                                onChange={(val) => this.setState({ volume: val })} />
                        </div>

                        <div className="col-6 p-2">Tall|Gran|Löv</div>
                        <div className="col-6 p-2">TGL %</div>

                        <div className="col-6 p-2">Tall %</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.tall}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.tall}
                                type="number"
                                error={this.state.errors ? "Summan av TGL kan inte vara över 100" : null}
                                onChange={(val) => { this.setState({ tall: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                        </div>

                        <div className="col-6 p-2">Gran %</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.gran}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.gran}
                                type="number"
                                error={this.state.errors ? "Summan av TGL kan inte vara över 100" : null}
                                onChange={(val) => { this.setState({ gran: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                        </div>

                        <div className="col-6 p-2">Löv %</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.lov}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.lov}
                                type="number"
                                error={this.state.errors ? "Summan av TGL kan inte vara över 100" : null}
                                onChange={(val) => { this.setState({ lov: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                        </div>

                        <div className="col-6 p-2">Ålder (år)</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.age}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.age}
                                type="number"
                                onChange={(val) => this.setState({ age: val })} />
                        </div>

                        <div className="col-6 p-2">SI</div>
                        <div className="col-6 p-2">
                            <VBDropdown
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={majorTree + this.props.currentSectionData.si}
                                options={Window.react_data.lists.siOptions}
                                onChange={(val) => { this.setState({ si: val.value }) }} />
                        </div>

                        <div className="col-6 p-2">Snitt höjd (m)</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.height}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.height}
                                type="number"
                                onChange={(val) => this.setState({ height: val })} />
                        </div>

                        <div className="col-6 p-2">Snitt diameter (cm)</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.diameter}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.diameter}
                                type="number"
                                onChange={(val) => this.setState({ diameter: val })} />
                        </div>

                        <div className="col-6 p-2">Grundyta (m³/ha)</div>
                        <div className="col-6 p-2">
                            <VBInput
                                value={this.state.grun}
                                inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                                placeholder={this.props.currentSectionData.grun}
                                type="number"
                                onChange={(val) => this.setState({ grun: val })} />
                        </div>

                        <div className="col-12"><button className='n-btn btn-forest-green rounded btn-sm fw-medium w-100 my-2' type="submit">SPARA</button></div>
                    </div>
                </form>
            </div>
        </CustomScrollbar>
    }

    render() {
        return (
            <React.Fragment>
                <SectionPopupDesktop className="d-none d-lg-block bg-neutral-cloud-100 py-2" show={this.state.showPopup} onHide={() => { this.setState({ showPopup: false }) }} placement='start'>
                    <div className="row subtitle g-0 px-4 py-2 my-3 bg-neutral-cloud hover-bg-neutral-cloud-700" role="button" onClick={() => this.setState({ showPopup: false })}>
                        <div className="col-12 d-flex justify-content-between align-items-center white">
                            <span className="fw-medium supp-forest-green">Redigera avdelning {this.props.currentSectionData.localID}</span>
                            <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="30" height="30" />
                        </div>
                    </div>

                    {this.populateSectionEdit()}
                </SectionPopupDesktop>

                <SectionPopupMobile className="d-lg-none bg-neutral-cloud-100 py-2" show={this.state.showPopup} onHide={() => { this.setState({ showPopup: false }) }} placement='bottom'>
                    <div className="row subtitle g-0 mx-4 my-2">
                        <div className="col-12 d-flex justify-content-center py-2" role="button" onClick={() => this.setState({ showPopup: false })}><img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-25" width="35" height="35" /></div>

                        <div className="col-6 supp-forest-green fw-medium ps-2">Redigera avdelning {this.props.currentSectionData.localID}</div>
                    </div>

                    {this.populateSectionEdit()}
                </SectionPopupMobile>
            </React.Fragment>
        )
    }
}

export default UpdateMap;