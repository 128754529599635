import React from 'react';
import VBLeaflet from "@virkesborsen/vb-js-commons/lib/components/vbLeaflet";
import {isMobile, isDesktop} from 'react-device-detect';

class NHLeafletChange extends React.Component {
    constructor(props) {
        super(props);

        this.map = React.createRef();

        this.state={
            mobile_map_visible:false,
            layers: {},
            imageOverlay: null
        }
    }

    componentDidMount(){
        if(this.props.geojson){
            const timeout = setTimeout(() => {
            if(this.map.current.state.map){
                let group = L.geoJSON(this.props.geojson, {
                    interactive: true,
                    style: (feature) => {
                        return {
                            color: "#FFFFFF",
                            fillColor:"transparent"
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        layer.addTo(this.map.current.state.map)
                    },
                })

                this.map.current.state.map.fitBounds(group.getBounds())
                
                clearTimeout(timeout);
            }
        }, 50)
        }

        if(this.props.sections){
            const t2 = setTimeout(() => {
            if(this.map.current.state.map){
                this.props.sections.map((sect) => {
                    this.addGeojsonToMap(sect.geom, null, sect.geom_color ? sect.geom_color : "#03A086", false)
                });
                
                clearTimeout(t2);
            }
        }, 50)
        }
    }
    removeLayer(key){

        this.map.current.state.drawn.removeLayer(this.state.layers[key])
        if(this.state.imageOverlay){
            this.map.current.state.map.removeLayer(this.state.imageOverlay)
        }
    }

    addImageOverlayToMap(imageOverlay){
        if(this.state.imageOverlay){
            this.map.current.state.map.removeLayer(this.state.imageOverlay)
        }

        imageOverlay.addTo(this.map.current.state.map);
        this.map.current.state.map.fitBounds(imageOverlay.getBounds());

        this.setState({
            imageOverlay: imageOverlay
        })
    }

    addGeojsonToMap(geojson, key=null, color="#f6f6f6", addToDrawn=true) {
        L.geoJSON(geojson, {
            interactive: true,
            style: (feature) => {
                return {
                    color: color ? color : "#f6f6f6",
                    fillColor: "transparent"
                };
            },
            onEachFeature: (feature, layer) => {
                if(addToDrawn){
                    layer.addTo(this.map.current.state.drawn)
                    layer.bringToFront()
                    this.state.layers[key] = layer
                } else{
                    layer.addTo(this.map.current.state.map)
                }
                
            },
        })
        this.setState({layers: this.state.layers});
        if(this.map.current.state.drawn.getBounds().isValid()){
            this.map.current.state.map.fitBounds(this.map.current.state.drawn.getBounds())
        }
    }

    render() {
        return (
                <VBLeaflet
                mapid="change"
                enableDrawControl={{
                    circle: false,
                    rectangle: false,
                    polyline: false,
                    edit: false,
                    circlemarker: false,
                    marker: false,
                }}
                mapOptions={{gestureHandling: isMobile}}
                style={{}}
                basemapName="Satellit"
                ref={this.map}
                enableLayers={true}
                hideToolkitButton={true}
                hideLeafletLayerButton={false}
                onClick={this.handleMapClick}
                onShapeCreate={(e, all_drawn) => { }}
                onShapeClick={(e, all_drawn) => { }}
                onDrawStart={(e) => { }}
                />
        )
    }
}
export default NHLeafletChange;