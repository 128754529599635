
const NHDelete = (props) => {
    if(!Window.react_data.is_editor){
        return null;
    }
    return <div className={props.className}><a className="d-inline-flex bg-neutral-cloud-500 rounded-circle p-2" style={{cursor:"pointer"}} onClick={() => {props.onClick()}}>
        <img src={`${Window.static_path}img/icons/cross.svg`} style={{width:"24px", height:"24px"}} />
        </a></div>
}

export default NHDelete;