import React from 'react';
import { CalculateSpacing, highlightWordsFunc } from '../editor/Helpers';
import * as Opt from '../editor/Helpers'

class Header extends React.Component {
    constructor() {
        super();
    }

    render() {

        let size = `n-${this.props.type}`
        if(this.props.fontSizes){
            size = "";
        }

        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''}`} style={{width:this.props.headerWidth}}>
                {this.props.highlight.words?.length > 0 ? (
                    React.createElement(
                        this.props.type,
                        {
                            className: `text-break ${size} ${this.props.textAlign} ${this.props.color} ${CalculateSpacing(this.props.spacing)} ${Opt.CalculateFontSize(this.props.fontSizes)} ${this.props.textStyle}`,
                            style: {whiteSpace: "pre-wrap"}
                        },
                        highlightWordsFunc(this.props.text, Array.from(this.props.highlight.words, (w) => w.word), this.props.highlight.color, this.props.highlight.backgroundColor, this.props.highlight.rounded, this.props.highlight.padding)
                    )
                ) : (
                    React.createElement(
                        this.props.type,
                        {
                            className: `text-break ${size} ${this.props.textAlign} ${this.props.color} ${CalculateSpacing(this.props.spacing)} ${Opt.CalculateFontSize(this.props.fontSizes)} ${this.props.textStyle}`,
                            style: {whiteSpace: "pre-wrap"}
                        },
                        this.props.text
                    )
                )}

            </div>
        )

    }

}

Header.defaultProps = {
    visibility: "",
    type: 'h1',
    color: 'neutral-charcoal',
    text: 'Heading',
    textAlign: "text-start",
    spacing: [],
    highlight: { words: [], color: 'neutral-charcoal', backgroundColor: 'white', rounded: '', padding: 'p-1' },
    headerWidth: "100%",
    textStyle: "",
    fontSize: '',
}

Header.fields = {
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    headerWidth: {
        type: "text",
    },
    text: {
        type: "textarea",
    },
    type: {
        type: "select",
        options: Opt.headerElements
    },
    color: {
        type: "select",
        options: Opt.colorVariables
    },
    textAlign: {
        type: "select",
        options: Opt.TextAlign
    },
    spacing: Opt.spacingOptions,
    highlight: Opt.highlightOptions,
    textStyle: {
        type: "select",
        options: Opt.TextStyles
    },
    fontSizes: Opt.fontSizeOptions,
}

export default Header;