import { useState } from "react";

const NHBox = (props) => {
    
    const [hideChildren, setHideChildren] = useState(false)

    if(props.hidden){
        return null;
    }

    let bg = 'bg-neutral-cloud-100'
    if(props.bg){
        bg = props.bg
    }

    return <div className={`corners-1-all ${bg} p-2 mb-3 position-relative`}>
        <div className="d-flex justify-content-between align-items-center">
            <h3 className="n-fs-5 mb-3">{props?.headline}</h3>
            <div>
                <p className="n-fs-7 d-flex align-items-center mb-0">
                    {props?.top_right}
                    {props.can_toggle ? <img onClick={() => {setHideChildren(!hideChildren)}} className="ms-2" src={`${Window.static_path}img/icons/caret.svg`} style={{height:"20px", cursor:"pointer", transition:"300ms", transform:(hideChildren ? "rotate(90deg)": "rotate(-90deg)")}} /> :null}
                </p>
                
            </div>
        </div>
        
        {hideChildren ? null : props.children}
        </div>;
}

export default NHBox;