import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartSection from './ChartSection';
import { Doughnut } from 'react-chartjs-2';

import PropTypes from "prop-types";
import styled from 'styled-components';

const PieRoot = styled.div`
    canvas {
        max-height: 280px;
        @media (min-width: 1200px) {
            max-height: 320px;
        }
    }
`

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
function PieChart({ title, description, topRight, data, legend, options, page, plugins }) {

    return (
        <div>
            <ChartSection
                title={title}
                description={description}
                page={page}
                component={
                    <div className="float-end text-end">
                        {topRight}
                    </div>
                }
            >
                <PieRoot>
                    <Doughnut
                        data={data}
                        options={options} />
                    {legend}
                </PieRoot>
            </ChartSection>
        </div>
    )
}


PieChart.propTypes = {
    topRight: PropTypes.string || PropTypes.node,
    title: PropTypes.string || PropTypes.node,
    description: PropTypes.string || PropTypes.node,
    legend: PropTypes.node,
};

PieChart.defaultProps = {
    topRight: null,
    title: undefined,
    description: undefined,
    legend: undefined,
}



export default PieChart;