import React from 'react';
import Navigation from '../components/static/Navigation';
import Footer from '../components/static/Footer';
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import { validateEmail } from '../api';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';

class ContactSellerPage extends React.Component {
    constructor() {
        super();
        this.state = {
            formData: {},
            errors: {},
            messageSentModal: false,
            reloadForm: false
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = this.state.formData
        data.ad_id = Window.react_data.ad_id
        let validation = {}

        if (!data.name) {
            validation.name = "Du har inte angett ditt namn."
        }
        if ((!data.email || !validateEmail(data.email))) {
            validation.email = "Den här e-postadressen ser inte ut att vara korrekt";
        }
        if (!data.message) {
            validation.message = "Du har inte skrivit ditt meddelande"
        }
        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        }
        else {
            try {
                let target = "/contact-seller/"

                fetch(target, {
                    "method": "POST",
                    "body": JSON.stringify({ "data": data }),
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': Cookies.get('csrftoken')
                    },
                }).then(res => {
                    if (res.ok) {
                        this.setState({ messageSentModal: true, reloadForm: true })
                    } else {
                        alert('Oops! Något gick fel...')
                    }
                });
            } catch (error) {
                alert('Oops! Något gick fel...')
            }
        }
    }


    render() {
        return (<div className="container-fluid p-0">
            <div className="bg-white">
                <Navigation />
                
                <div className='bg-white'>
                    <section className='container charcoal'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-10 col-lg-8'>
                                <h1 className="mb-4 d-flex justify-content-center">Kontakta säljaren</h1>

                                <h2 className='d-flex justify-content-center'>Skicka ett meddelande</h2>

                                {!this.state.reloadForm &&
                                    <form onSubmit={(e) => this.handleSubmit(e)}>
                                        <div className="row justify-content-center">
                                            <div className='mb-2'>
                                                <VBInput
                                                    type="text"
                                                    placeholder="Namn"
                                                    label={<label className='pb-1'>Ditt namn</label>}
                                                    error={this.state.errors.name}
                                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, name: val } }); }} />
                                            </div>
                                            <div className='mb-2'>
                                                <VBInput
                                                    type="email"
                                                    placeholder="Email"
                                                    label={<label className='pb-1'>Din email</label>}
                                                    error={this.state.errors.email}
                                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, email: val } }); }} />
                                            </div>
                                            <div className='mb-2'>
                                                <VBTextarea
                                                    type="text"
                                                    rows={8}
                                                    label={<label className='pb-1'>Ditt fråga</label>}
                                                    error={this.state.errors.message}
                                                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, message: val } }); }} />
                                            </div>
                                            <div className="d-flex justify-content-center mt-4">
                                                <button type="submit" className="btn btn-forest-green">Skicka fråga</button>
                                            </div>
                                        </div>
                                    </form>
                                }

                                <Modal show={this.state.messageSentModal}
                                    onHide={() => { this.setState({ messageSentModal: false, formData: {}, reloadForm: false }) }}
                                    size="md" contentClassName="bg-neutral-charcoal" centered>
                                    <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                                        <h2 className="primary-main mb-0">Skickat</h2>
                                    </Modal.Header>
                                    <Modal.Body className="py-0">
                                        <p className="white mb-0">Ditt meddelande skickas och vi kontaktar dig inom kort.</p>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-end border-0">
                                        <button className="btn btn-secondary btn-sm" onClick={() => this.setState({ messageSentModal: false, formData: {}, reloadForm: false })}>Stäng</button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>

        </div>)
    }
}
export default ContactSellerPage;