import React, { Fragment } from 'react';
import Cookies from 'js-cookie';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import Modal from 'react-bootstrap/Modal';

import { validateEmail} from '../api';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Opt from '../components/editor/Helpers';

class BrevoSignUpForm extends React.Component {
    constructor() {
        super();

        this.state = {
            formData:{
                email:"",
                name: "",
                mobile: "-"
            },
            errors:{
                email: null,
                name: null
            },
            confirmModal: false
        }

    }




    handleSubmit(e) {
        e.preventDefault();

        let checkList = [];
        let data = this.state.formData


        if ((!data.email || !validateEmail(data.email))) {
            this.setState({errors:{email: "Den här e-postadressen ser inte ut att vara korrekt"}})
            return;
        }

        if (this.props.includeMobile) {
            if ((!data.mobile || !isValidPhoneNumber(data.mobile))) {
                this.setState({errors:{mobile: "Det här mobilnumret ser inte ut att vara korrekt"}})
                return;
            }
        }

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        return fetch("/cms-api/add-brevo-list/",{
            method: 'POST',
            credentials: 'same-origin',
            headers: headers,
            body:JSON.stringify({"email": data.email, "list_id": this.props.list_id, "mobile": data.mobile })
            }).then((resp) => {
                if(resp.status >= 200 && resp.status <= 299){
                    this.setState({confirmModal: true});
                } else{
                    alert("Något gick fel, Försök igen senare eller kontakta oss.")
                }
            })
        
       
    }

    render() {
        return (<Fragment>
            <VBLoadingScreen show_loading={this.state.loading} />
            <div className={`${this.props.theme}`}>
                <form onSubmit={(e) => { this.handleSubmit(e) }} className={`${this.props.textColor}`}>
                <VBInput
                        type="email"
                        label={<p className='subtitle mb-1 mt-3'>Ange din mailadress:</p>}
                        placeholder="E-postadress"
                        error={this.state.errors.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                        onChange={(val) => {this.setState({ formData: { ...this.state.formData, email: val } }); }} 
                    />

                    <VBInput
                        type="text"
                        label={<p className='subtitle mb-1 mt-3'>Ange ditt namn:</p>}
                        placeholder="Namn"
                        error={this.state.errors.name}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, name: '' } })}
                        onChange={(val) => {this.setState({ formData: { ...this.state.formData, name: val } }); }} 
                    />
                    {this.props.includeMobile?
                    <VBPhoneInput
                    label={<p className='subtitle mb-1 mt-3'>Ange ditt mobilnummer:</p>}
                    placeholder="Mobilnummer"
                    error={this.state.errors.mobile}
                    onChange={(val) => {this.setState({ formData: { ...this.state.formData, mobile: val } }); }} 
                    /> : null}

                    <button type="submit" className={`n-btn ${this.props.button.variant} px-5 mt-4`}>{this.props.button.text}</button>

                    <p className='caption text-center text-md-start mt-3'>Genom att klicka på knappen ovan godkänner du våra <a href="/terms/" className='supp-forest-green'>användarvillkor </a> och <a href='/privacy/' className='supp-forest-green'>integritetspolicy.</a></p>
                </form>
            </div>

            

            <Modal show={this.state.confirmModal} onHide={() => {this.setState({ confirmModal: false});}} contentClassName="bg-neutral-cloud p-2 zindex-modal" centered>
                <Modal.Header className="align-items-start border-0" closeButton>
                    <h2 className="neutral-charcoal mb-0">{this.props.confirmModal.heading}</h2>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <p className='neutral-charcoal'>{this.props.confirmModal.text}</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-0">
                    <a type="button" href={this.props.confirmModal.buttonLink} onClick={() => {this.setState({confirmModal: false})}} className="n-btn btn-forest-green btn-sm float-end">{this.props.confirmModal.buttonText}</a>
                </Modal.Footer>
            </Modal>
        </Fragment>)
    }
}
BrevoSignUpForm.defaultProps={
    textColor: "primary-dark",
    button:{
        variant:"btn-forest-green",
        text: "Anmäl mig"
    },
    confirmModal: {
        heading: "Du är nu anmäld!",
        text: "Inom kort kommer ett bekräftelse mejl till din anginva E-post",
        buttonLink: "#",
        buttonText: "Stäng"
    },
    list_id: 77
}
BrevoSignUpForm.fields={
    list_id:{
        type: "text",
    },
    textColor:{
        type: "select",
        options: Opt.colorVariables
    },
    includeMobile:{
        type: "radio",
        options: [
            {label: "Yes", value: true},
            {label: "No", value: false}
        ]
    },
    button: {
        type: "object",
        objectFields: {
            variant: { type: "select", options: Opt.buttonOptions },
            text: {type: "text"}
        }
    },
    confirmModal: {
        type: "object",
        objectFields: {
            heading: {type: "text"},
            text: {type: "text"},
            buttonLink: {type: "text"},
            buttonText: {type: "text"},
        }
    }
}

export default BrevoSignUpForm;