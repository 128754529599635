import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";
import LoginForm from './LoginForm';
import {default_redirect} from '../api';

const LoginModal= styled(Modal)`
   z-index: 1100;
`
// display: ${props => props.formStatus === 'none' ? 'none' : 'block'};
class LoginFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            next_url: null
        }

    }

    // Event is sent to open the modal from vb-price-comparison (ComparisonFilter)  
    componentDidMount(){
        window.addEventListener("openLoginModal",(event)=>{
            this.setState({visible:true, next_url: event.detail.nextUrl})
        })
    }

    //Remove the event when the component unmounts  
    componentWillUnmount() {
        window.removeEventListener("openLoginModal", (event)=>{
            this.setState({visible:true, next_url: event.detail.nextUrl})
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.visible != this.props.visible){
            this.setState({visible:this.props.visible})
        }
    }

    handleCompleted(){
        if(this.props.completed){
            this.props.completed();
        }
        else if(!this.props.disable_redirect){
            this.setState({loading:true})
            default_redirect(()=>this.setState({loading:false}))
        }

        // Send the event that user has logged in to vb-price-comparison
        const event = new CustomEvent("userLoggedIn",{
            detail:{
                isLogged:true
            }
        });
        window.dispatchEvent(event);
        this.setState({visible:false})
    }

    render() {
    

        return <>
            <LoginModal show={this.state.visible} onHide={this.props.onHide ? this.props.onHide : () => {this.setState({visible:false})}} size="md" contentClassName={`bg-white ${this.props.formStatus == 'none'?'d-none':''}`} centered>
                <Modal.Body className="py-0">
                    <LoginForm 
                    disable_redirect={this.props.disable_redirect}
                    next_url={this.state.next_url ? this.state.next_url :this.props.next_url} 
                    fromUrl={this.state.fromUrl} 
                    onUserRegister={this.props.onUserRegister}
                    onUserLogin={this.props.onUserLogin}
                    onBankIDConnect={this.props.onBankIDConnect}
                    completed = {()=>{this.handleCompleted()}} 
                    data = {this.props.data} 
                    onHide={() => this.setState({visible:false}) } 
                    disable_magic_link={this.props.disable_magic_link}
                    formStatus = {this.props.formStatus}
                    />
                </Modal.Body>
            </LoginModal>
        </>
    }
}


export default LoginFormModal;