import React from 'react';
import styled from "styled-components";
import { getUserRights } from "../api";

import Offcanvas from 'react-bootstrap/Offcanvas';

const navbarDropdownItemsSaljaVirke = [
    {"id":"salja-virke", "label": "Sälja Virke", "href": "/salj-virke/" },
    {"id":"jamfor-virkespriser", "label": "Virkespriser", "href": "/virkespriser/", "children": [
        {"label": "Jämför virkespriser", "href": "/virkespriser/"},
        {"label": "Virkesprisindikator", "href": "/virkespriser/virkesprisindikator/"},
    ]},
    {"id":"salja-bio", "label": "Sälj Biobränsle", "href": "/auctions/grot/create/" },
    {"id":"vardera-av-skog", "label": "Värdering av skog", "href": "/fastighetsvardering/" },
    {"id":"primakund", "label": "Pluskund", "href": "/primakund/" },
    {"id":"for-maklare", "label": "För Mäklare", "href": "/for-maklare/"},
    {"id":"hitta-skogsbank", "label": "Hitta Skogsbank", "href": "/hitta-skogsbank/" },
    {"id":"skogsbruksplan", "label": "Skogsbruksplan", "href": "/skogsbruksplan/" },
]

const NavbarDropdown = styled.div`
  &:hover {
    > div {
      display: flex !important;
    }
  }
`;

class Navigation extends React.Component {
    constructor(props) {
      super();
  
      this.state = {
        isLogged: Window.user_data.isLogged,
        isAdmin: Window.user_data.isAdmin,
        userRoles: Window.user_data.roles,
        userOpenedAds: Window.user_data.openedAdsExpireSoon,
        userRegions: Window.user_data.regions,
        userOngoingAds: Window.user_data.ongoingAds,
        showMobileMenu: false,
        text: props.theme == 'dark' ? 'white' : 'primary-dark',
        button: props.theme == 'dark' ? 'btn-secondary' : 'btn-primary',
        background: props.theme == 'dark' ? 'bg-primary-dark' : 'bg-white',
        current_hover: null
      }
    }

    /*  ==========
        Update the navbar by making a reference in the parent component and calling the function, see examples below:

        - When initiating state set this        ||  this.navigation = React.createRef();
        - Import this component and render it   ||  <Navigation ref={this.navigation} />
        - Call this function on any element     ||  onClick={() => {this.navigation.current.updateNavbar()}}

        ==========  */

    updateNavbar() {
        getUserRights().then(resp => {
            if(resp.ok) {
                resp.json().then((json) => {
                    this.setState({ isLogged: json.is_login, userRoles: json.roles, isAdmin: json.is_admin })
                })
            } else {
                alert('Oops something went wrong')
            }
        })
    }

    userStatusItems() {
        let items = {}

        {this.state.isLogged ? 
            items.text = {href: '/logout/', text: 'LOGGA UT'} : 
            items.text = {href: '/logga-in/', text: 'LOGGA IN'}
        }

        {this.state.isLogged ?
            this.state.isAdmin ? 
                items.button = {href: '/vb-admin/', text: 'ADMIN'} :
            this.state.userRoles.indexOf("seller") != -1 ? 
                items.button = {href: '/skogsbruksplan/karta/', text: 'MINA SIDOR'} :
                items.button = {href: '/my-account/', text: 'MINA SIDOR'} :
            items.button = {href: '/logga-in/', text: 'BLI MEDLEM'}
        }

        return items
    }

    renderNavbarDropdown(itms){
        return itms.map((link) => {
            let color = "primary-main"
            let bg_color = "bg-white"
            let img_filter_color = "primary-main-filter";

            if(this.props.theme != 'dark'){
                if(this.state.current_hover == link.id){
                    color = "white"
                    bg_color = "bg-primary-dark"
                    img_filter_color = "white-filter"
                }
            } else{
                if(this.state.current_hover == link.id){
                    color = "white"
                    bg_color = "bg-secondary-dark"
                    img_filter_color = "white-filter"
                } else{
                    color = "primary-main"
                    bg_color = "bg-primary-dark"
                    img_filter_color = "primary-main-filter"
                }  
            }
            return <div 
            className="position-relative d-flex"
            onMouseEnter={() => {this.setState({current_hover: link.id})}}
            onMouseLeave={() => {this.setState({current_hover: null})}}
            >
                <a 
                    href={link.href} 
                    className={`w-100 subtitle fw-bold text-nowrap ${color} ${bg_color} p-3`} 
                    target={link.blank && "_blank"}>
                        {link.label} 
                        {link.children ? <img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} className={`max-h-25 d-flex-inline ms-1 mb-1 ${img_filter_color}`} />
                        : null
                        }
                </a>
                {link.children ? 
                    <div className={`${this.state.current_hover == link.id ? "d-flex" : "d-none"} pt-1 position-absolute start-100 flex-wrap ${bg_color}`} style={{zIndex:1, minHeight:"100%"}}>
                        {link.children.map((child) => {
                            return <a 
                            href={child.href} 
                            className={`subtitle primary-main fw-bold text-nowrap hover-white ${color} ${bg_color} p-3`} 
                            target={child.blank && "_blank"}>
                                {child.label}
                            </a>
                        })}
                    </div>
                : null}
            </div>
        })
    }

    render() {
        const navbarButtons = {}

        {this.state.isLogged ? 
            navbarButtons.first = {href: '/logout/', text: 'LOGGA UT'} : 
            navbarButtons.first = {href: '/logga-in/', text: 'LOGGA IN'}
        }

        {this.state.isLogged ?
            this.state.isAdmin ? 
                navbarButtons.second = {href: '/vb-admin/', text: 'ADMIN'} :
            this.state.userRoles.indexOf("seller") != -1 ? 
                navbarButtons.second = {href: '/skogsbruksplan/karta/', text: 'MINA SIDOR'} :
                navbarButtons.second = {href: '/my-account/', text: 'MINA SIDOR'} :
            navbarButtons.second = {href: '/logga-in/', text: 'BLI MEDLEM'}
        }

        return (<>
        <div class="w-100 bg-primary-main position-absolute top-0 start-0 primary py-3" style={{textAlign: "center"}}>
            <div class="container fw-bold">Inom kort byter Virkesbörsen utseende och namn till Treebula. <a href="https://virkesborsen.se/nyheter/snart-byter-virkesborsen-namn-till-treebula/" class="text-decoration-underline">Läs mer här.</a></div>
            </div>
            <div className="container" id="navbar" style={{marginTop:"75px"}}>
                <div className="row d-none d-lg-flex align-items-center py-4">
                    <div className="col-3">
                        <a href="/">
                            <img src={`${Window.static_path}img/virkesborsen/logo-text-closer.svg`} className={this.props.theme == 'dark' ? 'white-filter' : ''} style={{ marginBottom: "10px", maxWidth:"100%" }} />
                        </a>
                    </div>

                    <div className="col-3 text-end" style={{height: "30px"}}>
                        <NavbarDropdown className="position-relative d-inline-block pe-3">
                            <span className={`subtitle fw-bold text-nowrap ${this.state.text}`}>FÖR SKOGSÄGARE<img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} className={`max-h-25 d-flex-inline ms-1 mb-1 ${this.props.theme == 'dark' ? 'white-filter' : 'primary-dark-filter'}`} /></span>
                            <div className={`d-none position-absolute flex-column text-start start-0 m-0 ${this.state.background}`} style={{ boxShadow: '#C4D0CD 0px 10px 20px',zIndex:1}}>
                                {this.renderNavbarDropdown(navbarDropdownItemsSaljaVirke)}
                            </div>
                        </NavbarDropdown>
                    </div>
                    <div className={`col-3 text-start ${this.state.text}`} style={{height: "30px"}}>
                        <a href="/kop-virke/" className={`subtitle fw-bold text-nowrap pe-3 ${this.state.text}`}>KÖP VIRKE</a>
                        <a href="/om-oss/" className={`subtitle fw-bold text-nowrap ${this.state.text}`}>OM OSS</a>
                    </div>

                    <div className={`col-2 text-nowrap text-end ${this.state.text}`}>
                        <a href={navbarButtons.first.href} className={`btn btn-text btn-sm fw-normal ps-0 pe-1 ${this.state.text}`}>{navbarButtons.first.text}</a>
                        <a href={navbarButtons.second.href} className={`btn btn-sm ${this.state.button}`}>{navbarButtons.second.text}</a>
                    </div>
                </div>

                <div className="row d-lg-none justify-content-between py-4">
                    <div className="col">
                        <img src={`${Window.static_path}img/virkesborsen/icon.svg`} className={this.props.theme == 'dark' ? 'white-filter' : ''} style={{ maxHeight: "70px"}} />
                    </div>

                    <div className="col d-flex align-items-center justify-content-end">
                        <a type="button" onClick={() => {this.setState({ showMobileMenu: true })}}>
                            <img src={`${Window.static_path}img/icons/hamburger.svg`} className={this.props.theme == 'dark' ? 'max-h-50 white-filter' : 'max-h-50'} />
                        </a>
                    </div>
                </div>
                
                <Offcanvas className="bg-primary-dark h-100" style={{overflowY:"auto"}} show={this.state.showMobileMenu} placement='top'>
                    <div className="container">
                        <div className="row py-4 ms-2">
                            <div className="col-12 d-flex align-items-center justify-content-end" style={{ height: "70px"}}>
                                <a type="button" onClick={() => {this.setState({ showMobileMenu: false })}}>
                                    <img src={`${Window.static_path}img/icons/nav-cross.svg`} className="max-h-50 secondary-main-filter" />
                                </a>
                            </div>

                            <div className="col-12">
                                <ul className="list-unstyled">
                                    <li className="subtitle fw-bold white">FÖR SKOGSÄGARE</li>
                                    {navbarDropdownItemsSaljaVirke.map((link) => {
                                        return <li className="p-2">
                                            <a href={link.href} className="subtitle primary-main fw-bold p-1" target={link.blank && "_blank"}>- {link.label}</a>
                                            {link.children ? 
                                            <div className="d-flex flex-wrap ps-3">
                                                {link.children.map((child) => {
                                                    return <a href={child.href} className=" w-100 subtitle primary-main fw-bold p-1" target={child.blank && "_blank"}>- {child.label}</a>
                                                })}
                                            </div>
                                            : null}
                                            </li>
                                    
                                    })}
                                    <li className="mt-3"><a href="/kop-virke/" className="subtitle fw-bold white">KÖP VIRKE</a></li>
                                    <li className="mt-3"><a href="/om-oss/" className="subtitle fw-bold white">OM OSS</a></li>
                                </ul>
                            </div>

                            <div className="col-12 mt-5">
                                <a href={navbarButtons.first.href} className="white p-2 mb-2">{navbarButtons.first.text}</a>
                            </div>
                            
                            <div className="col-12 mt-4">
                                <a href={navbarButtons.second.href} className="primary-main p-2">{navbarButtons.second.text}</a>
                            </div>
                        </div>
                    </div>
                </Offcanvas>
            </div>

            {this.state.isLogged && this.state.userRegions.length > 0 && ((this.state.userRoles.includes('seller') && this.state.userOngoingAds.length > 0) || this.state.userRoles.includes('buyer')) &&
                <div className="d-flex align-items-center py-3 bg-tertiary-main">
                    <p className="subtitle fw-bold text-center white w-100 mb-0">
                        {this.state.userRoles.includes('seller') && this.state.userOngoingAds.length > 0 ? 
                            <a href="/my-account/listings" className="subtitle px-4 white hover-secondary-main">Se dina pågående annonser</a>
                                : 
                            <><a href={this.state.userRegions.length > 0 ? `/auctions/?regions=${this.state.userRegions[0].id}&sort=end_time` : `/auctions/?sort=end_time`} className="subtitle px-2 white hover-secondary-main">{`Gå till Auktioner ${this.state.userRegions.length > 0 ? "i " + this.state.userRegions[0].label : ""}`}</a>
                            {this.state.userOpenedAds.length > 0 && <> | 
                            <a href={`/auctions/${this.state.userOpenedAds[0].id}`} className="subtitle px-2 white hover-secondary-main">{`En annons du tittat på löper ut snart: #${this.state.userOpenedAds[0].id} ${this.state.userOpenedAds[0].label} i ${this.state.userOpenedAds[0].muni}`}</a></>} </>
                        }
                    </p>
                </div>}
            </>
        )
    }
}

export default Navigation;