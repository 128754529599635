import React from 'react';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import ColorSelector from '../editor/fields/ColorSelector';
import TextEditor from '../editor/fields/TextEditor';

class FeatureStepsLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleIndex: -1,
    };

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const windowHeight = window.innerHeight / 2;
    const features = document.querySelectorAll('.feature-step');

    let visibleIndex = -1;

    // this is finding the index of the first feature step that is fully visible
    features.forEach((feature, index) => {
      const featureRect = feature.getBoundingClientRect();
      if (featureRect.top >= 0 && featureRect.bottom <= windowHeight) {
        visibleIndex = index;
        return;
      }
    });

    this.setState({ visibleIndex });
  };

  render() {
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
        <div className={`container`}>
            <div className="row align-items-stretch d-flex">
              {/* image here */}
              <div className='col-12 col-lg-6 pe-lg-5 mb-4 mb-lg-0 flex-grow-1'>
                  <img style={{ width: "100%", height: "100%", minHeight: '350px' }} alt={this.props.alt} src={`${this.props.src}`} className={`rounded-5 object-fit-cover`} />
              </div>

              {/* feature steps here */}
              <div className='my-lg-4 col-12 col-lg-6 d-flex flex-column'>
                {this.props.steps.map((step, index) => (
                  <div key={index} className={`feature-step mb-2 ps-3 ${this.state.visibleIndex === index ? `border-3 border-${this.props.borderColor} border-start` : `border-3 border-${this.props.backgroundColor} border-start`}`}>
                    <h3 className={`n-fs-3 mt-1 ${step.titleColor}`}>{step.title}</h3>
                    <div className='d-block quill-text mb-2' dangerouslySetInnerHTML={{ __html: step.text }}></div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
    );
  }

}

FeatureStepsLayout.defaultProps = {
  visibility: "",
  backgroundColor: 'white-500',
  borderColor: 'neutral-charcoal',
  src: "https://placehold.co/600x800",
  alt: "scenic image",
  steps: [
    {
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
      
    },
    {
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    },
    {
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    }],

}

FeatureStepsLayout.fields = {
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  borderColor:{
    type: "select",
    options: Opt.colorVariables
  },
  src: {
    type: "custom",
    render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
  },
  alt: {
    type: "text"
  },
  steps: {
    type: "array",
    arrayFields: {
      title: { type: "text" },
      titleColor:{
        type: "select",
        options: Opt.colorVariables
      },
      text: { 
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} /> 
      },
    },
    defaultItemProps: FeatureStepsLayout.defaultProps.steps[0],
  },
}

export default FeatureStepsLayout;