import React from 'react';
import Navigation from '../components/static/Navigation';
import Footer from '../components/static/Footer';

import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import {isMobile, isDesktop} from 'react-device-detect';
import Cookies from 'js-cookie';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";



class GbbrPoc extends React.Component {
    constructor() {
        super();
        this.map = React.createRef();
        this.state={
            loading:false,
            layer: null,
            show:'draw',
            before_year: '2021',
            after_year: '2023',
            overlay: null
        }
    }

    handleDrawButtonClick = () => {
        const ritaButton = document.querySelector('.rita-button');
        if(ritaButton){
          ritaButton.style.display = 'none';
        }
        // Ensure the map is properly initialized
        if (this.map.current.state.map) {
          const map = this.map.current.state.map;
          const drawnItems = new L.FeatureGroup();
          map.addLayer(drawnItems);
          
          const drawControl = new L.Control.Draw({
            draw: {
              polygon: true,
              circle: false,
              polyline: false,
              marker: false,
              rectangle: false,
              circlemarker: false,
              edit: {
                featureGroup: drawnItems,
                edit: false,
                remove: true
              }
            }
          });
          map.addControl(drawControl);
      
          drawControl._toolbars.draw._modes.polygon.handler.enable();
          drawControl.getContainer().querySelector('.leaflet-draw-toolbar a').style.display = 'none';
          drawControl.getContainer().querySelector('.leaflet-draw-actions').style.display = 'none';
          
          // Remove the default border on leaflet
          const style = L.DomUtil.create('style');
          style.innerHTML = '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar { border: none !important; }';
          document.head.appendChild(style);
      
          // Create a wrapper div element to hold the buttons
          const buttonsWrapper = L.DomUtil.create('div', 'buttons-wrapper d-flex flex-column');
          buttonsWrapper.style.marginTop = '90px';
      
          // Create a button to finish drawing
          const finishButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
          finishButton.innerHTML = 'Spara';
          finishButton.addEventListener('click', () => {
            drawControl._toolbars.draw._modes.polygon.handler.completeShape();
            map.removeControl(finishButton);
            map.removeControl(cancelButton);
            map.removeControl(undoButton);
            buttonsWrapper.remove();
            //show Rita button again
            if(ritaButton){
              ritaButton.style.display = 'inline-block';
            }
          });
      
          const cancelButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
          cancelButton.innerHTML = 'Avbryt';
          cancelButton.addEventListener('click', () => {
            drawControl._toolbars.draw._modes.polygon.handler.disable();
            buttonsWrapper.remove();
            //show Rita button again
            if(ritaButton){
              ritaButton.style.display = 'inline-block';
            }
          });
      
          const undoButton = L.DomUtil.create('button', 'btn btn-secondary btn-sm mb-1');
          undoButton.innerHTML = 'Ångra';
          undoButton.addEventListener('click', () => {
            drawControl._toolbars.draw._modes.polygon.handler.deleteLastVertex();
          });
      
          buttonsWrapper.appendChild(finishButton);
          buttonsWrapper.appendChild(undoButton);
          buttonsWrapper.appendChild(cancelButton);
      
          map.on(L.Draw.Event.CREATED, (event) => {
            // Hide the buttons after polygon is created
            finishButton.style.display = 'none';
            cancelButton.style.display = 'none';
            undoButton.style.display = 'none';
          });
      
          map.getContainer().querySelector('.leaflet-draw-toolbar').appendChild(buttonsWrapper);
        }
      };

    handleShapeCreate(e, all_drawn){
        var type = e.layerType,
        layer = e.layer;

        if(L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) > 5500000){
            alert("To big area!");
            window.location.reload();
            return;
        }

        this.setState({layer:layer, show:"buttons"});
    }

    loadGbbrCloseTime(){
        var sw = this.state.layer.getBounds().getSouthWest();
        var ne = this.state.layer.getBounds().getNorthEast();
        let bbox = `${sw.lng.toString()},${sw.lat.toString()},${ne.lng.toString()},${ne.lat.toString()}`

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        this.setState({loading:true})
        fetch('/gbbr-poc-algo/', {
            method:"POST",
            credentials: 'same-origin',
            headers: headers,
            body: JSON.stringify({
                    "bbox": bbox,
                    "key": "7CDB3C76FE044A0BA7440D29C3CBE12BDA7574F31227973EA9A82BC0BD9BE656",
                    "year_one": this.state.before_year,
                    "year_two": this.state.after_year
            }),

        }).then((resp) => resp.json()).then((json) => {
            console.log(json)
            if(json["features"].length > 0){
                L.geoJSON(json, {
                    interactive: true,
                    style: (feature) => {
                      return {
                        color: "#ffff00",
                      };
                    },
                    onEachFeature: (feature, layer) => {
                      layer.bringToFront();
                      layer.addTo(this.map.current.state.drawn)
                    },
                  })
                  this.setState({loading:false});
            } else{
                alert("Found nothing!");
                this.setState({loading:false});
            }
        }).catch((e) => {
            fetch('/gbbr-poc-algo/', {
                method:"POST",
                credentials: 'same-origin',
                headers: headers,
                body: JSON.stringify({
                        "bbox": bbox,
                        "key": "7CDB3C76FE044A0BA7440D29C3CBE12BDA7574F31227973EA9A82BC0BD9BE656",
                        "year_one": this.state.before_year,
                        "year_two": this.state.after_year
                }),
    
            }).then((resp) => resp.json()).then((json) => {
                console.log(json)
                if(json["features"].length > 0){
                    L.geoJSON(json, {
                        interactive: true,
                        style: (feature) => {
                          return {
                            color: "#ffff00",
                          };
                        },
                        onEachFeature: (feature, layer) => {
                          layer.bringToFront();
                          layer.addTo(this.map.current.state.drawn)
                        },
                      })
                      this.setState({loading:false});
                } else{
                    alert("Found nothing!");
                    this.setState({loading:false});
                }
            })
        })
    }

    loadTCI(year){
        var sw = this.state.layer.getBounds().getSouthWest();
        var ne = this.state.layer.getBounds().getNorthEast();
        let bbox = `${sw.lng.toString()},${sw.lat.toString()},${ne.lng.toString()},${ne.lat.toString()}`

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        this.setState({loading:true})
        fetch('/gbbr-poc-image/', {
            method:"POST",
            credentials: 'same-origin',
            headers: headers,
            body: JSON.stringify({
                    "bbox": bbox,
                    "img_type": "image/png",
                    "script": "TCI_ENHANCED",
                    "format": "b64",  
                    "key": "7CDB3C76FE044A0BA7440D29C3CBE12BDA7574F31227973EA9A82BC0BD9BE656",
                    "year": year,
            })}).then((resp) => {
                resp.json().then((json) => {
                    if(this.state.overlay){
                        this.map.current.state.map.removeLayer(this.state.overlay);
                    }
                    let imageOverlay= L.imageOverlay(json["b64"], [[ne.lat, ne.lng], [sw.lat, sw.lng]]);
                    imageOverlay.addTo(this.map.current.state.map);
                    this.setState({overlay:imageOverlay});
                    this.setState({loading:false})
                })
            })

    }

    loadTCIBefore(){
        this.loadTCI(this.state.before_year)
    }

    loadTCIAfter(){
        this.loadTCI(this.state.after_year)
    }

    render() {
        return (
            <div className="container-fluid p-0">
                <div className="bg-white">
                <Navigation />
                    <div className="mt-4 position-relative">
                    <VBLeaflet
                        mapOptions={{gestureHandling: isMobile}}
                        enableDrawControl={{
                            circle: false,
                            rectangle: false,
                            polyline: false,
                            edit: false,
                            circlemarker: false,
                            marker: false,
                          }}
                        style={{height: "800px" }}
                        basemapName="Mapbox Satelite Borders"
                        ref={this.map}
                        enableLayers={true}
                        hideToolkitButton={true}
                        onShapeCreate={(e, all_drawn) => { this.handleShapeCreate(e, all_drawn); }}
                    />
                    <div style={{zIndex:10000}} className="position-absolute top-0 start-0 mt-4 ms-4 bg-white p-2 border">
                    {this.state.show == "draw" && <button className="rita-button n-btn btn-charcoal text-nowrap btn-sm m-1" onClick={this.handleDrawButtonClick.bind(this)}>Rita</button>}
                    {this.state.show == "buttons" && <VBInput initial={this.state.before_year} label="Before year" onChange={(val) => {this.setState({before_year: val})}} />}
                    {this.state.show == "buttons" && <VBInput initial={this.state.after_year} label="After year" onChange={(val) => {this.setState({after_year: val})}} />}
                    
                    {this.state.show == "buttons" && <button className="rita-button n-btn btn-charcoal text-nowrap btn-sm m-1" onClick={this.loadGbbrCloseTime.bind(this)}>Ladda Gbbr temporal</button>}
                    {this.state.show == "buttons" && <button className="rita-button n-btn btn-charcoal text-nowrap btn-sm m-1" onClick={this.loadTCIBefore.bind(this)}>Load TCI Before year</button>}
                    {this.state.show == "buttons" && <button className="rita-button n-btn btn-charcoal text-nowrap btn-sm m-1" onClick={this.loadTCIAfter.bind(this)}>Load TCI After year</button>}
                    
                    </div>
                    
                    </div>
                </div>
                <VBLoadingScreen show_loading={this.state.loading} disable_reload_timeout={true} />
                <Footer/>
            </div>
        )
    }
}
export default GbbrPoc;