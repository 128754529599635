import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class VerticalSpace extends React.Component {
  constructor() {
      super();

      this.state = {

      };
      
  }

  render() {
    return (
        <div className={`${this.props.applyBgColor === 'yes' ? `bg-${this.props.backgroundColor}` : ''} ${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility === "dt" ? 'd-none d-lg-block' : this.props.visibility === "mob" ? 'd-lg-none' : 'd-block'} w-100`} style={{ height: this.props.verticalHeight}}></div>
    )
  }
}

VerticalSpace.defaultProps = {
  applyBgColor: 'yes',
  backgroundColor: 'neutral-stone-500',
  visibility: "",
  verticalHeight: '24px',
}

VerticalSpace.fields = {
  applyBgColor: {
    type: "select",
    options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
  },
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  verticalHeight: {
    type: "select",
    options: Opt.verticalSpaceOptions
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  }
}

export default VerticalSpace;