import React from 'react';
import MediaSelector from '../editor/fields/MediaSelector';
import * as Opt from '../editor/Helpers'

class List extends React.Component {
  constructor() {
    super();
}


  render() {
    return (
        <ul className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} ${this.props.theme} list-unstyled`}>
            {this.props.listItems.map((li) => {
                return <li className={`${li.textColor} ${li.size} ${li.style} d-flex align-items-center`}>
                    {li.icon && <img style={{width:li.iconSize, height: li.iconSize, objectFit: "contain"}} src={li.icon} /> }
                    <p className={`${li.spaceFromIcon} mb-0`}>{li.text}</p>
                </li>;
            })}
        </ul>);
  }
}

List.defaultProps = {
    visibility: "",
    listItems: [
    { 
        text: '1',
        style: '',
        textColor: "black",
        size: '',
        spaceFromIcon: "ps-1",
        icon: "",
        iconSize: "16px"
    },
    { 
        text: '2',
        textColor: "black",
        style: '',
        size: '',
        spaceFromIcon: "ps-1",
        icon: "",
        iconSize: "16px"
    },
  ],
  theme:'#F7F6F2',
  spacing: [],
};

List.fields = {
  spacing: Opt.spacingOptions,
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  listItems: {
    type: "array",
    arrayFields: {
        icon:{
            type: "custom",
            render: ({ name, onChange, value }) => <MediaSelector name={name} type={'icon'} onChange={onChange} value={value} />,
        },
        iconSize: {
            type:"select",
            options: Opt.iconSizeOptions
        },
        text: { type: "text" },
        textColor: {
            type: "select",
            options: Opt.colorVariables,
        },
        style: {
            type: "select",
            options: Opt.TextStyles
        },
        size: {
            type: "select",
            options: Opt.TextSizes
        },
        spaceFromIcon:{
            type:"select",
            options: Opt.paddingOptionsStart
        }
    }
  },
  theme: {
    type: "select",
    options: Opt.colorVariables
  },
}

export default List;
