import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';
import PostSelector from '../editor/fields/PostSelector';

class PostsPopular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 20,
      posts:[],
      selectedPostIds: props.postIds || [],
      selectedPostDetails: null,
    };
  }

  async componentDidMount() {
    if(Array.isArray(this.props.postIds) && this.props.postIds.length > 0) {
      await this.fetchPosts(this.props.postIds);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.postIds !== this.props.postIds) {
      await this.fetchPosts(this.props.postIds);
    }
  }

  fetchPosts = async (postIds) => {
    try {
      const response = await fetch(`/admin-api/get-posts-by-id/?id=${postIds.join(',')}`);
      if (!response.ok) {
        throw new Error('Failed to fetch post details');
      }
      const postDetails = await response.json();
      this.setState({ posts: postDetails.posts });
    } catch (error) {
      console.error('Error fetching post details:', error);
    }
  };

  handlePostChange = (postIds) => {
    if (!Array.isArray(postIds)) {
      postIds = [postIds];
    }
    this.setState({ selectedPostIds: postIds });
    if (postIds.length > 0) {
      this.fetchPosts(postIds);
    } else {
      this.setState({ posts: [] });
    }
  };

  render() {
    const siblingCount = 2;
    const pageNumbers = [];

    //calculate page number to display
    for(let i = Math.max(1, this.props.currentPage - siblingCount); 1 <= Math.min(this.state.totalPages, this.props.currentPage + siblingCount); i++) {
      pageNumbers.push(i);
    }
      const postDetailsJsx = this.state.posts.map((post) => (
        <div className="col-lg-6" key={post.id}>
          <div className={`d-flex w-100 overflow-hidden mb-3 corners-2-all bg-${this.props.cardBackgroundColor}`} style={{height:"230px"}}>
            <a href={post.url} className="d-flex w-100">
            <div className={`p-3 position-relative mb-lg-4 mb-2`} style={{width:(screen.width>=992 ? "60%" : "100%")}}>
                <h4 className={`fw-medium n-fs-6 w-100 mb-1 ${this.props.fontColor}`} style={{fontSize:"20px"}}>{post.title}</h4>
                <p className={`n-fs-7 w-100 overflow-hidden ${this.props.fontColor}`} style={{maxHeight:"64px"}}>{post.description}</p>
                <div className={`mb-0 w-100 bottom-0 position-absolute ${this.props.fontColor}`}>
                  <div className="d-flex align-items-center">
                    <img style={{width:"48px", height:"48px", objectFit:"cover"}} src={Window.static_path + (post.author_image ? post.author_image : "").substr(1)} className="rounded-circle" />
                    <div className="ms-3">
                      <p className={`mb-0 n-fs-6 ${this.props.fontColor}`}>{post.author}</p>
                      <p className={`n-fs-7 mb-0 ${this.props.fontColor}`}>{post.published_date}</p>
                    </div>
                  </div>
                </div>
            </div>
            <img className="vb-rounded rounded-sb d-none d-lg-block" src={post.image_url} style={{height:"230px", width:"40%", objectFit:"cover"}} />
            </a>
          </div>
    </div>
      ));

  return (
    <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
      <section className={`container`}>
      <div className="row">
      {this.props.fields && this.props.fields.postIds && this.props.fields.postIds.render({ value: this.props.postIds, onChange: this.handlePostChange})}
      {postDetailsJsx}
      </div>
      </section>
    </div>
    );

  }
}

PostsPopular.defaultProps = {
  postIds: [],
  visibility: "",
  category:{"label": "inital", "value": 1},
  backgroundColor: 'neutral-stone-500',
  cardBackgroundColor: 'neutral-cloud-500',
  categoryBackgroundColor: 'supp-forest-green-500',
  categoryFontColor: 'white',
  fontColor: 'neutral-charcoal',
  numPosts: 2,
}

PostsPopular.fields = {
  postIds: {
    type: 'custom',
    render: (props) => <PostSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  cardBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryFontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  fontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  numPosts: {
    type: "number",
  },
}

export default PostsPopular;
