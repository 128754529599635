import React from 'react';
import {isMobile, isDesktop} from 'react-device-detect';
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import Modal from 'react-bootstrap/Modal';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";


let fields = [
    {
        id: 'al',
        label: 'Al',
    },
    {
        id: 'asp',
        label: 'Asp',

    },
    {
        id: 'björk',
        label: 'Björk',
    },
    {
        id: 'bok',
        label: 'Bok',
    },
    {
        id: 'ek',
        label: 'Ek',
    },
];
class Step2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gallring: props.adData.arrangement == "gallring",
            currentSectionData: [],
            errors: false,
            selectedSection: 0,
            // Modals
            editSectionModal: false,
            addSectionModal: false,
            deleteSectionModal: false,
            isMenuRowOpen:null
        }
        this.map = React.createRef()
        this.adLayers = new L.FeatureGroup();
        this.volumeM3skInput = React.createRef(); 
        this.volumeM3FubInput = React.createRef();
        this.volumeM3skInputNew = React.createRef(); 
        this.volumeM3FubInputNew = React.createRef()
        this.menuRef = React.createRef()
    }



    addGeojsonToMap(geojson) {
        L.geoJSON(geojson, {
            interactive: true,
            style: (feature) => {
                return {
                    color: "#ffff00",
                };
            },
            onEachFeature: (feature, layer) => {
                layer.addTo(this.map.current.state.map)
            },
        })
    }

    addLabel(itm) {
        setTimeout(() => {
            let lblCenter = L.latLng(itm.location)
            const labelContent = document.createElement('div');
            labelContent.innerHTML = itm.label;

            const label = new L.tooltip({
                className: "white bg-transparent border-0 shadow-none fs-2",
                direction: "center",
                permanent: true,
                opacity: 1

            })
            label.setLatLng(lblCenter);
            label.setContent(labelContent);
            label.addTo(this.map.current.state.map);
        }, 50)

    }



    componentDidMount() {
        window.addEventListener('click', this.handleClickOutside);
        window.scrollTo({ top: 700, behavior: 'smooth' })

        if (this.props.adData.adLayers.length > 0) {
            setTimeout(() => {
                let group = new L.FeatureGroup();
                let feat_num = 1
                this.setState({feat_num:feat_num})
                this.props.adData.adLayers.forEach((itm) => {
                    itm.layer.addTo(group)
                    if (!itm.layer.hasOwnProperty("_icon")){
                        this.addLabel({ label: this.state.feat_num.toString(), location: itm.layer.getBounds().getCenter() })
                        const updatedForestWithSections={...this.props.adData.forestWithSections};
                        updatedForestWithSections.sections[this.state.feat_num-1].Name=this.state.feat_num.toString()
                        this.props.changeAdData({forestWithSections:updatedForestWithSections})
                        this.setState({feat_num:feat_num+=1})
                    }
                })

                this.map.current.state.map.fitBounds(group.getBounds())
                this.addGeojsonToMap(group.toGeoJSON())

                const updatedAdLayers = []
                this.props.adData.adLayers.forEach((adLayer) => {
                    if (!adLayer.layer.hasOwnProperty("_icon")){
                    updatedAdLayers.push({
                        data: {
                            ...adLayer.data,
                            tall: adLayer.data.tall / 100,
                            gran: adLayer.data.gran / 100,
                            lov: adLayer.data.lov / 100,
                            id: adLayer.layer._leaflet_id,
                            al: adLayer.layer.al / 100,
                            asp: adLayer.layer.asp / 100,
                            björk: adLayer.layer.björk / 100,
                            bok: adLayer.layer.bok / 100,
                            ek: adLayer.layer.ek / 100,
                            name:this.state.feat_num .toString(),
                        },
                    })
                    }
                });

                this.setState({ currentSectionData: updatedAdLayers });
            }, 200);
        }
        else if (Object.keys(this.props.adData.forestWithSections).length > 0) {
            let data = this.props.adData.forestWithSections.sections.map((section) => ({
                data: {
                    area: section.Hectares,
                    volume: section.totalVolume,
                    volume_m3fub: section.totalVolume,
                    tall: section.TGL.Tall,
                    gran: section.TGL.Gran,
                    lov: section.TGL.Lov ?? section.TGL.default,
                    id: section.Name,
                    al: section.TGL.Al,
                    asp: section.TGL.Asp,
                    björk: section.TGL.Björk,
                    bok: section.TGL.Bok,
                    ek: section.TGL.Ek,
                    name:section.Name,
                }
            }));

            this.setState({ currentSectionData: data });

            setTimeout(() => {
                if (this.props.adData.custom_area) {
                    this.setState({ allow_map_edit: false })
                    L.geoJSON(this.props.adData.custom_area, {
                        interactive: false,
                        style: (feature) => {
                            return {
                                color: "#ffff00",
                            };
                        },
                        onEachFeature: (feature, layer) => {
                            layer.bringToFront();
                            layer.addTo(this.adLayers)
                        },
                    })
                    this.adLayers.addTo(this.map.current.state.map);
                    this.map.current.state.map.fitBounds(this.adLayers.getBounds());
                }
            }, 200)

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.adData !== this.props.adData) {
            if (Object.keys(this.props.adData.forestWithSections).length > 0) {
                const data = this.props.adData.forestWithSections.sections.map((section) => ({
                    data: {
                        area: section.Hectares,
                        volume: section.totalVolume,
                        volume_m3fub: section.totalVolume,
                        tall: section.TGL.Tall,
                        gran: section.TGL.Gran,
                        lov: section.TGL.Lov ?? section.TGL.default,                      
                        al: section.TGL.Al,
                        asp: section.TGL.Asp,
                        björk: section.TGL.Björk,
                        bok: section.TGL.Bok,
                        ek: section.TGL.Ek,
                        id: section.Name,
                        name:section.Name,
                    },
                }));
                this.setState({ currentSectionData: data });
            }
        }
    }



    validateTGL() {
        const tall = Math.abs(parseInt(this.state.tall)) || 0; 
        const lov = Math.abs(parseInt(this.state.lov)) || 0;
        const gran = Math.abs(parseInt(this.state.gran)) || 0;
        const al = Math.abs(parseInt(this.state.al)) || 0;
        const asp = Math.abs(parseInt(this.state.asp)) || 0;
        const bjork = Math.abs(parseInt(this.state.björk)) || 0;
        const bok = Math.abs(parseInt(this.state.bok)) || 0;
        const ek = Math.abs(parseInt(this.state.ek)) || 0;
      
        const validFields = [tall, lov, gran, al, asp, bjork, bok, ek].filter(value => !isNaN(value));
      
        const sum = validFields.reduce((acc, value) => acc + value, 0);
      
        if (sum !== 100) {
          this.setState({ errors: true });
        } else {
          this.setState({ errors: false });
        }
      }
      
    

    handleEditSubmit(event) {
        event.preventDefault();
        let updatedData;
        const { selectedSection, volume, volume_m3fub, tall, gran, lov, al, asp, björk, bok, ek } = this.state
        if (this.state.errors === false) {
             if (Object.keys(this.props.adData.forestWithSections).length > 0) {
                const originalSections=this.props.adData.forestWithSections.sections;
                const updatedSections = originalSections.map((section) => {
                    if (selectedSection == section.Name) {
                        const updatedTGL = {
                            "Tall": tall / 100,
                            "Gran": gran / 100,
                            "default": lov / 100,
                            ...(al != null && al !== 0 && al !== '' && !isNaN(al) ? { "Al": al / 100 } : {}),
                            ...(asp != null && asp !== 0 && asp !== '' && !isNaN(asp) ? { "Asp": asp / 100 } : {}),
                            ...(björk != null && björk !== 0 && björk !== '' && !isNaN(björk) ? { "Björk": björk / 100 } : {}),
                            ...(bok != null && bok !== 0 && bok !== '' && !isNaN(bok) ? { "Bok": bok / 100 } : {}),
                            ...(ek != null && ek !== 0 && ek !== '' && !isNaN(ek) ? { "Ek": ek / 100 } : {}),
                        };
                        const filteredTGL = Object.fromEntries(Object.entries(updatedTGL).filter(([key, value]) => value !== 0));
                        return {
                            ...section,

                            totalVolume: typeof(volume_m3fub) !== 'undefined' &&  volume_m3fub !== ""? volume_m3fub : volume,
                            TGL: filteredTGL,
                        };
                    }
                    return section;
                });

                const sectionsChanged=JSON.stringify(updatedSections) !== JSON.stringify(originalSections)
                if (sectionsChanged){
                    const updatedForestWithSections = {
                        ...this.props.adData.forestWithSections,
                        sections: updatedSections,
                    };
                    this.props.changeAdData({ forestWithSections: updatedForestWithSections });
                updatedData = updatedSections
            }
                
            }
            this.setState({
                editSectionModal: false, volume_m3fub: '', tall: '', gran: '', lov: '', fieldSelected: '', al: '', asp: '', björk: '', bok: '', ek: ''
            });
            return;
        }

        else {
            alert('Andel ska vara positiv och summan av TGL 100')
        }
       
    }
    handleDeleteSection() {
        const { selectedSection } = this.state;
        if (Object.keys(this.props.adData.forestWithSections).length > 0) {
            const updatedSections = this.props.adData.forestWithSections.sections.filter((section) => {
                return section.Name !== selectedSection
            })
            const updatedForestWithSections = { ...this.props.adData.forestWithSections, sections: updatedSections }
            this.props.changeAdData({ forestWithSections: updatedForestWithSections })
            this.setState({ deleteSectionModal: false })
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const { avdelningsnr, area, volume_m3fub_new, tall, gran, lov, al, asp, björk, bok, ek } = this.state
        if (this.state.errors === false) {
            const updatedTGL = {
                "Tall": (tall !== undefined && tall !== null) ? tall / 100 : 0,
                "Gran": (gran !== undefined && gran !== null) ? gran / 100 : 0,
                "default": (lov !== undefined && lov !== null) ? lov / 100 : 0,
                ...(al !== null && !isNaN(al) ? { "Al": al / 100 } : {}),
                ...(asp !== null && !isNaN(asp) ? { "Asp": asp / 100 } : {}),
                ...(björk !== null && !isNaN(björk) ? { "Björk": björk / 100 } : {}),
                ...(bok !== null && !isNaN(bok) ? { "Bok": bok / 100 } : {}),
                ...(ek !== null && !isNaN(ek) ? { "Ek": ek / 100 } : {}),
            };
            const filteredTGL = Object.fromEntries(Object.entries(updatedTGL).filter(([key, value]) => value !== 0));
            const newSection = {
                Name: (avdelningsnr !== undefined && avdelningsnr !== null) ? avdelningsnr : this.props.adData.forestWithSections.sections.length+1,
                Hectares: (area !== undefined && area !== null) ? area : "1",
                totalVolume: (volume_m3fub_new !== undefined && volume_m3fub_new !== null) ? this.state.gallring ? Math.round(parseFloat(volume_m3fub_new)*0.3) : volume_m3fub_new : 0,
                TGL: filteredTGL
            }
            let updatedForestWithSections;

            const updatedSections = [...this.props.adData.forestWithSections.sections, newSection]
            updatedForestWithSections = { ...this.props.adData.forestWithSections, sections: updatedSections }
            this.props.changeAdData({ forestWithSections: updatedForestWithSections })

            this.setState({ addSectionModal: false, avdelningsnr: '', area: '', volume_m3fub_new: '', tall: '', gran: '', lov: '', fieldSelected: '', al: '', asp: '', björk: '', bok: '', ek: '' })
        }
        else {
            alert('Vänligen fyll i alla fält för att fortsätta')
        }

    }
    capitalizeFirstLetter(str) {
        if (typeof str !== 'string' || str.length === 0) {
            return str;
        }
        
        if (str[0] === str[0].toLowerCase()) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str;
        }
    }

    componentWillUnmount() {
         window.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside=(event)=>{
        if (this.menuRef.current && !this.menuRef.current.contains(event.target) && this.state.isMenuRowOpen !== null){
            this.setState({isMenuRowOpen:null})
        }
    }
    handleMenuClick(index,e){
        e.stopPropagation();
        this.setState(prevState => ({
            isMenuRowOpen: prevState.isMenuRowOpen === index ? null : index
          }));
    }
   
    render() {
        return (
            <div className='' data-step='2'>
                <div className='row justify-content-between d-flex'>
                 <div className='col-lg-4 col-8 position-relative rounded shadow p-2 m-4'>
                 <p className="fw-medium m-2">{this.props.adData.description_of_property}</p>
                 <p className="m-2 fw-medium"> {this.capitalizeFirstLetter(this.props.adData.arrangement)}</p>
                 </div>
                 <div className='col-lg-4 col-10 rounded-3 bg-neutral-stone-300 p-3 ms-3 me-1 mb-3'>
                    <p className='neutral-charcoal mb-0'>I kartan nedan kan du se de områdena du valde i förra steget, under kartan kan du redigera de hämtade skogsuppgifterna för områdena.</p>
                 </div>
                 </div>
                <div style={{ height: '500px' }}>
                    <VBLeaflet
                        mapOptions={{gestureHandling: isMobile}}
                        enableDrawControl={{
                            circle: false,
                            rectangle: false,
                            polyline: false,
                            edit: false,
                            circlemarker: false,
                            marker: false,
                        }}
                        style={{ "borderRadius": "15px" }}
                        basemapName="Satellit"
                        ref={this.map}
                        enableLayers={true}
                        hideToolkitButton={true}p
                        hideLeafletLayerButton={true}
                        onClick={this.handleMapClick}
                        onShapeCreate={(e, all_drawn) => { this.handleShapeCreate(e, all_drawn); }}
                        onShapeClick={(e, all_drawn) => { this.handleShapeClick(e, all_drawn); }}
                        onDrawStart={(e) => { }}
                    />
                </div>
                <div className='rounded-4 bg-neutral-cloud-300 neutral-charcoal p-2 p-lg-4 mb-3 mt-5'>
                    <h5 className='fw-medium n-fs-5 neutral-charcoal mt-3'>Virkesinformation</h5>
                    <p>Nedan information är hämtad från senaste lasterdatan. Klicka på de tre punkterna till höger om varje rad för att Redigera/Radera.</p>
                    <p>Notera att volym och trädslag behöver inte vara 100% rätt för din annons, utan säkerställ bara att det stämmer ganska bra.</p>
                    <button className="n-btn btn-core btn-sm" onClick={() => { this.setState({ addSectionModal: true, avdelningsnr: null, area: 0, volume_m3fub_new: 0, tall: 100, gran: 0, lov: 0}) }}><img src={Window.static_path + "img/icons/icon-left-dark.svg"} className="pe-2" />Lägg till avdelning</button>
                    <div className='table-responsive'>
                        <table className="table table-borderless table-striped position-relative">
                            <thead className="position-sticky bg-neutral-cloud-300" style={{ "top": "-30px", "height": "60px" }}>
                                <tr>
                                    <th className='fw-medium neutral-charcoal' scope="col">Avd # </th>
                                    <th className='fw-medium neutral-charcoal' scope="col">Areal</th>
                                    <th className='fw-medium neutral-charcoal text-nowrap' scope='col'>{this.state.gallring ? 'Uttag*' : 'Uttag'}</th>
                                    <th className='fw-medium neutral-charcoal' scope='col'>Tall</th>
                                    <th className='fw-medium neutral-charcoal' scope='col'>Gran</th>
                                    <th className='fw-medium neutral-charcoal' scope='col'>Löv</th>
                                    {this.state.currentSectionData.some(itm => itm.data.al !== null && !isNaN(itm.data.al)) && <th className='fw-medium neutral-charcoal' scope='col'>Al</th>}
                                    {this.state.currentSectionData.some(itm => itm.data.asp !== null && !isNaN(itm.data.asp)) && <th className='fw-medium neutral-charcoal' scope='col'>Asp</th>}
                                    {this.state.currentSectionData.some(itm => itm.data.björk !== null && !isNaN(itm.data.björk)) && <th className='fw-medium neutral-charcoal' scope='col'>Björk</th>}
                                    {this.state.currentSectionData.some(itm => itm.data.bok !== null && !isNaN(itm.data.bok)) && <th className='fw-medium neutral-charcoal' scope='col'>Bok</th>}
                                    {this.state.currentSectionData.some(itm => itm.data.ek !== null && !isNaN(itm.data.ek)) && <th className='fw-medium neutral-charcoal' scope='col'>Ek</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.currentSectionData.map((itm, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td>{"#" + (itm.data.name)}</td>
                                                <td>{Math.round(itm.data.area * 10) / 10} ha</td>
                                                <td>{`${itm.data.volume_m3fub} m³fub`}</td>
                                                <td>{isNaN(itm.data.tall) ? 0 : Math.round(itm.data.tall * 100)}%</td>
                                                <td>{isNaN(itm.data.gran) ? 0 : Math.round(itm.data.gran * 100)}%</td>
                                                <td>{isNaN(itm.data.lov) ? 0 : Math.round(itm.data.lov * 100)}%</td>
                                                {this.state.currentSectionData.some(itm => itm.data.al !== null && !isNaN(itm.data.al)) && <td>{isNaN(itm.data.al) ? '' : Math.round(itm.data.al * 100)}%</td>}
                                                {this.state.currentSectionData.some(itm => itm.data.asp !== null && !isNaN(itm.data.asp)) && <td>{isNaN(itm.data.asp) ? '' : Math.round(itm.data.asp * 100)}%</td>}
                                                {this.state.currentSectionData.some(itm => itm.data.björk !== null && !isNaN(itm.data.björk)) && <td>{isNaN(itm.data.björk) ? '' : Math.round(itm.data.björk * 100)}%</td>}
                                                {this.state.currentSectionData.some(itm => itm.data.bok !== null && !isNaN(itm.data.bok)) && <td>{isNaN(itm.data.bok) ? '' : Math.round(itm.data.bok * 100)}%</td>}
                                                {this.state.currentSectionData.some(itm => itm.data.ek !== null && !isNaN(itm.data.ek)) && <td>{isNaN(itm.data.ek) ? '' : Math.round(itm.data.ek * 100)}%</td>}
                                                <td><div className='position-relative'>
                                                    <button className='n-btn btn-ghost btn-charcoal btn-sm' onClick={(e)=>this.handleMenuClick(i,e)}><img src={Window.static_path + "img/icons/three-dots-vertical.svg"} role='button' ></img></button>
                                                {this.state.isMenuRowOpen === i && (
                                                    <div ref={this.menuRef} className='bg-white rounded-1 p-2 position-absolute translate-middle-x' style={{zIndex:1, top:"-50%", left:"-50%"}}>
                                                         <button className='n-btn btn-ghost btn-core d-flex justify-content-center' onClick={() => { this.setState({ editSectionModal: true, selectedSection: itm.data.id, volume: Math.round(itm.data.volume), tall: Math.round(itm.data.tall * 100), gran: Math.round(itm.data.gran * 100), lov: Math.round(itm.data.lov * 100) }) }}><span className='me-1'><img src={Window.static_path + "img/icons/pencil.svg"} role='button' ></img></span><span>Redigera</span></button>
                                                         <button className='n-btn btn-ghost btn-charcoal d-flex justify-content-center' onClick={() => { this.setState({ deleteSectionModal: true, selectedSection: itm.data.id }) }}><span className='me-1'><img src={Window.static_path + "img/icons/bin.svg"} role='button' ></img></span><span>Radera</span></button>
                                                    </div>
                                                )}
                                                </div></td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.gallring ? <p className="mt-0 mb-2 neutral-charcoal n-fs-7">*Observera att volymen på nya avdelningar automatiskt reduceras till 30% pga Gallringsuppdrag. För att ändra volymen till valfritt värde klickar du på Redigera.</p> : null}

                <Modal show={this.state.editSectionModal} onHide={() => this.setState({ editSectionModal: false, fieldSelected:[], errors:false })} size="md" contentClassName="bg-white p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="neutral-charcoal" closeButton>
                        <h4 className='neutral-charcoal fs-5'>Redigera avsnitt</h4>
                    </Modal.Header>
                    <Modal.Body className="py-0 rounded-4 my-2 pb-1">
                        <form onSubmit={(e) => this.handleEditSubmit(e)}>
                            <div className="row align-items-center g-0 p-2">
                                <div className="col-6 p-2">Totalvolym (m³sk)</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        initial={this.state.volume ? Math.round(parseFloat(this.state.volume) / 0.83) : 0}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        type="number"
                                        onChange={(val) => {
                                            val = val.replace(".","");
                                            const volume_m3fub = Math.round(parseFloat(val) * 0.83);
                                            this.setState({ volume: val, volume_m3fub: volume_m3fub },()=>{
                                                this.volumeM3FubInput.current.setValue(this.state.volume_m3fub);
                                            });                                            
                                        }} 
                                        ref={this.volumeM3skInput} />
                                </div>
                                <div className="col-6 p-2">Totalvolym (m³fub)</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        initial={this.state.volume ? this.state.volume : 0}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        type="number"
                                        onChange={(val) => {
                                            val = val.replace(".","");
                                            const volume = Math.round(parseFloat(val) / 0.83);
                                            this.setState({ volume: volume, volume_m3fub: val },()=>{
                                                this.volumeM3skInput.current.setValue(this.state.volume);
                                            });
                                        }}
                                        ref={this.volumeM3FubInput}/>
                                </div>

                                <div className="col-6 p-2">Tall|Gran|Löv</div>
                                <div className="col-6 p-2">TGL %</div>

                                <div className="col-6 p-2">Tall %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        initial={isNaN(this.state.tall) ? 0 : this.state.tall}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ tall: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>

                                <div className="col-6 p-2">Gran %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        initial={isNaN(this.state.gran) ? 0 : this.state.gran}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ gran: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>

                                <div className="col-6 p-2">Löv %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px", borderStyle:"groove" }}
                                        initial={isNaN(this.state.lov) ? 0 : this.state.lov}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ lov: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>
                                <VBMultiOption
                                    inputStyle={{ minHeight: 0 }}
                                    placeholder="Lägg till fler trädslag"
                                    label={<p className='mb-1 mt-3'>Lägg till fler trädslag?</p>}
                                    options={fields}
                                    autoClose={true}
                                    onChange={(val) => { this.setState({ fieldSelected: val }) }} />

                                {fields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        {this.state.fieldSelected && this.state.fieldSelected.some(selectedField => selectedField.id === field.id) && (
                                            <>
                                                <div className="col-6 p-2">{field.label} %</div>
                                                <div className="col-6 p-2">
                                                    <VBInput
                                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" , borderStyle:"groove"}}
                                                        initital={this.state[field.id]}
                                                        type="number"
                                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                                        onChange={(val) => { this.setState({ [field.id]: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                                </div>
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}


                                <div className="mt-4 row justify-content-center">
                                <div className='col-12 col-md-6'>
                                <button className='n-btn btn-forest-green w-100' type="submit">Spara</button>
                                </div>
                                <div className='col-12 col-md-6'>
                                <button type="button" className="n-btn btn-outline btn-charcoal w-100 mt-1 mt-md-0" onClick={() => this.setState({ editSectionModal: false, fieldSelected: [], errors:false })}>Stäng</button>
                                </div>
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                </Modal>
                <Modal show={this.state.addSectionModal} onHide={() => this.setState({ addSectionModal: false, fieldSelected:[], errors:false })} size="md" contentClassName="bg-white p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="primary-dark" closeButton>
                        <h4 className="neutral-charcoal mb-0 fs-5">Lägg till avsnitt</h4>
                    </Modal.Header>
                    <Modal.Body className="py-0 my-2 pb-1">
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="row align-items-center g-0 p-2">
                                <div className="col-6 p-2">Avdelnings nr</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.avdelningsnr}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={""}
                                        type="text"
                                        onChange={(val) => this.setState({ avdelningsnr: val })} />
                                </div>
                                <div className="col-6 p-2">Areal (ha)</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.area}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={""}
                                        type="number"
                                        onChange={(val) => this.setState({ area: val })} />
                                </div>
                                <div className="col-6 p-2">Totalvolym (m³sk)</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.volume_new}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={this.state.volume_m3fub_new ? Math.round(parseFloat(this.state.volume_m3fub_new.toString().replace(".", "")) / 0.83) : ''}
                                        type="number"
                                        onChange={(val) => {
                                            val = val.replace(".","");
                                            const volume_m3fub = Math.round(parseFloat(val) * 0.83);
                                            this.setState({ volume_new: val, volume_m3fub_new: volume_m3fub },()=>{
                                                this.volumeM3FubInputNew.current.setValue(this.state.volume_m3fub_new);
                                            });
                                        }}
                                        ref={this.volumeM3skInputNew}  />
                                </div>
                                <div className="col-6 p-2">Totalvolym (m³fub)</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.volume_m3fub_new}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={this.state.volume_new ? Math.round(parseFloat(this.state.volume_new.toString().replace(".", "")) * 0.83) : ''}
                                        type="number"
                                        onChange={(val) => {
                                            val = val.replace(".","");
                                            const volume_new = Math.round(parseFloat(val) / 0.83);
                                            this.setState({ volume_new: volume_new, volume_m3fub_new: val },()=>{
                                                this.volumeM3skInputNew.current.setValue(this.state.volume_new);
                                            });
                                        }}
                                        ref={this.volumeM3FubInputNew}/>
                                </div>
                                <div className="col-6 p-2">Tall|Gran|Löv</div>
                                <div className="col-6 p-2">TGL %</div>

                                <div className="col-6 p-2">Tall %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.tall}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={""}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ tall: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>

                                <div className="col-6 p-2">Gran %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.gran}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={""}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ gran: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>

                                <div className="col-6 p-2">Löv %</div>
                                <div className="col-6 p-2">
                                    <VBInput
                                        value={this.state.lov}
                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                        placeholder={""}
                                        type="number"
                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                        onChange={(val) => { this.setState({ lov: val }); setTimeout(() => { this.validateTGL() }, 1000) }} />
                                </div>
                                <VBMultiOption
                                    inputStyle={{ minHeight: 0 }}
                                    placeholder="Lägg till fler trädslag"
                                    label={<p className='subtitle mb-1 mt-3'>Lägg till fler trädslag?</p>}
                                    options={fields}
                                    autoClose={true}
                                    onChange={(val) => { this.setState({ fieldSelected: val }) }} />

                                {fields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        {this.state.fieldSelected && this.state.fieldSelected.some(selectedField => selectedField.id === field.id) && (
                                            <>
                                                <div className="col-6 p-2">{field.label} %</div>
                                                <div className="col-6 p-2">
                                                    <VBInput
                                                        value={this.state[field.id]}
                                                        inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px",borderStyle:"groove" }}
                                                        placeholder={this.state[field.id]}
                                                        type="number"
                                                        error={this.state.errors ? "Andel ska vara positiv och summan av TGL 100" : null}
                                                        onChange={(val) => { this.setState({ [field.id]: val });setTimeout(() => { this.validateTGL() }, 1000)}}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                                
                                <div className="mt-4 d-flex align-items-center justify-content-center">
                                <div className='col-12 col-md-4'>
                                <button className='n-btn btn-forest-green w-100 w-auto' type="submit">Spara</button>
                                </div>
                                <div className='ms-2 col-12 col-md-4'>
                                <button type="button" className="n-btn btn-outline btn-charcoal w-100 w-auto" onClick={() => this.setState({ addSectionModal: false, fieldSelected:[], errors:false })}>Stäng</button>
                                </div>
                            </div>
                            </div>
                        </form>

                    </Modal.Body>

                </Modal>
                <Modal show={this.state.deleteSectionModal} onHide={() => this.setState({ deleteSectionModal: false })} size="md" contentClassName="bg-neutral-charcoal p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                        <h4 className="white mb-0 fw-medium">Är du säker på att du vill radera avsnittet?</h4>
                    </Modal.Header>

                    <Modal.Footer className="justify-content-between border-0">
                        <button type="button" className="n-btn btn-outline primary-core btn-core" onClick={() => this.setState({ deleteSectionModal: false })}>Stäng</button>
                        <button type="button" className="n-btn btn-core" onClick={() => { this.handleDeleteSection() }}>Radera</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default Step2;