import React from 'react';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import Modal from 'react-bootstrap/Modal';
import {createSimpleBid, selectWinningBid} from '../../api';


class AdPlaceBidSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ad: Window.react_data.ad,
      visiblePlaceBidModal: false,
      VisibleShowBidsModal: window.location.toString().includes("#show_bids"),
      open_bids: [],
      temp_files: [],
      bidText: "",
      open_view:false
    }
    const isAdmin = Window.user_data.isAdmin
  }


   //do the conditional on FE instead of BE
  renderAdDetailsStateBar = () => {
    let text = "Din virkesannons granskas av Treebula";
    let icon = "img/icons/icon-center.svg"
    // let filter_color = "neutral-charcoal-filter"
    let extra = null;
    let color="success-500"
    if (this.state.ad.state_name === "WAITING_FOR_REVIEW") {
      /* ad is currently waiting for review */
      text = "Din virkesannons granskas av Treebula";
      icon = "img/icons/icon-center.svg"
      color = "warning-500"
    } else if (this.state.ad.state_name === "AUCTION_READY") {
      /* ad is currently in AR */
      icon = "img/icons/check-circle.svg"
      if(this.state.ad.auction_in_future){
        /* ad will be live in the future */ 
        text = (Window.user_data.loggedInUserID == this.state.ad.ad_user ? 'Din virkesannons' : 'Den här virkesannonsen') + " öppnas för anbud " + this.state.ad.start_time;
      }
      else if(this.state.ad.auction_is_live){
        /* ad is currently live */
        text = (Window.user_data.loggedInUserID === this.state.ad.ad_user ? 'Din virkesannons' : 'Den här virkesannonsen') + " är öppen för anbud"
      } else if (this.state.ad.auction_is_over){
        
        if (this.state.ad.current_user_has_bidded){
          /* current user isn't owner but has placed bid */
          icon = "img/icons/check-circle.svg"
          text = "Auktionen är avslutad, säljaren väljer köpare"
        } else if(Window.user_data.loggedInUserID == this.state.ad.ad_user && this.state.ad.bids_visible.length !== 0){
            text="Klicka på knappen nedan för att se dina anbud och välja köpare"
        }
        else{
          /* current user isn't owner and has not placed bid */
          text = "Denna affär är avslutad:"
        }
      } 
    } else if (this.state.ad.state_name === 'NO_BID_SELECTED') {
      /* Seller did not sell */ 
      icon = "img/icons/Warning.svg"
      color = "warning-500"
      text="Säljaren valde att avvakta och valde inget anbud"
    } else if (this.state.ad.state_name === 'WINNER_SELECTED') {
      /* ad is over and has selected winner */
      icon = "img/icons/check-circle.svg"
      text = "Denna affär är avslutad";
      if(Window.user_data.loggedInUserID === this.state.ad.ad_user){
          /* user is ad owner */
          text = "Du har valt köpare - Köparen kommer att kontakta dig för fortsatt dialog, nästa steg och kontraktskrivning";
        } 
      else if(this.state.ad.winning_bid_user  == Window.user_data.loggedInUserID){
          /* user is winning bidder */
          text="Du har blivit vald som köpare - kontakta skogsägaren för fortsatt dialog, nästa steg och kontraktskrivning"
        } 
      else if(this.state.ad.current_user_has_bidded && this.state.ad.winning_bid_user !== Window.user_data.loggedInUserID){
          /* user has placed bid and din't win */
          icon = "img/icons/XCircle.svg"
          color = "warning-500"
          text="Du har tyvärr inte valts som köpare"
        }

    } else if (this.state.ad.state_name === 'CONTRACT_CREATED') {
      /* ad is over and has contract */
      icon = "img/icons/check-circle.svg"
      text="Treebula granskar virkesaffären"
      if(this.state.ad.signing_method === this.state.ad.SIGNING_ON_PAPER) {
        /* contract handled offline */ 
        if (this.state.ad.winning_bid_user === Window.user_data.loggedInUserID || this.state.ad.ad_user === Window.user_data.loggedInUserID){
          /* user is allowed to view contract */ 
          text = "Affären signeras på papper."
          extra = <a className='text-decoration-underline fw-bold me-3' href={this.state.ad.contractPdf_URL}>Ladda ned kontraktet</a>
        } else if(this.state.ad.current_user_has_bidded){
          /* user has placed bid but was not selected as winner */
          text = "Du valdes tyvärr inte som köpare"
          icon = "img/icons/icon-center.svg"
          color = "warning-500"
        }
        else {
          /* user can't view contract and did not bid */ 
          text = "Treebula granskar virkesaffären"
        }
      } else if (this.state.ad.contract !== 'SIGNED') { 
        /* contract is signed digital, this is DEPRECTED */
        text = "Treebula hanterar signering av kontrakt"
      } else if (this.state.ad.winning_bid_user === Window.user_data.loggedInUserID || this.state.ad.ad_user === Window.user_data.loggedInUserID){
        /* contract is signed, allow download */
        text ="Affären är avslutad."
        extra = <a className='text-decoration-underline fw-bold me-3' href={this.state.ad.contractPdf_URL}>Ladda ned kontraktet.</a>
      }
    } else if (this.state.ad.state_name === 'DRAFT' && this.state.ad.ad_isFullService) {
      /* ad is draft that will be created by virkesbörsen */ 
      icon = "img/icons/icon-center.svg"
      color = "warning-500"
      text="Treebula arbetar med att ta fram din annons"
    } else if (this.state.ad.state_name == "CREATED" && this.state.ad.ad_user === Window.user_data.loggedInUserID){
      text = "Du granskar din annons"
    } 
    else if (this.state.ad.state_name === 'DRAFT' && Window.user_data.isAdmin) {
      /* ad is draft*/ 
      icon = "img/icons/icon-center.svg"
      color = "warning-500"
      text="Annonsen är under bearbetning."
    } else if(Window.user_data.isAdmin) {
      /* ad is in strange state */ 
      icon = "img/icons/Warning.svg"
      // filter_color = "neutral-charcoal-filter"
      text = `Annonsen är ${this.state.ad.state_name == 'INACTIVE' ? 'inaktiv.' : 'ej redo för auktion.'}`
    } 
    else{
      return null
    }

    return (
      <>
      {Window.react_data.trustedBuyer ? null: <p className='mt-2'>Eftersom du inte är ansluten köpare, måste du kontakta oss på <a className="text-decoration-underline fw-bold"  style={{ cursor: 'pointer' }}href="mailto:info@treebula.se">info@treebula.se</a> för att se all information.</p>}
        <div className="d-flex flex-row justify-content-start align-items-center">       
          {text ? <img src={Window.static_path + icon} className={`rounded-circle p-1 bg-${color}`} height='32px' width='32px' alt='Warning Icon' /> : null}
          {text ? <p className={`ms-2 n-fs-5 fw-medium mb-0`}>{text}</p> : null}
        </div>
        {extra ? <div>{extra} <hr className='bg-neutral-charcoal'></hr></div> : null}
      </>
    );
  };

  renderAdsDetailsCTA = () => {
    if (Window.user_data.loggedInUserID !== this.state.ad.ad_user && this.state.ad.auction_is_live) {
      return (
        <div className='d-flex flex-column align-items-center'>
          <hr className='w-100'/>
          <button className="n-btn btn-forest-green text-nowrap w-100" style={{ minWidth: '100px' }} onClick={() => {this.setState({visiblePlaceBidModal: true})}}>Lämna anbud via formulär</button>
          {/* Submit an offer via form */}
          <p className='text-center n-fs-7 my-2'>eller</p>
          <a role='button' className="n-btn btn-forest-green btn-outline text-nowrap w-100" style={{ minWidth: '100px' }} href={`mailto:anbud@treebula.se?subject=Anbud på annons #${this.state.ad.id}`}>
            {/* Submit an offer via email */}
            Lämna anbud via mail
          </a>
          <hr className='w-100'/>
          <div className='bg-neutral-stone-300 p-2' style={{borderRadius:"8px"}}>
          
          <p className='mt-2'>
            Du kan även maila ditt anbud till <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href={`mailto:anbud@treebula.se?subject=Anbud på annons #${this.state.ad.id}`}>anbud@treebula.se</a> eller ringa oss på <a className='text-decoration-underline fw-bold' style={{ cursor: 'pointer' }} href="tel:00468339944">08-33 99 44</a>.
          </p>
          <p> Ange annonsnummer #{this.state.ad.id}.</p>
          </div>        
          {this.state.ad.current_user_has_bidded || this.state.ad.child_account_did_bid || Window.user_data.isAdmin ? (
            <>
            <hr className='w-100'/>
            <button className="n-btn btn-charcoal text-nowrap w-100" style={{ minWidth: '100px' }} onClick={() => {this.setState({VisibleShowBidsModal:true})}} >Se dina anbud<span><img className={`ms-2 primary-core-filter`} style={{ width: "16px", height: "16px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button> </>
          ):null}
          <hr className='w-100'/>
        </div>
      );
    } else if (this.state.ad.state_name === 'AUCTION_READY' && this.state.ad.auction_is_over && 
      this.state.ad.ad_user === Window.user_data.loggedInUserID && (this.state.ad.bids_visible.length !== 0)) {
      return (//  Select buyer
        <div className='d-flex flex-column align-items-center'>
          <hr className='w-100'/>
          <button className="n-btn btn-forest-green text-nowrap w-100 mb-3" style={{ minWidth: '100px' }} onClick={() => { this.setState({ VisibleShowBidsModal: true }) }}>Välj köpare <span><img className={`ms-2 white-filter`} style={{ width: "16px", height: "16px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
        </div>
      )
    } else if (this.state.ad.state_name === 'AUCTION_READY' && this.state.ad.auction_is_over && 
    this.state.ad.ad_user === Window.user_data.loggedInUserID && (this.state.ad.bids_visible.length !== 0)) {
      return (
        <div className='d-flex align-items-start justify-content-center'>
          {/* The timber exchange will contact you regarding your tenders */}
          <p className='text-center mb-0 subtitle fw-bold'>Treebula kontaktar dig gällande dina anbud</p>
        </div>
      )
    } else{
      return <div className='col-12'>
        {(Window.user_data.isAdmin) || (this.state.ad.current_user_has_bidded)  || (this.state.ad.child_account_did_bid) || (this.state.ad.bids_visible.length !== 0 && this.state.ad.ad_user === Window.user_data.loggedInUserID) ? (
          <div className='d-flex flex-column align-items-center'>
            <hr className='w-100'/>
            <button className="n-btn btn-charcoal text-nowrap w-100" style={{ minWidth: '100px' }} onClick={() => {this.setState({VisibleShowBidsModal:true})}} >Se dina anbud<span><img className={`ms-2 primary-core-filter`} style={{ width: "16px", height: "16px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button> 
            <hr className='w-100'/>
            </div>
          ):null}
      </div>
    }

    return null;
  }

  renderAdDatesAndButtons = () => {
    if (this.state.ad.auction_in_future) {
      return <div className='text-center fw-medium n-fs-6'>Startdatum: {this.state.ad.start_time}</div>;
    } else if (this.state.ad.auction_is_live) {
      return (
        <div className='text-center align-items-center'>
          {this.state.ad.days_left <= 1 ? (
            <div className='fw-medium n-fs-6'>Timmar kvar: {this.state.ad.hours_left}</div>
          ) : (
            <div className='fw-medium n-fs-6'>Dagar kvar: {this.state.ad.days_left}</div>
          )}
          {this.state.ad.state_name !== "CREATED" && this.state.ad.ad_user !== Window.user_data.loggedInUserID && <hr/>}
          {this.state.ad.ad_user !== Window.user_data.loggedInUserID && Window.react_data.trustedBuyer &&(

            <div className='col-12 mt-3'>
              {/* Ask the seller */}
              <a className="fw-medium n-fs-7 me-3" style={{ cursor: 'pointer' }} href={`/contact-seller/?ad_id=${this.state.ad.id}`}>
                Fråga säljaren
              </a>
              {this.state.ad.attachments && (
                <a className="fw-medium n-fs-7 ms-3" style={{ cursor: 'pointer' }} onClick={this.scrollToAttachments}>
                  Se viktiga bilagor
                </a>
              )}
            </div>
          )}
        </div>
      );
    } else if (this.state.ad.state_name === 'WAITING_FOR_REVIEW') {
      return (
        //Reviewed by Virkesborsen
        <p className='text-center mb-0 n-fs-7 lh-sm fw-bold mb-3'>Just nu tittar våra specialister på din annons och kommer att godkänna annonsen eller kontakta dig så snabbt som möjligt.</p>
      )
    }
    
  };

  handleUploadFiles = (e) => {


      let files = e.target.files;
      Array.from(files).map((file) => {
          this.state.temp_files.push(file);
      });


    this.setState({temp_files: this.state.temp_files});
  }

  placeBid = () => {
    this.setState({visiblePlaceBidModal: false});
    this.props.toggle_loading();
    let resp = createSimpleBid(this.state.bidText,this.state.temp_files, Window.react_data.ad.id);
    resp.then(() => {
      window.location.reload();
    })

  }

  selectWinningBid = (bid_id) => {
    this.props.toggle_loading();
    let resp = selectWinningBid(bid_id)

    resp.then(() => {
      window.location.reload();
    })
  }

  placeBidModal = () => {
    return <Modal show={this.state.visiblePlaceBidModal} onHide={() => this.setState({ visiblePlaceBidModal: false })} size="lg" contentClassName="bg-neutral-cloud-300" style={{zIndex: 1110}} centered>
      <Modal.Header className="align-items-start border-0" closeButton>
        <div>
          <p className="n-fs-5 fw-medium mb-1">Ange anbud</p>
          <p className="n-fs-7 neutral-pebble">Lägg till text eller ladda upp bilagor</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container-flex">
          <div className="row">
            <div className="col-12">
              <VBTextarea height="128px" onChange={(txt) => { this.setState({ bidText: txt }) }}
                placeholder="Lägg till text"
                label={<p className="pb-2 fw-medium n-fs-7">Ange anbudet i textformat här:</p>} />
              <hr />
              <div className="w-100">
                <p className='fw-medium n-fs-7 mb-1'>Ladda upp anbudsbilagor</p>
                <p className="n-fs-7 neutral-pebble">Ladda upp bilagor som du vill ha med i anbudet exempelvis prislistor.</p>
                {this.state.temp_files.map((file) => {
                  return <a className="bg-neutral-charcoal p-1 me-1 white rounded-3 n-fs-8 d-inline-block">
                    {file.name}
                    <img
                      src={Window.static_path + 'img/icons/X.svg'}
                      className='white-filter'
                      role='button'
                      width='16px'
                      height='16px'
                      onClick={() => {
                        this.state.temp_files.splice(this.state.temp_files.indexOf(file), 1);
                        this.setState({ temp_files: this.state.temp_files });
                      }}
                    />
                  </a>
                })}
              </div>
              <label className="n-btn btn-forest-green btn-sm mt-3"> <img className={`me-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/icon-left.svg"} />Välj filer
                <input onChange={this.handleUploadFiles} type="file" multiple style={{ display: "none" }} />
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <p className="mt-2 mb-2">Treebula tar ut en avgift på 3% på virkesvärdet (bruttovärdet) på affären av dig som köpare ifall du blir vald</p>
        <button className="n-btn btn-forest-green btn-ghost mt-2" onClick={() => { this.setState({ visiblePlaceBidModal: false }) }}>Avbryt</button>
        <button className="n-btn btn-forest-green ms-2 mt-2" onClick={() => { this.placeBid(); }}>Ja, Skicka anbud<span><img className={`ms-2 white-filter`} style={{ width: "16px", height: "16px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></button>
      </Modal.Footer>

    </Modal>
  }
  showBidsModal = () => {
    return <Modal show={this.state.VisibleShowBidsModal} onHide={() => this.setState({ VisibleShowBidsModal: false })} size="lg" contentClassName="bg-neutral-cloud-300" style={{zIndex: 1110}} centered>
      <Modal.Header className="align-items-start border-0" closeVariant="black" closeButton>
        <div>
          <p className="n-fs-5 fw-medium mb-1">Anbud på annons #{Window.react_data.ad.id}</p>
          <p className="n-fs-7 neutral-pebble">Se anbud</p>
        </div>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="container-flex">
          <div className="row">
            {this.state.ad.bids_data.map((itm) => {
              return<div> <div className={`col-12 p-3 rounded-1 mb-2 bg-${Window.react_data.ad.winning_bid_id === itm.id ? "primary-core-100":"white" }`}>
                <div className='d-flex justify-content-between'>
                <p
                  className="n-fs-6 fw-medium"
                  style={{ "cursor": "pointer" }}
           
                >#{itm.id} - {itm.bid_user_name} {Window.react_data.ad.winning_bid_id === itm.id ? " - Vald köpare":null }</p>
                <img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} className={`max-h-25 d-flex-inline neutral-charcoal-filter`} style={{ transform: this.state.open_bids.includes(itm.id) ? 'rotate(180deg)' : '', transition: 'transform 150ms ease' }} onClick={() => {
                    if (this.state.open_bids.indexOf(itm.id) !== -1) {
                      this.state.open_bids.splice(this.state.open_bids.indexOf(itm.id), 1);
                    } else {
                      this.state.open_bids.push(itm.id);
                    }
                    
                    this.setState({"open_bids": this.state.open_bids })
                   
                  }} />
              </div>
                <div className={this.state.open_bids.indexOf(itm.id) !== -1 ? "d-block" : "d-none"}>
                  <div className="w-100">
                    {itm.other_fields.map((f) => {
                      return <p className="mw-100 text-break n-fs-7 fw-medium">
                        <span>{f.label}</span>: {f.value}
                      </p>
                    })}
                  </div>
                  <p className="n-fs-7 pt-2 mb-1 fw-medium">Kommentar</p>
                  <p className="mw-100 text-break n-fs-7 neutral-pebble" style={{whiteSpace:"pre-line"}}>{itm.comments}</p>
                  {itm.attachments.length > 0 ? <p className="fw-medium pt-2 mb-1">Bilagor</p> : null}
                  <div className="w-100">
                    {itm.attachments.map((attch) => {
                      return <a className="bg-neutral-charcoal p-1 me-1 white rounded-2 n-fs-8 d-inline-block" href={attch.url} target="_blank">{attch.file_name}</a>
                    })}
                  </div>
                  {Window.react_data.ad.winning_bid_id === null && Window.react_data.ad.auction_is_over  &&  this.state.ad.ad_user === Window.user_data.loggedInUserID ? <div className="w-100 d-flex justify-content-end">
                    <button className="n-btn btn-forest-green btn-sm mt-1" onClick={() => { this.selectWinningBid(itm.id) }}>Välj denna köpare</button>
                  </div> : null}
                  {Window.react_data.ad.winning_bid_id === itm.id ? <div className="w-100 d-flex justify-content-end">
                    <p className="n-btn btn-forest-green btn-sm mt-1">Vald som vinnare</p>
                  </div> : null}
                </div>
              </div>
              <hr/>
              </div>
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <button className="n-btn btn-forest-green btn-sm mt-2" onClick={() => { this.setState({ VisibleShowBidsModal: false }) }}>Stäng</button>
      </Modal.Footer>

    </Modal>
  }

  scrollToAttachments = () => {
    const attachmentsElement = document.getElementById('attachments');
    if (attachmentsElement) {
      attachmentsElement.scrollIntoView({ behavior: 'smooth'});
    }
  };

  render() {
    return (
      <div className='mb-4 shadow p-3 d-flex flex-column justify-content-center bg-neutral-cloud' style={{ position: 'sticky', top: '24px', zIndex: 100, borderRadius:'12px' }}>
        {this.renderAdDetailsStateBar()}
        {this.renderAdsDetailsCTA()}
        {this.renderAdDatesAndButtons()}
        {this.placeBidModal()}
        {this.showBidsModal()}
      </div>
    );
  }
}

export default AdPlaceBidSection;
