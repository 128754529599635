import React from 'react';
import { DropZone } from "@measured/puck";
import MediaSelector from '../editor/fields/MediaSelector';
import * as Comp from '../editor/PuckComps';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class ColumnsMasonry extends React.Component {
    constructor(props) {
        super(props);
    }

    loadItem = (i, index) => {
        let cardStyle = {}
        if(i.cardHeight) { cardStyle.minHeight = i.cardHeight }
        if(i.backgroundImage) { 
            cardStyle.background = `url(${i.backgroundImage}) center center no-repeat`
            cardStyle.backgroundSize = 'cover'
        }

        return <div className={`rounded-4 p-3 mb-4 ${!i.backgroundImage && 'bg-'+i.cardBackgroundColor}`} style={cardStyle}>
            <DropZone zone={`${index}`} disallow={["Columns", "ColumnsMasonry", "Container", "ContentSectionLayout", "CustomerTestimonials", "FAQ", "FeaturesLayout", "FeatureStepsLayout", "HeroMetric", "ImgContentCard", "Metrics", "PricingSection", "TestimonialWithImage", "Posts", "PricingMap"]} />

            {i.content.map((comp) => {
                // Loop over all the components for that column and render them within
                let Component = Comp[comp.type]
                return <Component {...comp.props} />
            })}
        </div>
    }

    isInRange(number, min, max) {
        return number >= min && number < max;
    }

    render() {
        let items = this.props.columns
        let sizeTwo = Math.round(items.length / 2)
        let sizeThree = Math.round(items.length / 3)
        let remaining = Math.ceil(items.length / 3) - sizeThree

        return <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${this.props.backgroundColor}`}>
                <div className={`container`}>
                    <div className='row'>                        
                        <div className='d-flex d-md-none'>
                            <div className='col-12 p-2'>
                                {items.map((i, index) => { return this.loadItem(i, index) })}
                            </div>
                        </div>

                        {this.props.columnLength == 2 ? <div className='d-none d-md-flex'>
                            <div className='col-6 p-2'>
                                {items.map((i, index) => { if(this.isInRange(index, 0, sizeTwo)) {return this.loadItem(i, index)} })}
                            </div>

                            <div className='col-6 p-2'>
                                {items.map((i, index) => { if(this.isInRange(index, sizeTwo, items.length)) {return this.loadItem(i, index)} })}
                            </div>
                        </div>: null}

                        {this.props.columnLength == 3 ? <div className='d-none d-md-flex'>
                            <div className='col-4 p-2'>
                                {items.map((i, index) => { if(this.isInRange(index, 0, sizeThree+remaining)) {return this.loadItem(i, index)} })}
                            </div>

                            <div className='col-4 p-2'>
                                {items.map((i, index) => { if(this.isInRange(index, sizeThree+remaining, (sizeThree * 2)+remaining)) {return this.loadItem(i, index)} })}
                            </div>

                            <div className='col-4 p-2'>
                                {items.map((i, index) => { if(this.isInRange(index, (sizeThree * 2)+remaining, items.length)) {return this.loadItem(i, index)} })}
                            </div>
                        </div>: null}
                    </div>
                </div>
            </div>
    }
}

ColumnsMasonry.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    columnLength: 3,
    spacing: [],
    columns: [{cardBackgroundColor: 'neutral-stone-500', content: [], cardHeight:"250px"}, {cardBackgroundColor: 'neutral-stone-500', content: [] , cardHeight:"250px"}, {cardBackgroundColor: 'neutral-stone-500', content: [] , cardHeight:"250px"}, {cardBackgroundColor: 'neutral-stone-500', content: [] , cardHeight:"250px"}, {cardBackgroundColor: 'neutral-stone-500', content: [] , cardHeight:"250px"}, {cardBackgroundColor: 'neutral-stone-500', content: [] , cardHeight:"250px"}]
}

ColumnsMasonry.fields = {
    backgroundColor: {
        type: "select",
        options: Opt.colorVariables
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
    },
    columnLength: {
        type: "radio",
        options: [
            { label: "Two", value: 2 },
            { label: "Three", value: 3 },
        ],
    },
    spacing: Opt.spacingOptions,
    columns: {
        type: "array",
        arrayFields: {
            name: {
                type: "text"
            },
            cardBackgroundColor: {
                type: "custom",
                render: (props) => <ColorSelector {...props} />
            },
            backgroundImage: {
                type: "custom",
                render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
            },
            cardHeight: {
                type: "text",
            }
        },
        defaultItemProps: { cardBackgroundColor: 'neutral-stone-500', content: [], cardHeight:"250px"},
        getItemSummary: (col, i) => col.name ? `${col.name} #${i+1}` : `Column #${i+1}`,
    }
}

export default ColumnsMasonry;