import React from 'react';
import MediaSelector from '../editor/fields/MediaSelector';
import { DropZone } from "@measured/puck";
import * as Comp from '../editor/PuckComps';
import * as Opt from '../editor/Helpers'
import { CalculateSpacing } from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';
import RadiusEditor from '../editor/fields/RadiusEditor';

class Columns extends React.Component {
    constructor() {
        super();
    }

    render() {
        let totalWidthSet = 0
        let emptyColumnsCount = 0
        this.props.columns.map((i) => {if(i.width) { totalWidthSet += Number(i.width) } else { emptyColumnsCount += 1 }})
        const columnWidth = (12 - totalWidthSet) / emptyColumnsCount
        
        let containerStyle = {}
        if (this.props.backgroundImage) {
            containerStyle.background = `url(${this.props.backgroundImage}) no-repeat`
            containerStyle.backgroundSize = 'cover'
            containerStyle.zIndex=-1;
        }
        if(this.props.backgroundImagePosition){
            containerStyle.backgroundPosition=this.props.backgroundImagePosition;
        }

        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${!this.props.backgroundImage && !this.props.stretchBackgroundImage ? this.props.backgroundColor : ''} ${(Array.isArray(this.props.borderRadiusOuter)?this.props.borderRadiusOuter:[]).reduce((acum, itm) => acum+itm.radius+" ", "")}`} style={this.props.stretchBackgroundImage ? containerStyle : {}}>
                <div className={`${this.props.containerFluid} ${this.props.guttersContainer}`}>
                    <div className={`row position-relative bg-${!this.props.backgroundImage ? this.props.backgroundColor : ''} ${(Array.isArray(this.props.borderRadiusInner)?this.props.borderRadiusInner:[]).reduce((acum, itm) => acum+itm.radius+" ", "")} ${this.props.guttersRow} ${this.props.reverseOnMobile ? `flex-column-reverse flex-lg-row` : ''} ${this.props.verticalAlignContent} ${this.props.horizontalAlignContent} ${CalculateSpacing(this.props.spacing)}`}>
                        <div className="col-12 h-100 w-100 position-absolute" style={{zIndex:-1}}><div className="w-100 h-100" style={!this.props.stretchBackgroundImage ? containerStyle : {}}></div></div>
                        {this.props.columns.map((i, index) => {
                            let cardStyle = {}
                            if (i.height) { cardStyle.minHeight = i.height }
                            if (i.backgroundImage) {
                                cardStyle.background = `url(${i.backgroundImage}) center center no-repeat`
                                cardStyle.backgroundSize = 'cover'
                            }
                            
                            return <div className={`col-${this.props.doubleColumnsOnMobile == "yes" ? 6 : 12} col-lg-${i.width ? i.width : Math.floor(columnWidth) != 0 ? Math.floor(columnWidth) : 1} `}>
                                <div className={`h-100 d-flex flex-wrap ${(Array.isArray(i.borderRadius)?i.borderRadius:[]).reduce((acum, itm) => acum+itm.radius+" ", "")} ${i.spacing ? CalculateSpacing(i.spacing) : ''}`} style={cardStyle}>
                                    {/* Dropzone is for the Puck editor to be able to drop components into a 'zone' */}
                                        <DropZone zone={`${index}`} disallow={["Columns", "ColumnsMasonry", "Container", "ContentSectionLayout", "CustomerTestimonials", "FAQ", "FeatureStepsLayout", "HeroMetric", "ImgContentCard", "Metrics", "PricingSection", "TestimonialWithImage", "Posts"]} />

                                    {i.content.map((comp) => {
                                        // Loop over all the components for that column and render them within
                                        let Component = Comp[comp.type]
                                        return <Component {...comp.props} />
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

Columns.defaultProps = {
    visibility: "",
    stretchBackgroundImage: null,
    backgroundImagePosition: 'center center',
    backgroundColor: 'white-500',
    borderRadiusInner: [],
    borderRadiusOuter: [],
    guttersContainer: "",
    guttersRow: "",
    containerFluid: "container",
    spacing: [],
    horizontalAlignContent: "justify-content-between",
    verticalAlignContent: "align-items-start",
    columns: [{content: [] }, {content: [] }],
    reverseOnMobile: false,
    doubleColumnsOnMobile: "no",
}

Columns.fields = {
    backgroundImage: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} onChange={onChange} value={value} />,
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    stretchBackgroundImage: {
        type: "select",
        options: [{ label: "Yes", value: true }, { label: "No", value: null }]
    },
    backgroundImagePosition: {
        type: "select",
        options: ["top", "bottom", "center"].reduce((acum, itm) => {
            ["left", "right", "center"].forEach((itm2) => {
                acum.push({label:itm+" "+itm2, value:itm+" "+itm2})
            })
            return acum;
        }, [{label:"top", value:"top"}, {label:"bottom", value:"bottom"}, {label:"center", value:"center"}, {label:"left", value:"left"}, {label:"right", value:"right"}])
    }, 
   backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    borderRadiusInner: {
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    },
    borderRadiusOuter: {
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    },
    containerFluid: {
        type: "radio",
          options: [
            { label: "No", value: "container" },
            { label: "Yes", value: "container-fluid" },
          ],
    },
    guttersContainer: {
        type: "radio",
          options: [
            { label: "Yes", value: "" },
            { label: "No", value: "g-0" },
          ],
    },
    guttersRow: {
        type: "radio",
          options: [
            { label: "Yes", value: "" },
            { label: "No", value: "g-0" },
          ],
    },
    spacing: Opt.spacingOptions,
    columns: {
        type: "array",
        arrayFields: {
            name: {
                type: "text"
            },
            backgroundImage: {
                type: "custom",
                render: ({ name, onChange, value }) => <MediaSelector name={name} onChange={onChange} value={value} />,
            },
            height: {
                type: "text",
            },
            borderRadius: {
                type: "array",
                arrayFields: {
                    radius:{
                        type: "custom",
                        render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
                    }
                }
            },
            width: {
                type: "select",
                options: [{ label: "None", value: null }, { label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "5", value: 5 }, { label: "6", value: 6 }, { label: "7", value: 7 }, { label: "8", value: 8 }, { label: "9", value: 9 }, { label: "10", value: 10 }, { label: "11", value: 11 }, { label: "12", value: 12 }]
            },
            spacing: Opt.spacingOptions
        },
        defaultItemProps: {content: [], horizontalAlign: "justify-content-start", verticalAlign: "align-items-start", borderRadius: [] },
        getItemSummary: (col, i) => col.name ? `${col.name} #${i+1}` : `Column #${i+1}`,
    },
    horizontalAlignContent: {
        type: "select",
        options: Opt.contentJustify
    },
    doubleColumnsOnMobile: {
        type: "select",
        options: [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    },
    reverseOnMobile: {
        type: "select",
        options: [{ label: "Yes", value: true }, { label: "No", value: null }]
    },
    verticalAlignContent: {
        type: "select",
        options: Opt.contentAlignItems
    },

}

export default Columns;