import React from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import Modal from 'react-bootstrap/Modal';

import { validateEmail, validateForm, userExists, loginUser, registerUser } from '../api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';

class LoginRegisterForm extends React.Component {
    constructor() {
        super();

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            errorModal: false,
            formData: {},
            errors: {},
            loading: false,
            loginVisible: true,
            registerVisible: true,
            welcomeVisible:false,
        }

        this.emailInput = React.createRef();
    }

    componentDidMount() {
        if(this.state.isLogged && this.props.redirectIfLogged && this.props.action.actionLocation && !window.location.pathname.includes('editor')) {
            this.setState({ loading: true })
            window.location = this.props.action.actionLocation
        }
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists }, () => {
                                if (this.state.isReg == false) {
                                    this.setState({ registerVisible: false })
                                }
                            })
                        })
                    } else {
                        this.setState({ errorModal: true })
                    }
                })
            }, 200)
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        let checkList = [];
        let data = this.state.formData

        if (!this.state.isLogged) {
            if (!data.email) {
                checkList.push('email')
            } else if (this.state.isReg == true) {
                checkList.push('email', 'password')
            } else {
                checkList.push('roles', 'email', 'muni', 'mobile_phone_number', 'checkbox')
            }
        }

        // Validate the fields
        let validation = validateForm(data, checkList, this.state.isReg)

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            this.setState({ loading: true })
            if (this.state.isLogged) {
                window.location = this.props.action.actionLocation ? this.props.action.actionLocation : '/'
            } else if (this.state.isReg == true) {
                loginUser({ email: data.email, password: data.password }).then(res => {
                    if (res.ok) {
                        setTimeout(() => {
                            let url = window.location.search
                            res.json().then((json) => {
                                if (this.props.action.actionLocation) {
                                    window.location = this.props.action.actionLocation
                                } else if (url.includes('next=')) {
                                    if (json.roles.indexOf("buyer") == -1 && !json.has_active_kyc) {
                                        window.location = '/kyc/' + url
                                    } else {
                                        window.location = url.split('next=')[1]
                                    }
                                } else {
                                    if (json.roles.indexOf("buyer") == -1 && !json.has_active_kyc) {
                                        window.location = '/kyc/'
                                    } else if (json.roles.indexOf("buyer") != -1) {
                                        window.location = '/kop-virke/virkesauktioner/'
                                    } else {
                                        window.location = '/skogsbruksplan/karta/'
                                    }
                                }
                            })
                        }, 100);
                    } else if (res.status == 401) {
                        validation.email = 'E-posten och det angivna lösenordet överensstämmer inte';
                        validation.password = 'E-posten och det angivna lösenordet överensstämmer inte';

                        this.setState({ errors: validation, loading: false })
                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            } else if (this.state.isReg == false) {
                registerUser({ email: data.email, roles: data.roles, muni: data.muni, mobile_phone_number: data.mobile_phone_number}).then(res => {
                    if (res.ok) {
                        let url = window.location.search
                        let dest_url = "/kyc/?next=/skogsbruksplan/karta/";
                        res.json().then((json) => {
                            if (json.roles.indexOf("buyer") == -1) {
                                if (url.includes('next=')) {
                                    dest_url = '/kyc/?next=' + url.split('next=')[1]
                                } else {
                                    dest_url = '/kyc/?next=/skogsbruksplan/karta/'
                                }
                            } else {
                                if (url.includes('next=')) {
                                    dest_url = url.split('next=')[1]
                                } else {
                                    dest_url = '/kop-virke/virkesauktioner/'
                                }
                            }
                            if (this.props.action.actionLocation) {
                                dest_url = this.props.action.actionLocation
                            }
                            setTimeout(() => {
                                gtag('event', 'Registered', {});
                                gtag("event", "sign_up", {
                                    method: "Site",
                                    event_callback: () => {
                                        window.location = dest_url;
                                    }
                                })
                            }, 100);
                        });

                    } else {
                        this.setState({ loading: false, errorModal: true })
                    }
                })
            }
        }
    }
// This part may be added later to allow users to validate their emails 
    passwordSent() {
        return (<div className={`${this.props.textColor}`}>
            <p className={`mb-2 n-fs-6 fw-medium`}>Vad händer nu?</p>
            <p className={`mb-0 n-fs-7`}>1.  Ett lösenord har skickats till din mailadress.</p>
            <p className={`mb-4 n-fs-7`}>2. Ange lösenordet i formuläret nedan.</p>

            <form className='mt-5'>
                <p className='n-fs-5 fw-medium black mb-1'>Ange lösenord</p>
                <VBInput
                    type="password"
                    placeholder="Lösenord"
                    error={this.state.errors.password}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, password: '' } })}
                    onChange={(val) => { this.setState({ formData: { ...this.state.formData, password: val } }); }} />

                <a href="/forgot-password/" className="n-fs-7 fw-bold float-start text-decoration-underline mt-1" target="_blank">Glömt lösenord?</a>

            </form>
            <div className='mt-5 d-flex'>
                <a href="#" className='n-btn btn-core w-100' onClick={(e) => { e.preventDefault(); this.handleSubmit(e) }}>Logga in<span><img className={`ms-2 neutral-charcoal-filter`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></a>
            </div>
            <div className='bg-neutral-cloud supp-forest-green p-3' style={{ borderRadius: "24px", marginTop: "50%" }}>
                <p className='n-fs-6 fw-medium'>Har du frågor?</p>
                <p className='n-fs-7 mt-8'>Du når oss på:</p>
                <p className='n-fs-7 mt-8'>08-33 99 44</p>
                <p className='n-fs-7 mt-8'>info@treebula.se</p>
            </div>

        </div>)
    }

    render() {
        // Setting subText
        let subText = 'Logga in eller skapa ditt konto för att använda våra tjänster.'
        if(this.props.subTextLogged && this.state.isLogged) {
            subText = this.props.subTextLogged
        } else if(this.props.subText) {
            subText = this.props.subText
        } else if(this.state.isLogged) {
            if(this.props.redirectIfLogged) {
                subText = 'Vänta… Så skickar vi dig rätt.'
            } else if(!this.props.action.hideIfLogged) {
                subText = 'Klicka på knappen nedan för att gå vidare.'
            } else {
                subText = `Hej ${Window.user_data.name.includes('@') ? 'användare' : Window.user_data.name},`
            }
        } else {
            if(this.state.isReg === false) {
                subText = 'Skapa konto för att använda våra tjänster'
            } else if(this.state.isReg === true) {
                subText = 'Logga in för att använda våra tjänster.'
            } else {
                subText = 'Logga in eller skapa ditt konto för att använda våra tjänster.'
            }
        }

        // Setting title
        let title = 'Välkommen till Treebula'
        if(this.props.titleLogged && this.state.isLogged) {
            title = this.props.titleLogged
        } else if(this.props.title) {
            title = this.props.title
        }

        return <>
            <VBLoadingScreen show_loading={this.state.loading} />

            <div className={`bg-${this.props.backgroundColor} ${this.props.textColor} w-100`} style={{ borderRadius: "40px", padding: "32px" }}>
                {!this.props.hideBackButton && <a href="javascript:history.back()"><img src={`${Window.static_path}img/icons/caret.svg`} className="rotate-50" width="50px" height="50px" /></a>}

                <p className={`n-fs-3 fw-medium ${!this.props.hideBackButton ? 'mt-5' : ''}`}>{title}</p>

                <p className='mb-4'>{subText}</p>

                {this.state.loginVisible && <form onSubmit={(e) => { this.handleSubmit(e) }} className={`${this.props.textColor}`}>
                    {!this.state.isLogged && <VBInput
                        ref={this.emailInput}
                        type="email"
                        placeholder="E-postadress"
                        label={<label className='n-fs-7 mt-3 neutral-pebble'>E-postadress</label>}
                        error={this.state.errors.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, email: '' } })}
                        onChange={(val) => { this.checkEmail(val); this.setState({ formData: { ...this.state.formData, email: val } }); }} />}

                    {this.state.isReg == true ? <>
                        <VBInput
                            type="password"
                            placeholder="Lösenord"
                            inputClass="mb-2"
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>Ange lösenord</label>}
                            error={this.state.errors.password}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, password: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, password: val } }); }} />

                        <a href="/forgot-password/" className="n-fs-7 fw-bold float-start" target="_blank">Glömt lösenord?</a>
                    </> : null}

                    {this.state.isReg == false ? <>
                        <VBDropdown
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>Ange en roll.</label>}
                            placeholder="Roll"
                            options={Window.react_data.lists.roles}
                            autoClose={true}
                            error={this.state.errors.roles}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, roles: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, roles: val.value } }); }} />

                        <VBMultiOption
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>{this.state.formData?.roles?.indexOf(2) != -1 ? 'Var äger du skog?' : 'Var är du verksam?'}</label>}
                            options={Window.react_data.lists.munis}
                            placeholder="Välj Kommun(er)"
                            autoClose={true}
                            error={this.state.errors.muni}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, muni: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, muni: val.map(itm => parseInt(itm.value)) } }); }} />

                        <VBPhoneInput
                            inputClass='my-1'
                            placeholder="Mobilnummer"
                            label={<label className='n-fs-7 mt-3 neutral-pebble'>Mobilnummer</label>}
                            error={this.state.errors.mobile_phone_number}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, mobile_phone_number: '' } })}
                            onChange={(val) => { this.setState({ formData: { ...this.state.formData, mobile_phone_number: val } }); }} />

                        <div className='d-flex flex-column' style={{ marginTop: "24px" }}>
                            {this.state.errors?.checkbox && 
                                <p className='text-danger n-fs-7 mb-1'>{this.state.errors?.checkbox}</p>
                            }
                            <div className='d-flex align-items-center'>
                                <VBCheckbox
                                    label=""
                                    initial={false}
                                    onClick={() => { this.setState({ formData: {...this.state.formData, checkbox: !this.state.formData.checkbox}, errors: { ...this.state.errors, checkbox: '' } }) }} />
                                    
                                <p className='mb-0 n-fs-7'>Genom att klicka på någon av knapparna ovan godkänner du våra <a href="/terms/"  className={`text-decoration-underline`}>användarvillkor</a> och <a href="/privacy/" className={`text-decoration-underline`}>integritetspolicy.</a> Jag förstår att jag skapar ett konto för att fortsätta.</p>
                            </div>
                        </div>
                    </> : null}
                            
                    {(!this.state.isLogged || (this.state.isLogged && !this.props.action.hideIfLogged)) && <div className='mt-5 d-flex'>
                        <a href="#" className='n-btn btn-core w-100' onClick={(e) => { e.preventDefault(); this.handleSubmit(e) }}>{(this.props.action.buttonTextLogged && this.state.isLogged) ? this.props.action.buttonTextLogged : this.props.action.buttonText ? this.props.action.buttonText : this.state.isReg == false ? "Skapa konto" : this.state.isLogged ? "Fortsätt" : "Logga in"}<img className='ms-2 neutral-charcoal-filter' width="18px" height="18px" src={Window.static_path + "img/icons/arrow-black.svg"} /></a>
                    </div>}

                    <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered>
                        <Modal.Body>
                            <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
                            <p className='n-fs-5 neutral-charcoal py-3'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>
                            <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}}onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                        </Modal.Body>
                    </Modal>
                </form>}

                {this.props.showRegisterCard && this.state.registerVisible && <div className='bg-neutral-cloud p-4' style={{ borderRadius: "24px", marginTop: "50%" }}>
                    <p className='n-fs-5 fw-medium black d-flex justify-content-center'>Bli medlem</p>
                    <p className='n-fs-6 black d-flex justify-content-center'>Har du inget konto? Kom igång gratis</p>
                    <div className='mt-4 d-flex'>
                        <a href="#" className='n-btn w-100' onClick={() => { e.preventDefault(); this.setState({ isReg: false, registerVisible: false }) }}>Skapa konto<span><img className={`ms-2 neutral-charcoal-filter`} style={{ width: "24px", height: "24px" }} src={Window.static_path + "img/icons/arrow-black.svg"} /></span></a>
                    </div>
                </div>}

                {this.state.welcomeVisible && this.passwordSent()}
            </div>
        </>
    }
}

LoginRegisterForm.defaultProps = {
    title: null,
    titleLogged: null,
    subText: null,
    subTextLogged: null,
    textColor: "neutral-charcoal",
    backgroundColor: "white",
    showRegisterCard: false,
    hideBackButton: false,
    redirectIfLogged: false,
    action: {buttonText: null, buttonLogged: null, actionLocation: null, hideIfLogged: false}
}

LoginRegisterForm.fields = {
    title: {
        type: "text",
    },
    titleLogged: {
        type: "text",
    },
    subText: {
        type: "text",
    },
    subTextLogged: {
        type: "text",
    },
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    showRegisterCard: {
        type: "radio",
        options: [
            {label: "Yes", value: true},
            {label: "No", value: false}
        ]
    },
    hideBackButton: {
        type: "radio",
        options: [
            {label: "Yes", value: true},
            {label: "No", value: false}
        ]
    },
    redirectIfLogged: {
        type: "radio",
        options: [
            {label: "Yes", value: true},
            {label: "No", value: false}
        ]
    },
    action: {
        type: "object",
        objectFields: {
            buttonText: { type: "text" },
            buttonTextLogged: { type: "text" },
            actionLocation: { type: "text" },
            hideIfLogged: { type: "radio", options: [ {label: "yes", value: true}, {label: "No", value: false} ]}
        },
      },
}

export default LoginRegisterForm;