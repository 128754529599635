import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';

import GroupedBarGraph from '../Graphs/GroupedBarGraph';
import PieChart from '../Graphs/PieChart';
import PropSelector from './PropSelector';

const Span = styled.span`
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
    background-color: ${props => props.bgColor}; 
`

class Economy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            selectedSection: null,
            editActions: false,
            selectedActionID: null,
            createUpdateActionName: "",
            createUpdateActionYear: "",
            createUpdateActionComment: "",
            // Modals
            createUpdateActionModal: false,
            removeActionModal: false
        }
    }



    startLoading() {

        var loadingMessages = [
            <React.Fragment><h2 className="white w-100 text-center pt-1">Hämtar laserdata...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Hämtar publik data...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Startar Algoritm...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar avdelningar...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar virkesvärden...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar trädslag...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Läser av trädhöjd...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar marken...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Bedömer risker för barkborre och brand...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">hämtar virkespriser...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar trädslag<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar risker...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Slutför, detta kan ta några minuter</h2><p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></React.Fragment>
        ]
        var i = 0
        var intervalID = setInterval(() => {
            this.setState({ loading_message: loadingMessages[i] })
            if (i + 1 <= loadingMessages.length - 1) {
                i += 1
            }
        }, 5000)
        this.setState({ loading: true, intervalID: intervalID });
    }

    stopLoading() {
        this.setState({ "loading": false });
        clearInterval(this.state.intervalID);
    }

    onFinish(json, borderjson, selectedProp) {
        this.setState({ data: json["data"], area: json["area"], sun_area: json["sun_area"], selectedProp: selectedProp });
    }

    getSections() {
        return <div>
            <button className="btn btn-primary mb-2" onClick={() => { this.setState({ editActions: false }) }}>Tillbaka till grafer</button>
            <div className="bg-white rounded border border-2 border-tertiary-dark">
                <div className="row g-0 subtitle fw-bold border-bottom border-1 border-tertiary-dark p-2">
                    <div className='col-1'>#</div>
                    <div className='col-1'>ha</div>
                    <div className='col-2'>Ålder</div>
                    <div className='col-2'>HKL</div>
                    <div className='col-1'>SI</div>
                    <div className='col-2'>Volym</div>
                    <div className='col-3 col-lg-2'>T | G | L</div>
                </div>
                {this.state.data.map((itm) => {
                    let majorTree = "B"
                    if (itm.tall > itm.gran && itm.tall > itm.lov) {
                        majorTree = "T"
                    } else if (itm.gran > itm.tall && itm.gran > itm.lov) {
                        majorTree = "G"
                    }
                    return <div className="container-fluid g-0 border-bottom border-1 border-tertiary-dark px-2">
                        <div className='row g-0 py-1'>
                            <div className='col-1'>#{itm.localID}</div>
                            <div className='col-1'>{itm.area}</div>
                            <div className='col-2'>{itm.age}</div>
                            <div className='col-2'>{itm.felling_class}</div>
                            <div className='col-1'>{itm.si > 0 ? <React.Fragment>{majorTree}{itm.si}</React.Fragment> : null}</div>
                            <div className='col-2'>{itm.volume} m³sk</div>
                            <div className='col-3 col-lg-2'>{itm.tall} | {itm.gran} | {itm.lov}</div>
                        </div>
                        {itm.actions.length > 0 ?
                            <div className="container-fluid bg-primary-main rounded">
                                <div className="row py-1">
                                    <div className="col-12">
                                        <p className="mt-1 mb-0">Planerade åtgärder</p>
                                    </div>
                                </div>
                                <div className="row subtitle fw-bold py-1">
                                    <div className="col-6 col-lg-4">Åtgärd</div>
                                    <div className="col-2 col-lg-1">Utför</div>
                                    <div className="col-2 col-lg-1 text-nowrap">Resultat(kr)</div>
                                </div>
                                {itm.actions.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => Number(a.year) - Number(b.year)).map((acti) => {
                                    return <div className="row align-items-center subtitle py-1">
                                        <div className="col-6 col-lg-4">{acti.name}</div>
                                        <div className="col-2 col-lg-1">{acti.year}</div>
                                        <div className="col-2 col-lg-1">{acti.result.toLocaleString('sv-SE')}</div>
                                        <div className="col-1">
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        selectedActionID: acti.pk,
                                                        selectedSection: itm,
                                                        createUpdateActionName: acti.name,
                                                        createUpdateActionYear: acti.year,
                                                        createUpdateActionComment: acti.comment,
                                                        createUpdateActionModal: true
                                                    })
                                                }}
                                                role="button"
                                                className="white-filter"
                                                src={Window.static_path + "img/icons/ui-edit.svg"}
                                                width="25px"
                                                height="25px" />
                                        </div>
                                        <div className="col-1">
                                            <a role="button" onClick={() => { this.setState({ selectedActionID: acti.pk, selectedSection: itm, removeActionModal: true }) }}
                                                style={{ color: "red", fontSize: "24px" }}>
                                                &times;
                                            </a>
                                        </div>
                                    </div>
                                })}
                            </div>
                            : null}
                        <div className="row primary-dark p-1">
                            <div className="col-6 col-lg-4"><button className="btn btn-primary bg-secondary-main border-secondary-main btn-sm rounded primary-dark border-0 fw-bold" onClick={() => {
                                this.setState({
                                    selectedActionID: null,
                                    selectedSection: itm,
                                    createUpdateActionName: "Plantering",
                                    createUpdateActionYear: "",
                                    createUpdateActionComment: "",
                                    createUpdateActionModal: true
                                })
                            }}>
                                Lägg till åtgärd
                            </button>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    getGraphs() {
        let profit = 0;
        let cost = 0;

        let years = []
        let profit_by_year = []
        let cost_by_year = []

        let cost_profit_per_year = {}
        this.state.data.forEach((itm) => {
            itm.actions.forEach((action) => {
                if (!cost_profit_per_year.hasOwnProperty(action.year)) {
                    cost_profit_per_year[action.year] = { "cost": 0, "profit": 0 };
                }
                if (action.result > 0) {
                    profit += action.result;
                    cost_profit_per_year[action.year]["profit"] += action.result;
                } else {
                    cost += action.result * -1;
                    cost_profit_per_year[action.year]["cost"] += action.result * -1;
                }
            });
        })

        Object.keys(cost_profit_per_year).forEach((year) => {
            years.push(year);
            profit_by_year.push(cost_profit_per_year[year]["profit"])
            cost_by_year.push(cost_profit_per_year[year]["cost"])
        })

        return <div className="row">
            <div className="col-12 col-lg-8 mb-3">
                <div className="bg-white rounded h-100 p-3">
                    <GroupedBarGraph {...{
                        topRight: <React.Fragment><p className='caption pine textBold'>Y-axeln: <span> Kr</span> </p><p className='caption pine textBold'>X-axeln: <span>År</span></p></React.Fragment>,
                        title: 'Balansräkning',
                        description: '',
                        page: 'sbp',
                        legend: <ul className='d-flex flex-column py-1' style={{ listStyle: "none" }}>
                            <li className='caption pine'><Span bgColor="#00ABAC"></Span> &nbsp; Vinst</li>
                            <li className='caption pine'><Span bgColor="#A2CCB7"></Span> &nbsp; Kostnad</li>
                        </ul>,
                        data: {
                            labels: years,
                            datasets: [
                                {
                                    label: "Vinst",
                                    data: profit_by_year,
                                    backgroundColor: '#00ABAC',
                                    borderSkipped: false,
                                    borderWidth: 1,
                                    borderRadius: 50,
                                    categoryPercentage: 0.4,
                                    barPercentage: 1
                                },
                                {
                                    label: "Kostnader",
                                    data: cost_by_year,
                                    backgroundColor: '#A2CCB7',
                                    borderSkipped: false,
                                    borderWidth: 1,
                                    borderRadius: 50,
                                    categoryPercentage: 0.4,
                                    barPercentage: 1
                                },

                            ],
                        },
                        options: {
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true
                                },
                                datalabels: {
                                    color: 'transparent'
                                }
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        color: '#C4D0CD',
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        autoSkip: false,
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        color: '#C4D0CD',
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        beginAtZero: true,
                                        min: 0,
                                        max: 3000,
                                        stepSize: 100,
                                        grace: "0.005%",
                                        callback: function (value, index, ticks) {
                                            return value + " " + '-';
                                        },
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                },
                            },
                            maintainAspectRatio: false,
                            responsive: true
                        }
                    }} />
                </div>
            </div>

            <div className="col-12 col-lg-4 mb-3">
                <div className="bg-white rounded h-100 p-3">
                    <PieChart {...{
                        topRight: null,
                        title: 'Kostnader och Vinster',
                        description: 'Visiualisering av fördelningen mellan kostnader och vinster',
                        page: 'sbp',
                        legend: <ul className='d-flex flex-column py-1' style={{ listStyle: "none" }}>
                            <li className='caption pine'><Span bgColor="#00ABAC"></Span> &nbsp; Vinst: {profit.toLocaleString('sv-SE')} kr</li>
                            <li className='caption pine'><Span bgColor="#A2CCB7"></Span> &nbsp; Kostnad: {cost.toLocaleString('sv-SE')} kr</li>
                        </ul>,
                        options: {
                            plugins: {
                                tooltip: {
                                    enabled: true
                                },
                            },
                            legend: {
                                display: false,
                            },
                        },
                        data: {
                            datasets: [
                                {
                                    label: '# av resultat',
                                    data: [(profit), (cost)],
                                    backgroundColor: [
                                        '#00ABAC',
                                        '#A2CCB7',

                                    ],
                                    borderColor: [
                                        '#00ABAC',
                                        '#A2CCB7',
                                    ],
                                    borderWidth: 1,
                                },
                            ],
                        }
                    }} />
                </div>
            </div>

            <div className="col-12 text-center text-md-start">
                <button className="btn btn-primary" onClick={() => { this.setState({ editActions: true }) }}>Redigera åtgärder</button>
            </div>
        </div>
    }

    createAction() {
        this.setState({ loading: true });
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let data = {
            "propID": this.state.selectedProp.value,
            "sectionID": this.state.selectedSection.nr,
            "actionName": this.state.createUpdateActionName,
            "actionYear": this.state.createUpdateActionYear,
            "actionComment": this.state.createUpdateActionComment
        }
        fetch("/map-api/user-create-section-action/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(res => res.json()).then(json => {
            this.setState({ loading: false, data: json["data"], createUpdateActionModal: false });
        });
    }

    updateAction() {
        this.setState({ loading: true });
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let data = {
            "propID": this.state.selectedProp.value,
            "sectionID": this.state.selectedSection.nr,
            "actionName": this.state.createUpdateActionName,
            "actionYear": this.state.createUpdateActionYear,
            "actionComment": this.state.createUpdateActionComment,
            "actionID": this.state.selectedActionID
        }
        fetch("/map-api/user-update-section-action/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(res => res.json()).then(json => {
            this.setState({ loading: false, data: json["data"], createUpdateActionModal: false });
        });
    }

    deleteAction() {
        this.setState({ loading: true });
        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
        let data = {
            "propID": this.state.selectedProp.value,
            "sectionID": this.state.selectedSection.nr,
            "actionID": this.state.selectedActionID,
        }
        fetch("/map-api/user-delete-section-action/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(res => res.json()).then(json => {
            this.setState({ loading: false, data: json["data"], removeActionModal: false });
        });
    }


    render() {
        return (
            <div className='container-fluid bg-secondary-light overflow-auto py-5' style={{maxHeight: "100vh"}}>
                <VBLoadingScreen
                    show_loading={this.state.loading}
                    disable_reload_timeout={true}
                    loading_text={this.state.loading_message} />

                <PropSelector
                    onDivideStart={() => {
                        this.startLoading();
                    }}
                    onDivideFinish={(json, BorderGeojson, selectedProp) => {
                        if (json.hasOwnProperty("over_limit")) {
                            alert('Det verkar som att du har löst in alla dina skogsbruksplaner.');
                        }

                        this.onFinish(json, BorderGeojson, selectedProp);
                        this.stopLoading();
                        
                    }} 
                    ownedProps={Window.react_data.ownedProps}
                    />

                {this.state.data.length > 0 ? this.state.editActions ? this.getSections() : this.getGraphs() : <h3>Välj fastighet i listan ovan</h3>}

                {this.state.selectedSection ? <Modal show={this.state.createUpdateActionModal} onHide={() => this.setState({ createUpdateActionModal: false, selectedSection: null })} size="lg" contentClassName="bg-primary-dark p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                        {this.state.selectedActionID ?
                            <h2 className="primary-main mb-0">Uppdatera åtgärd på avdelning #{this.state.selectedSection.localID}</h2>
                            : <h2 className="primary-main mb-0">Lägg till åtgärd på avdelning #{this.state.selectedSection.localID}</h2>}
                    </Modal.Header>
                    <Modal.Body className="py-0">
                        <p className='white pt-3 mb-1'>Välj åtgärd</p>
                        <VBDropdown
                            initial={this.state.createUpdateActionName}
                            onChange={((itm) => { this.setState({ createUpdateActionName: itm.value }); })}
                            options={[
                                { "label": "Plantering", "value": "Plantering" },
                                { "label": "Markberedning", "value": "Markberedning" },
                                { "label": "Röjning", "value": "Röjning" },
                                /*{"label": "Återväxtkontroll", "value": "Återväxtkontroll"},*/
                                { "label": "Naturvårdshuggning", "value": "Naturvårdshuggning" },
                                { "label": "Första gallring", "value": "Första gallring" },
                                { "label": "Andra gallring", "value": "Andra gallring" },
                                { "label": "Föryngringsavverkning", "value": "Föryngringsavverkning" },
                                { "label": "Ingen åtgärd", "value": "Ingen åtgärd" }
                            ]} />

                        <p className='white pt-3 mb-1'>Ange vilket år det ska utföras</p>
                        <VBInput
                            initial={this.state.createUpdateActionYear}
                            type="number"
                            onChange={(val) => { this.setState({ createUpdateActionYear: val }); }} />

                        <p className='white pt-3 mb-1'>Övrig kommentar</p>
                        <VBTextarea
                            initial={this.state.createUpdateActionComment}
                            height="200px"
                            resize="none"
                            onChange={(val) => { this.setState({ createUpdateActionComment: val }); }} />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between border-0">
                        <button type="button" className="btn btn-secondary btn-sm m-3" onClick={() => { this.setState({ createUpdateActionModal: false, selectedSection: null }) }}>Avbryt</button>
                        {this.state.selectedActionID ?
                            <button type="button" className="btn btn-secondary btn-sm m-3" onClick={() => { this.updateAction(); }}>Spara åtgärd</button>
                            : <button type="button" className="btn btn-secondary btn-sm m-3" onClick={() => { this.createAction(); }}>Spara åtgärd</button>
                        }
                    </Modal.Footer>
                </Modal> : null}

                <Modal show={this.state.removeActionModal} onHide={() => this.setState({ removeActionModal: false })} size="md" contentClassName="bg-primary-dark p-2" centered>
                    <Modal.Header className="align-items-start border-0" closeVariant="white" closeButton>
                        <h2 className="primary-main mb-0">Är du säker på att du vill radera denna åtgärd?</h2>
                    </Modal.Header>

                    <Modal.Footer className="justify-content-between border-0">
                        <button type="button" className="btn btn-secondary btn-sm m-3" onClick={() => { this.setState({ removeActionModal: false }) }}>Avbryt</button>
                        <button type="button" className="btn btn-secondary btn-sm m-3" onClick={() => { this.deleteAction() }}>Radera åtgärd</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default Economy;