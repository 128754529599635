import React from 'react';
import VBLeaflet from "@virkesborsen/vb-js-commons/lib/components/vbLeaflet";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

import { getShapeInfo } from "../map_api";
import styled from "styled-components";

const MapContainer = styled.div`

    @media (max-width: 577px) {
        .leaflet-top {
            top: 90px;
        }
    }
    .localId-label{
        background-color: transparent !important;
        color:white;
        border: none !important;
        border-radius: none !important;
        box-shadow: none !important;
    }
`

class LeafletMapHandler extends React.Component {

    constructor(props) {
        super(props);
        this.currentClickedLayer = null;

        this.state = {
            map: null,
            drawn: null,
            initalGeojson: null,
            propsSearch: [],
            selectedPropId: null,
            loading: false,
            intervalID: null,
            sections_data: {},
            loading_message: <h2 className="white w-100 text-center pt-1">Vänta...</h2>,
            legendData:[],
            geojson:null
        }
        this.map = React.createRef();
        this.borderGeoJsonLayerGroup = L.layerGroup();
    }

    handleShapeClick(e, all_shapes) {
        this.map.current.setState({selectedSection:e})
        //Set correct color on previous layer
        all_shapes.eachLayer((layer) => {
            let color = "#ffffff"
            if (layer.feature["properties"].hasOwnProperty("nr")) {
                if(this.props.selectedRisk && this.props.selectedRisk.name == "Fire") {
                    color = this.getFireRiskColor(this.state.sections_data[layer.feature.properties.nr].risks.find(r => r.type == 'Brand Risk'))
                } else if(this.props.selectedRisk && this.props.selectedRisk.name == "Bug") {
                    color = this.getBugRiskColor(this.state.sections_data[layer.feature.properties.nr].risks.find(r => r.type == 'Granbarkborre Risk'))
                } else {
                    color = this.getLayerColor(this.state.sections_data[layer.feature.properties.nr]);
                }
            }
            // Use the original style if available, otherwise set the color
            const originalStyle = layer.originalStyle || { color: color };
            layer.setStyle(originalStyle);
        })

        // Set the style of the clicked layer back to its original color
        e.target.setStyle(e.target.originalStyle || { color: "#ffffff" });
        e.target.bringToFront();
        e.target.bringToFront();
        let geojson = e.target.toGeoJSON();
        if (this.state.sections_data.hasOwnProperty(e.target.feature.properties.nr)) {
            let val = this.state.sections_data[e.target.feature.properties.nr]
            this.props.onSectionClick(val);
        } else {
            this.setState({ loading: true });
            let data = getShapeInfo(geojson);
            data.then((val) => {
                this.props.onSectionClick(val);
                this.setState({ loading: false });
            })
        }
    }

    handleShapeEdit(e, all_shapes) {
        this.props.handleShapeEdit(e, all_shapes);
    }

    handleShapeCreate(e, all_shapes) {
        this.props.handleShapeCreate(e, all_shapes);
    }

    handleShapeDelete(del, all_shapes) {
        this.props.handleShapeDelete(del, all_shapes);
    }

    getLayerColor(sect_data) {
        let felling_class = sect_data.felling_class;
        let color = ""
        if (felling_class == "S1") {
            color = "#c9c9c9"
        } else if (felling_class == "S2") {
            color = "#696969"
        } else if (felling_class == "S3") {
            color = "#393939"
        } else if (felling_class == "G1") {
            color = "#8af389"
        } else if (felling_class == "G2") {
            color = "#10950f"
        } else if (felling_class == "R") {
            color = "#95d0ea"
        } else if (felling_class == "K"){
            color = "#ff8080"
        } else{
            color = "#bb0000"
        }
        return color;
    }

    getBugRiskColor(data){
        // "Granbarkborre Risk"
        let risks_severity = data.severity
        let color=""
        if(risks_severity == "Medel risk"){
            color="#FFFF00"
        }
        else if (risks_severity =="Hög risk"){
            color="#FF0000"
        }
        else if (risks_severity == "Liten risk"){
            color = "#008000"
        }
        else{
            color="#808080"
        }
        return color;
        
    }
    getFireRiskColor(data){
        //Brand risk
        let risks_severity = data.severity
        let color=""
        if (risks_severity == "Mycket liten risk"){
            color= "#0000FF"
        }
        else if (risks_severity == "Liten risk"){
            color="#8A2bE2"
        }
        else if (risks_severity == "Måttlig risk"){
            color="#00FF00"
        }
        else if(risks_severity == "Stor risk"){
            color="#FFFF00"
        }
        else if (risks_severity=="Mycket stor risk"){
            color="#FFA500"
        }
        else if(risks_severity=="Extremt stor risk"){
            color= "#FF0000"
        }
        else{
            color="#808080"
        }
        return color;
    }

    clearMap(callback) {
        if (this.map.current.state.drawn) {
            this.map.current.state.drawn.clearLayers();
            this.map.current.state.map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    this.map.current.state.map.removeLayer(layer);
                }
            });
        }
        if (this.borderGeoJsonLayerGroup.getLayers().length !== 0) {
            this.map.current.state.map.removeLayer(this.borderGeoJsonLayerGroup)
            this.borderGeoJsonLayerGroup = L.layerGroup([],{interactive:false});
        }

        this.setState({ legendData: [] }, () => {
            // Call the callback function after clearing is complete
            callback(true);
        });
    }

    setMapGeojson(prop) {
        this.setState({geojson: prop.json})
        let sections_data = this.formatSectionsData(prop);
        this.map.current.setState({marked: L.featureGroup([])});
        //During update remove previous label
        this.map.current.state.map.eachLayer((layer) => {
            if (layer instanceof L.Tooltip) {
                this.map.current.state.map.removeLayer(layer);
            }
        })
        var legendData = this.state.legendData;
        var uniqueLegendData = new Set(this.state.legendData.map(i => i.label));
        var localIDLabels = []
        var title = "";
        var legendDataExplanation = []

        // Add the property borders to the map
        if (prop.BorderGeojson) {
            L.geoJson(prop.BorderGeojson, {
            interactive: false,
                onEachFeature: (feature, layer) => {
                    layer.setStyle({
                        color: "#FFFFFF", //border color
                        fillOpacity: 0,
                    });
                     
                }
            }).addTo(this.borderGeoJsonLayerGroup);
        }
        this.borderGeoJsonLayerGroup.addTo(this.map.current.state.map)

        L.geoJson(prop.json.geoJson, {
            interactive: true,
            onEachFeature: (feature, layer) => {
                let color;
                let legendLabel;
                if (this.props.selectedRisk && this.props.selectedRisk.name == "Fire"){
                    let risk_data = sections_data[feature.properties.nr].risks.find(r => r.type == 'Brand Risk')
                    color = this.getFireRiskColor(risk_data)
                    legendLabel = risk_data.severity
                    title = "Brand Risk"  
                } else if (this.props.selectedRisk && this.props.selectedRisk.name == "Bug"){    
                    let risk_data = sections_data[feature.properties.nr].risks.find(r => r.type == 'Granbarkborre Risk')
                    color = this.getBugRiskColor(risk_data)
                    legendLabel = risk_data.severity
                    title = "Granbarkborre Risk" 
                } else {
                    color = this.getLayerColor(sections_data[feature.properties.nr]);
                    legendLabel = sections_data[feature.properties.nr].felling_class;
                    title = "Huggningsklasser"
                    legendDataExplanation = [
                        "S1: yngre slutavverkningsbar skog", 
                        "S2: mogen avverkningsbar skog som bör slutavverkas", 
                        "G1: yngre gallringsskog",
                        "G2: äldre gallringsskog som uppnått lägsta ålder för slutavverkning", 
                        "R: röjningsskog", 
                        "K: kalmark före föryngringsåtgärder" ,
                        "IA: Ingen åtgärd" ,
                        "R1: R"         
                    ];
                }
               
                let originalWeight = 1.8; //set the original border weight

                //Add mouseover event
                layer.on('mouseover', () => {
                    // Check if the layer is the clicked layer; if it is, do not apply the styling
                    if (this.map.current.state.clickedLayer && this.map.current.state.clickedLayer === layer) {
                    // The layer is the currently clicked layer, so do not apply the styling
                    return;
                    }
                        layer.setStyle({
                            fillColor: color,
                            fillOpacity: 0.8,
                            weight: originalWeight + 1.5,
                            color: 'white', //dashArray is now in white color
                            dashArray: '10, 10' //add a dashed style border effect
                        });
                        layer.bringToFront();
                    });

                //Add mouseout event
                layer.on('mouseout', () => {
                    //check if the layer is clicked; if clicked, do not remove the style
                    if (!this.map.current.state.clickedLayer || this.map.current.state.clickedLayer !== layer) {
                        layer.setStyle({
                            fillColor: color,
                            fillOpacity: this.currentClickedLayer === layer ? 0.9 : 0.5,
                            weight: this.currentClickedLayer === layer ? 3.0 : originalWeight, //revert the border thickness
                            color: this.currentClickedLayer === layer ? 'white' : color,
                            dashArray: null,  //remove dashed style border effect
                        });
                    }
                    
                    layer.bringToFront();
                });
                
                // Check if a new legend item is not in the list yet, if it is add it to the list
                if (!uniqueLegendData.has(legendLabel)){
                    uniqueLegendData.add(legendLabel);
                    legendData.push({label: legendLabel, color: color})
                }
                layer.setStyle({
                    color: color,
                    fillOpacity: 0.5,
                    weight: 3.0,
                });
                layer.bringToBack();
                this.map.current.state.drawn.addLayer(layer);

                layer.on('click', (e) => { 
                    this.map.current.onShapeClick(e);

                    // Reset the style of the previously clicked layer
                    if (this.currentClickedLayer && this.currentClickedLayer !== layer) {
                        this.currentClickedLayer.setStyle({
                            fillOpacity: 0.5, //set opacity 0.5 for the prev clickec layer
                        });
                        // Bring the previously clicked layer to back
                        this.currentClickedLayer.bringToBack();
                    }

                    // Update the currently clicked layer
                    this.currentClickedLayer = layer;

                    // Bring the clicked layer to the front
                    layer.bringToFront();
                    layer.setStyle({
                        fillColor: color,
                        fillOpacity: 0.8,
                        weight: originalWeight + 1.5,
                        color: 'white',
                        dashArray: null
                    });
                }); 

                localIDLabels.push({
                    location:sections_data[feature.properties.nr]["label_location"],
                    label: sections_data[feature.properties.nr]["localID"] 
                });
            }
        });

        this.map.current.state.drawn.bringToBack();

        prop.json.markers.data.forEach((itm) => {
            var icon = L.icon({
                iconUrl: itm.icon,

                iconSize: [40, 57], // size of the icon
                shadowSize: [40, 57], // size of the shadow
                iconAnchor: [20, 57], // point of the icon which will correspond to marker's location (actual length divide by 2)
                shadowAnchor: [40, 57],  // the same for the shadow
                popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
            });
            let mark = L.marker([itm.y, itm.x], { icon: icon })
            mark.django_id = itm.id
            mark.addTo(this.map.current.state.map);
            let content = "<b>" + itm.name + "</b><br>" + itm.comment;
            content += "<div class='container-fluid><div class='row'>";
            content += "<a id='edit-marker' class='mx-1 pine col text-decoration-underline' role='button'>Ändra</a>";
            content += "<a id='delete-marker' class='mx-1 col highlight text-decoration-underline' role='button'>Radera</a>";
            content += "<div></div>";
            mark.bindPopup(content)
            //Adding edit functionality on marker
            var func = function () {
                var editButton = document.getElementById('edit-marker');
                editButton.addEventListener('click', function () {
                    this.props.editMarker(itm, prop.info.value);
                }.bind(this));
                //Delete marker
                var delButton = document.getElementById('delete-marker');
                delButton.addEventListener('click', function () {
                    this.props.deleteMarker(itm.id, prop.info.value);
                }.bind(this));
            }.bind(this);
            //Add this click to avoid bug when moving from open popup to open popup
            mark.on("click", function () { setTimeout(func, 500); });
            //bind this for slow PCs that miss the timing on the above func
            mark.on('popupopen', func)
        })

        this.map.current.setState({ drawn: this.map.current.state.drawn });
        this.setState({ sections_data: {...this.state.sections_data, ...sections_data}, avg_prices: prop.json.avg_prices, legendData: legendData });
        this.map.current.setLocalIDData(localIDLabels)
        
        const explanation = legendDataExplanation.filter(explanation => {
            const label = explanation.split(":")[0].trim();
            return legendData.some(data => data.label === label);
        });
        this.map.current.setLegendData(legendData,title,explanation)
    }

    formatSectionsData(prop) {
        let sections_data = {}
        prop.json.data.map((itm) => {
            sections_data[itm.nr] = { ...itm, property_name: prop.info.label, lm_id: prop.info.value }
        });
        return sections_data
    }

    onFinish(prop) {
        if (prop.json.hasOwnProperty("over_limit")) {
            alert('Det verkar som att du har löst in alla dina skogsbruksplaner');
            return;
        }

        this.setMapGeojson(prop);

        // Locate where the property is and zoom into it
        this.map.current.state.map.fitBounds(this.map.current.state.drawn.getBounds());
    }

    startLoading() {
        var loadingMessages = [
            <React.Fragment><h2 className="white w-100 text-center pt-1">Hämtar laserdata...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Hämtar publik data...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Startar Algoritm...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar avdelningar...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar virkesvärden...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar trädslag...</h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Läser av trädhöjd...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar marken...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Bedömer risker för barkborre och brand...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">hämtar virkespriser...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Analyserar trädslag<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Beräknar risker...<p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></h2></React.Fragment>,
            <React.Fragment><h2 className="white w-100 text-center pt-1">Slutför, detta kan ta några minuter</h2><p className="w-100 white text-center">Du kan stänga denna sida så mejlar vi dig när din skogsbruksplan är klar!</p></React.Fragment>
        ]
        var i = 0
        var intervalID = setInterval(() => {
            this.setState({ loading_message: loadingMessages[i] })
            if (i + 1 <= loadingMessages.length - 1) {
                i += 1
            }
        }, 5000)
        this.setState({ loading: true, intervalID: intervalID });
    }

    stopLoading() {
        this.setState({ loading: false });
        clearInterval(this.state.intervalID);
    }

    deleteLayersProperty() {
        this.map.current.state.drawn.clearLayers();

        if (this.borderGeoJsonLayerGroup.getLayers().length !== 0) {
            this.map.current.state.map.removeLayer(this.borderGeoJsonLayerGroup)
            this.borderGeoJsonLayerGroup = L.layerGroup([],{interactive:false});
        }

        this.map.current.state.map.eachLayer((layer) => {
            if (layer instanceof L.Tooltip) {
                this.map.current.state.map.removeLayer(layer);
            }
        })
        this.map.current.state.map.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
                this.map.current.state.map.removeLayer(layer);
            }
        });
    }

    render() {
        let vector = [
            { "name": "Avverkningsanmälan", "layer": "Avverkningsanmalan_Skogsstyrelsen", "link": "http://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaAvverkningsanmalan/MapServer/WmsServer?" },
            { "name": "Utförd avverkning", "layer": "UtfordAvverkning_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaUtfordavverkning/MapServer/WmsServer?" },
            { 
                "name": "Nyckelbiotoper", 
                "layer": "Nyckelbiotop_Skogsstyrelsen", 
                "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaNyckelbiotop/MapServer/WmsServer?", 
                "contentGenerator": (content) => {
                  let show_info_keys = [{key:"Beteckn", label: "Beteckning"}, {key:"Biotop1", label:"Biotop 1"}, {key:"Biotop2", label: "Biotop 2"}, {key:"Biotop3", label:"Biotop 3"}, 
                  {key:"Beskrivn1", label:"Beskrivning 1"}, {key:"Beskrivn2", label: "Beskrivning 2"}, {key:"Beskrivn3", label: "Beskrivning 3"}, {key:"Url", label:"Länk"}
                ]
                  let elems = content.documentElement.querySelectorAll("FIELDS")
                  let output = []
                  elems.forEach((itm) => {
                    show_info_keys.forEach((key) => {
                      let val = itm.getAttribute(key.key)
                      output.push({label:key.label, value:val})
                    });
                  })
            
                  return output;
                }
              },
              { 
                "name": "Fornlämningar", 
                "layer": "fornlamning", 
                "link": "https://pub.raa.se/visning/lamningar_v1/wms",
                "contentGenerator": (content) => {
                  let show_info_keys = [{key:"lamningsnummer", label: "Lämningsnummer"}, {key:"raa_nummer", label:"RAA nummer"}, {key:"lamningstyp", label: "Lämningstyp"}, {key:"egenskap", label:"Beskrivning"}, 
                  {key:"senast_publicerad", label:"Senast publicerad"}, {key:"url", label: "Länk"}
                ]
                  let output = []
            
                  show_info_keys.forEach((key) => {
                    let elem = content.documentElement.getElementsByTagName("lamningar_v1:" + key.key)[0]
                    if(elem){
                      let val = elem.innerHTML.toString()
                      output.push({label: key.label, value: val})
                    }
                  });
            
                  return output;
                }
              },
            { "name": "Vattenskyddsområden", "layer": "PS.ProtectedSites.VSO", "link": "https://geodata.naturvardsverket.se/inspire/ps-nvr/ows" },
            { "name": "Object med naturvärde", "layer": "Objektnaturvarde_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaObjektnaturvarde/MapServer/WmsServer?" },
            { "name": "Sumpskog", "layer": "Sumpskog_Skogsstyrelsen", "link": "https://geodpags.skogsstyrelsen.se/arcgis/services/Geodataportal/GeodataportalVisaSumpskog/MapServer/WmsServer" },
            { "name": "Observationer invasiva arter", "layer": "SpeciesObservationsInvasive", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" },
            { "name": "Observationer rödlistade arter", "layer": "SpeciesObservationsRedlisted", "link": "https://sosgeo.artdata.slu.se/geoserver/SOS/wms" },
            {"name": "Avdelnings nr", "layer":"Avdelnings nr","link":""},
          ]

        let basem = [
            /*{name:"Mapbox Satelite", url:'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', config:{ attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>', maxZoom: 18, id: 'virkesborsen/cl3oe50md007w14mltp5laxhv', tileSize: 512, zoomOffset: -1, accessToken: 'pk.eyJ1Ijoidmlya2VzYm9yc2VuIiwiYSI6ImNsM29lMHJ6dDBsbm8zamxudWYzOW5pcWQifQ.e1McEtWBLaLRbDP7sBQ0Ww'}},*/
            { name: "Mapbox Plain", url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', config: { attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>', maxZoom: 18, id: 'virkesborsen/cl3oe50md007w14mltp5laxhv', tileSize: 512, zoomOffset: -1, accessToken: 'pk.eyJ1Ijoidmlya2VzYm9yc2VuIiwiYSI6ImNsM29lMHJ6dDBsbm8zamxudWYzOW5pcWQifQ.e1McEtWBLaLRbDP7sBQ0Ww' } },
            { name: "Satellit", url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', config: { attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>', maxZoom: 18, id: 'virkesborsen/cl9zfrqh0006514pld9wn4229', tileSize: 512, zoomOffset: -1, accessToken: 'pk.eyJ1Ijoidmlya2VzYm9yc2VuIiwiYSI6ImNsM29lMHJ6dDBsbm8zamxudWYzOW5pcWQifQ.e1McEtWBLaLRbDP7sBQ0Ww' } },
            /*{name: "Google Satelite", url:'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', config:{ maxZoom: 18, id: 'mapbox/streets-v11', tileSize: 512, zoomOffset: -1, crossOrigin: 'None' }},*/
            { name: "Open Street Map", url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', config: { maxZoom: 18, tileSize: 512, zoomOffset: -1 } },
            {name: "ESA Worldcover", "type":"wms", "url": "https://services.terrascope.be/wms/v2", config:{
                'layers': "WORLDCOVER_2021_S2_TCC",
                'transparent': true,
                'tiled': true,
                'f': 'pjson',
                'format': 'image/png',
                'identify': false,
                'zIndex': 1000
              }}
          ]

        if(Window.user_data.loggedInUserID == "56143"){
            vector.push({"name": "Arboair markörer", 
            "layer": "VBPostGis:arboair_test", 
            "link": "https://geoserv.virkesborsen.se/geoserver/wms", 
            "contentGenerator": (content) => {
                let show_info_keys = [{key:"height", label: "Höjd (dm)"}, {key:"carbon_storage", label:"Koldioxid"}, {key:"specie", label: "Art"}, {key:"stem_diameter", label:"Diameter"}, {key:"volume", label:"Volym"}]
              
                let output = []
          
                show_info_keys.forEach((key) => {
                  let elem = content.documentElement.getElementsByTagName("VBPostGis:" + key.key)[0]
                  if(elem){
                    let val = elem.innerHTML.toString()
                    output.push({label: key.label, value: val})
                  }
                });
          
                return output;
              }})

              basem.push({"name": "Arboair mosaic", "type":"wms", "url": "https://geoserv.virkesborsen.se/geoserver/wms", config:{
                'layers': "VBPostGis:mosaic",
                'transparent': true,
                'tiled': true,
                'f': 'pjson',
                'format': 'image/png',
                'identify': false,
                'zIndex': 1000
              }})
        }

        return <React.Fragment>
            <VBLoadingScreen
                show_loading={this.state.loading}
                disable_reload_timeout={true}
                loading_text={this.state.loading_message} />

            <MapContainer className="vh-100">
                <VBLeaflet
                    enableDrawControl={false}
                    wms_skogsstyrelsen_vector={vector}
                    basemap_urls={basem}
                    basemapName="Satellit"
                    ref={this.map}
                    enableLayers={true}
                    initalGeojson={this.state.initalGeojson}
                    onShapeClick={(e, all_drawn) => { this.handleShapeClick(e, all_drawn); }}
                    onShapeEdit={(e, all_drawn) => { this.handleShapeEdit(e, all_drawn); }}
                    onShapeCreate={(e, all_drawn) => { this.handleShapeCreate(e, all_drawn); }}
                    onShapeDelete={(e, all_drawn) => { this.handleShapeDelete(e, all_drawn); }}
                    onDrawStart={(e) => { this.props.onDrawStart(e); }}
                    openMarkerPlacementPopup={this.props.openMarkerPlacementPopup}
                    toggleToolbox={this.props.toggleToolbox}
                    toggleMapLayers={this.props.toggleMapLayers}
                    hideToolkitButton={this.props.hideToolkitButton}
                    />
            </MapContainer>
        </React.Fragment>;
    }
}

export default LeafletMapHandler;