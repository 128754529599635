import Offcanvas from 'react-bootstrap/Offcanvas';
import NHDivider from './nhDivider';

import React from 'react';
import NHEdit from './nhEdit';
import NHAdd from './nhAdd';
import { Modal } from "react-bootstrap";
import txt from './txt';

function escapeHtml(unsafe)
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

class NHForestSection extends React.Component{
    constructor(props){
        super(props)

        this.state={
            show_more: false,
            show_image_modal: false
        }
    }

    get_other_info_formatted(text){
        let output=""

        let in_list = false;
        let start_list = false;
        let close_list = false;
        text.split("\n").map((itm) => {
            let text = itm;
            let tag = '<p class="n-fs-6 mb-3">';
            let close_tag = "</p>"
            if(itm.startsWith("***")){
                text = text.slice(3, text.length)
                tag = '<p class="fw-medium n-fs-6 mb-0">'
                close_tag="</p>"
                start_list = true;
            }
            else if(itm.startsWith("**")){
                text = text.slice(2, text.length)
                tag = '<p class="fw-medium n-fs-6 mb-0">'
                close_tag = "</p>"
                close_list=true
            }

            if(start_list){
                output += tag + escapeHtml(text) + close_tag + '<ul class="n-fs-6">'
                in_list = true;
                start_list = false;
                close_list=false;
            }
            else if(in_list && close_list){
                output += "</ul>" + tag + escapeHtml(text) + close_tag;
                in_list = false;
                close_list = false;
            }
            else if(in_list){
                output += "<li>" + escapeHtml(text) + "</li>"
            } else{
                output += tag + escapeHtml(text) + close_tag
            }
            
        })
        if(in_list){
            output += "</ul>"
        }
        return output
    }

    getImages(){
        let modal = <Modal size="lg" centered show={this.state.show_image_modal} onHide={() => {this.setState({show_image_modal:false})}}>
        <Modal.Header closeButton>
          <Modal.Title>{txt({global:true, text:"Bilder"})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.data.images.map(img => <div className="position-relative">
            <img src={img.image} className="d-block w-100 mb-2 px-1" /> 
            {Window.react_data.is_editor ? <button className="position-absolute top-0 me-2 mt-1 end-0 n-btn btn-sm btn-error" onClick={() => {this.props.deleteImage(img.id)}}>Delete image</button> : null}
            </div>)}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>;
        if(this.props.data.images.length == 0){
            return;
        }
        else if(this.props.data.images.length == 1){
            return <div className="mb-2 position-relative">
                <img onClick={() => {this.setState({show_image_modal:true})}} style={{height: "184px", objectFit:"cover"}} className="w-100"  src={this.props.data.images[0].image} />
                {modal}
            </div>
        }
        return <div className="mb-2 position-relative d-flex flex-wrap justify-content-between">
            <img className="corners-1-all" style={{width:"calc(50% - 10px)", height: "184px", objectFit:"cover"}} src={this.props.data.images[0].image} />
            <img className="corners-1-all" style={{width:"calc(50% - 10px)", height: "184px", objectFit:"cover"}} src={this.props.data.images[1].image} />
            <button className="n-btn btn-sm btn-spruce position-absolute bottom-0 end-0 mb-2 me-2 py-2" onClick={()=>{this.setState({show_image_modal:true})}}>{txt({global:true, text:"Visa bilder"})}</button>
            {modal}
        </div>
    }

    render(){
        return <><div className="corners-1-all bg-neutral-cloud-300 p-3 mb-2" style={{cursor:"pointer"}} onClick={() => {this.setState({show_more:true})}}>
        <h4 className="n-fs-6 mb-1">{txt({location_id:`ProjectForestSection_name_${this.props.data.id}`, context_clue:"biodiversity project forest section name", text:this.props.data.name})}</h4>
        <p className={"n-fs-7 " + (this.props.data.credits || this.props.data.activity_class ? "" : "mb-0")}>{txt({location_id:`ProjectForestSection_description_${this.props.data.id}`, context_clue:"biodiversity project forest section description", text:this.props.data.description})}</p>
        {(this.props.data.credits || this.props.data.activity_class) && <NHDivider extra_class="mt-1 mb-2" />}
        <div className="row">
            {this.props.data.credits && <div className="col-3">
                <p className="fw-medium n-fs-5 mb-1">
                    {this.props.data.credits} {txt({location_id:`ProjectForestSection_credit_unit_${this.props.data.id}`, text:this.props.data.credit_unit})}
                </p>
                <p className="n-fs-6 mb-1">
                    {txt({global:true, location_id:"project_kredit_area", text:"Areal/Antal Krediter per år"})}
                </p>
            </div>}
            {this.props.data.activity_class && <div className="col-9">
                <p className="fw-medium n-fs-5 mb-1">
                {txt({location_id:`ProjectForestSection_activity_class_${this.props.data.id}`, context_clue:"project forest section biodiversity activity class", text:this.props.data.activity_class})}
                </p>
                <p className="n-fs-6 mb-1">
                {txt({global:true, location_id:`project_activity_class`, context_clue:"biodiversity project forest section acitivty class title", text:"Aktivitetsklass"})}
                </p>
            </div>}
        </div>
        </div>
        <Offcanvas placement="end" show={this.state.show_more} onHide={() => {this.setState({show_more:false})}}>
            <Offcanvas.Body className="bg-neutral-stone-300">
                <NHEdit className="position-absolute end-0 pe-3 mt-4" onClick={() => {this.props.edit()}} />
                <div className="d-flex justify-content-between">
                <h3 className="n-fs-5">{txt({location_id:`ProjectForestSection_name_${this.props.data.id}`, context_clue:"biodiversity project forest section name", text:this.props.data.name})}</h3>
                    <span style={{cursor:"pointer"}} onClick={() => {this.setState({show_more:false})}}>✖</span>
                </div>
                <p className="n-fs-6 neutral-charcoal-400">{txt({location_id:`ProjectForestSection_description_${this.props.data.id}`, context_clue:"biodiversity project forest section description", text:this.props.data.description})}</p>
                <NHDivider extra_class="my-3" />
                {this.getImages()}
                {Window.react_data.is_editor && <button className="n-btn btn-sm" onClick={() => {this.props.addImage()}}>Add image</button>}
                <div>
                    {this.props.data.credits && <>
                        <p className="fw-medium n-fs-6 mb-0">{txt({global:true, location_id:"project_kredit_area", text:"Areal/Antal Krediter per år"})}:</p>
                        <p className="n-fs-6 mb-3">{this.props.data.credits} {txt({location_id:`ProjectForestSection_credit_unit_${this.props.data.id}`, text:this.props.data.credit_unit})}</p>
                    </>}
                    {this.props.data.activity_class && <>
                    <p className="fw-medium n-fs-6 mb-0">{txt({global:true, location_id:`project_activity_class`, context_clue:"biodiversity project forest section acitivty class title", text:"Aktivitetsklass"})}</p>
                    <p className="n-fs-6 mb-3">{txt({location_id:`ProjectForestSection_activity_class_${this.props.data.id}`, context_clue:"project forest section biodiversity activity class", text:this.props.data.activity_class})}</p>
                    </>}

                    
                    {this.props.data.other_info_formatted && <div dangerouslySetInnerHTML={{__html:this.get_other_info_formatted(txt({location_id:`ProjectForestSection_formatted_desc_${this.props.data.id}`, context_clue:"Formatted description of biodiversity project section, keep all * ** and - signs", text:this.props.data.other_info_formatted}))}}></div>}

                    
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>
    }
}


export default NHForestSection