import React from 'react';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import { CalculateSpacing } from '../editor/Helpers';

class Avatar extends React.Component{
    constructor(props){
        super(props)
        this.state={


        }
    }

    render(){
        return <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} w-100 col-lg-6 flex-wrap flex-lg-nowrap ${CalculateSpacing(this.props.spacing)}`}>
            <div className='d-flex flex-wrap flex-lg-nowrap align-items-center'>
                {/* Avatar here */}
                {this.props.showAvatarImage && <img style={{ height: "64px", width:"64px", objectFit:"cover"}} alt={this.props.avatarAlt} src={`${this.props.avatarSrc}`} className={`img-fluid rounded-circle me-lg-3 ${this.props.hideAvatarOnlyMobile ? 'd-none d-md-flex' : ''}`} />}
                <div className='mt-2 ms-2 mt-lg-0'>
                    <p className={`mb-0 n-fs-6 fw-medium ${this.props.fontColor}`}>{this.props.name}</p>
                    <p className={`mb-0 n-fs-6 ${this.props.fontColor}`}>{this.props.company}</p>
                </div>
            </div>
            {this.props.showCompanyImage && (
              <>
              <div className={`mx-2 border-2 border-${this.props.borderColor} border-start d-lg-block`}></div>
                {/* Image here */}
              <div className={`mt-2 mt-lg-0 d-flex align-items-center`}>
                <img style={{ height: "32px", objectFit:"contain" }} alt={this.props.imageAlt} src={`${this.props.imageSrc}`} className='img-fluid' />
              </div>
            </>)}
        </div>
    }
}

Avatar.defaultProps = {
    visibility: "",
    showAvatarImage: false,
    showCompanyImage: true,
    hideAvatarOnlyMobile: false,
    avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      //image
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
      spacing: [],
}
  
Avatar.fields = {
    visibility: {
      type: "select",
      options: Opt.visibilityOptions
    },
    showAvatarImage:{
        type: "radio",
        options:[
            {label: "No", value: false},
            {label: "Yes", value: true}
        ]
    },
    showCompanyImage:{
      type: "radio",
      options:[
          {label: "No", value: false},
          {label: "Yes", value: true}
      ]
    },
    hideAvatarOnlyMobile: {
      type: "radio",
      options: [
          { label: "No", value: false },
          { label: "Yes", value: true }
      ]
    },
    avatarSrc: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
      },
      avatarAlt: {
        type: "text"
      },
      name: { type: "text" },
      nameColor: {
        type: "select",
        options: Opt.colorVariables
      },
      company: { type: "text" },
      companyColor: {
        type: "select",
        options: Opt.colorVariables
      },
      borderColor: {
        type: "select",
        options: Opt.colorVariables
      },
      imageSrc: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
      },
      imageAlt: {
        type: "text"
      },
      spacing: Opt.spacingOptions,
}

export default Avatar;

