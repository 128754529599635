import React from 'react';

import SBPNavigation from '../vb-django/components/SBP/SBPNavigation';
import ProfileAdListings from './ad-listings';

class ProfileBuyer extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (<div className="d-flex p-0 overflow-hidden">
            <SBPNavigation hideMapNav={!Window.user_data.roles.includes('seller')} />
            <div className="w-100 vh-100 overflow-auto">
                <div className="pt-3">
                    <ProfileAdListings 
                        disableNavFooter={true} 
                        ads={Window.react_data.ads} 
                        years={Window.react_data.years} 
                        child_accounts={Window.react_data.child_accounts} 
                    />
                </div>
            </div>
            
        </div>)
    }
}

export default ProfileBuyer;