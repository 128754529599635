import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import SaljaVirkeButton from "../SaljaVirkeButton";

const Hamburger = styled.div`
    z-index: ${props => props.behavior == 'leaflet' ? "1" : "1010"};
    left: 20px;
    top: ${props => props.behavior == 'leaflet' ? "102px" : "20px"};
    @media (min-width: 576px) {
        top: 20px;
    }
`

class SBPNavigation extends React.Component {
    constructor() {
        super();

        this.state = {
            show: false,
            ownedProps: Window.react_data?.ownedProps ? Window.react_data?.ownedProps : [],
            roles: Window.user_data.roles,
            isAdmin: Window.user_data.isAdmin,
        }
    }

    loadItems(device) {
        return (
            <div className="d-flex mt-2 flex-column justify-content-between py-lg-4">
                <div className='justify-content-start'>
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <a href="/">
                            <img src={`${Window.static_path}img/icons/treebula_lockup_charcoal.svg`} className="d-block supp-forest-green-filter mt-4 mt-lg-0 mb-4 ms-2" height="30" />
                        </a>
                        <img id={"sbpCloseNavImg"+device} src={`${Window.static_path}img/icons/cross.svg`} className="supp-forest-green-filter size-25 d-lg-none me-3" role="button" onClick={() => { this.setState({ show: false }) }} />
                    </div>
                    
                    <div className='justify-content-start mx-1 ms-lg-0 mt-3'>
                        {!this.props.hideMapNav && <div className='mb-4'>
                            <a href="/skogsbruksplan/karta/" className={`d-flex align-items-center ${window.location.pathname === '/skogsbruksplan/karta/' ? 'bg-supp-forest-green white' : 'supp-forest-green'} hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2`}>
                                <span className="d-flex align-items-center">
                                    <img src={`${Window.static_path}img/icons/karta.svg`} className={`size-25 img-fluid ${window.location.pathname === '/skogsbruksplan/karta/' ? 'white-filter' : 'supp-forest-green-filter'} `} />
                                    <span className="ms-2 n-fs-6">Karta</span>
                                </span>
                            </a>
                            <a id={"skogsoverblick"+device} href="/skogsbruksplan/overblick/" className={`d-flex align-items-center ${window.location.pathname === '/skogsbruksplan/overblick/' ? 'bg-supp-forest-green white' : 'supp-forest-green'} hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2`}>
                                <span className="d-flex align-items-center">
                                    <img src={`${Window.static_path}img/icons/overview.svg`} className={`size-25 img-fluid ${window.location.pathname === '/skogsbruksplan/overblick/' ? 'white-filter' : 'supp-forest-green-filter'} `} />
                                    <span className="ms-2 n-fs-6">Skogsöverblick</span>
                                </span>
                            </a>
                            <a id="risker" href="/skogsbruksplan/karta/risk/" className={`d-flex align-items-center ${window.location.pathname === '/skogsbruksplan/karta/risk/' ? 'bg-supp-forest-green white' : 'supp-forest-green'} hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2`}>
                                <span className="d-flex align-items-center">
                                    <img src={`${Window.static_path}img/icons/risks.svg`} className={`size-25 img-fluid ${window.location.pathname === '/skogsbruksplan/karta/risk/' ? 'white-filter' : 'supp-forest-green-filter'} `} style={{ width: '22px', height: '22px' }}/>
                                    <span className="ms-2 n-fs-6">Risker</span>
                                </span>
                            </a>
                        </div>}
                        
                        
                        <div className='mb-4'>
                            <p className='ms-2 mb-1 supp-forest-green n-fs-7'>Tjänster</p>

                            {!this.state.roles.includes('seller') ? 
                                <>
                                    <a href="/auctions/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                                        <img src={`${Window.static_path}img/icons/tree-money.svg`} className="supp-forest-green-filter size-25" />
                                        <span className="ms-2 pe-2 n-fs-6">Köp virke</span>
                                    </a>
                                    <a href="/mina-annonser/" className={`d-flex align-items-center ${window.location.pathname === '/mina-annonser/' ? 'bg-supp-forest-green white' : 'supp-forest-green'} hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2`}>
                                        <img src={`${Window.static_path}img/icons/item-list.svg`} className={`${window.location.pathname === '/mina-annonser/' ? 'white-filter' : 'supp-forest-green-filter'} size-25`} />
                                        <span className="ms-2 pe-2 n-fs-6">Mina annonser</span>
                                    </a>
                                </>
                            :   <>
                                    <a href="/mina-annonser/" className={`d-flex align-items-center ${window.location.pathname === '/mina-annonser/' ? 'bg-supp-forest-green white' : 'supp-forest-green'} hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2`}>
                                        <img src={`${Window.static_path}img/icons/item-list.svg`} className={`${window.location.pathname === '/mina-annonser/' ? 'white-filter' : 'supp-forest-green-filter'} size-25`} />
                                        <span className="ms-2 pe-2 n-fs-6">Alla mina annonser</span>
                                    </a>
                                    <a href="/salj-virke/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                                        <img src={`${Window.static_path}img/icons/tree-money.svg`} className="supp-forest-green-filter size-25" />
                                        <span className="ms-2 n-fs-6">Sälja virke</span>
                                    </a>

                                    <a href="/virkespriser/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                                        <img src={`${Window.static_path}img/icons/labels-trade.svg`} className="supp-forest-green-filter size-25" />
                                        <span className="ms-2 pe-2 n-fs-6">Jämför virkespriser</span>
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className='justify-content-end mx-1 ms-lg-0 mb-4 mb-lg-0'>
                    <a href="/my-account/edit/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                        <img src={`${Window.static_path}img/icons/cog.svg`} className="supp-forest-green-filter size-25" />
                        <span className="ms-2 n-fs-6">Inställningar</span>
                    </a>
                
                    {this.state.isAdmin?
                        <a href="/vb-admin/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                            <img src={`${Window.static_path}img/icons/User-line.svg`} className="supp-forest-green-filter" height="20px" width="25px" />
                            <span className="ms-2 n-fs-6">Admin</span>
                        </a>
                    :
                        null
                    }

                    <a href="/logout/" className="d-flex align-items-center supp-forest-green hover-bg-supp-forest-green-100 rounded mb-2 ps-2 py-2">
                        <img src={`${Window.static_path}img/icons/logout.svg`} className="supp-forest-green-filter size-25" />
                        <span className="ms-2 n-fs-6">Logga Ut</span>
                    </a>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="d-flex bg-neutral-cloud supp-forest-green vh-100">
                <div className="d-none d-lg-flex bg-neutral-cloud justify-content-center" style={{ width: "270px", zIndex: this.state.ownedProps.length === 0 ? 10000 : null }}>
                    {this.loadItems('desktop')}
                </div>

                <Offcanvas id="SBPNavigationContainer" className="d-lg-none bg-neutral-cloud py-2" style={{ minHeight: '100vh', width: "270px", zIndex: this.state.ownedProps.length === 0 ? 10010 : null }} show={this.state.show} onHide={() => { this.setState({ show: false }) }} placement='start' >
                    {this.loadItems('mobile')}
                </Offcanvas>

                <Hamburger behavior={this.props.hamburgerBehavior} className="d-lg-none position-absolute bg-neutral-stone rounded p-1" id="MobileSBPNavbarHamburger" role="button" style={{  zIndex: this.state.ownedProps.length === 0 ? 10000 : null }} onClick={() => { this.setState({ show: true }) }}>
                    <img src={`${Window.static_path}img/icons/hamburger.svg`} height="25px" width="25px" />
                </Hamburger>
                <style>
                    {`
                        a:hover img.primary-dark-filter {
                            filter: brightness(0) invert(1);
                        }
                    `}
                </style>
            </div>
        )
    }
}

export default SBPNavigation;