import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 20,
      posts:[]
    };
    this.handlePageChange = this.handlePageChange.bind(this); // Binding the method
  }

  componentDidMount() {
    this.fetchTotalPages();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.category != this.props.category || prevProps.pageSize != this.props.pageSize){
      this.fetchTotalPages();
    }
  }

  // fetch request here & page count
  fetchTotalPages =  () => {
    fetch(`/cms-api/posts/?pagenr=${this.state.currentPage}&category=${this.props.category.value}&pagesize=${this.props.pageSize}`)
    .then((resp) => resp.json())
    .then((json) => {
      this.setState({posts:json.posts, totalPages: json.page_count})
    })
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.fetchTotalPages();
    });
    //logic here: also browser history
    window.history.pushState({ page: pageNumber }, `Page ${pageNumber}`, `?page=${pageNumber}`);
  };

  render() {
    const siblingCount = 2;
    const pageNumbers = [];

    //calculate page number to display
    for(let i = Math.max(1, this.props.currentPage - siblingCount); 1 <= Math.min(this.state.totalPages, this.props.currentPage + siblingCount); i++) {
      pageNumbers.push(i);
    }
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} bg-${this.props.backgroundColor}`}>
        <div className='container'>
        <div className='row'>
          {this.state.posts.map((post) => {
            return <div className="col-lg-4 col-12 mb-3 position-relative">
            <a href={post.url}>
            <img src={post.image_url} className="w-100" style={{height:"300px", width:"416px", objectFit:"cover", borderTopLeftRadius: "24px", borderTopRightRadius: "24px"}} />
            <div className={`p-3 bg-${this.props.cardBackgroundColor}`} style={{borderBottomLeftRadius: "24px", borderBottomRightRadius: "24px", height:"250px"}}>
              <div className="w-100 mb-4">
                <span className={`rounded-pill mb-2 d-inline-block n-fs-8 bg-${this.props.categoryBackgroundColor} ${this.props.categoryFontColor}`} style={{padding:"2px 8px", lineHeight:"18px"}}>{post.category_name}</span>
                <h4 className={`fw-bold n-fs-5 ${this.props.fontColor}`}>{post.title}</h4>
                <p className={`n-fs-6 ${this.props.fontColor}`} style={{maxHeight:"64px", overflow:"hidden"}}>{post.description}</p>
              </div>
              <div className="d-flex align-items-center position-absolute bottom-0 pb-3">
                <img style={{width:"48px", height:"48px", objectFit:"cover"}} src={Window.static_path + (post.author_image ? post.author_image : "").substr(1)} className="rounded-circle" />
                <div className="ms-3">
                  <p className={`mb-0 n-fs-6 ${this.props.fontColor}`}>{post.author}</p>
                  <p className={`n-fs-7 mb-0 ${this.props.fontColor}`}>{post.published_date}</p>
                </div>
              </div>
            </div>
            </a>
          </div>;
          })}
        </div>
      <nav aria-label="Page navigation" className={`${this.props.showPagination === "yes" ? 'mt-3' : 'd-none'}`}>
        <ul className="pagination justify-content-center m-0 p-3">
          <li className={`page-item ${this.state.currentPage === 1 ? 'disabled' : ''}`}>
            <a className={`page-link ${this.state.currentPage === 1 ? '' : 'supp-forest-green'}`} style={{cursor:"pointer"}} tabIndex="-1" aria-disabled={this.state.currentPage === 1} onClick={() => this.handlePageChange(this.state.currentPage - 1)}>Föregående</a>
          </li>
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${pageNumber === this.state.currentPage ? 'active' : ''}`}>
              <a className="page-link " style={{cursor:"pointer"}} href={`?page=${pageNumber}`} onClick={() => this.handlePageChange(pageNumber)}>{pageNumber}</a>
            </li>
          ))}
          <li className={`page-item ${this.state.currentPage === this.state.totalPages ? 'disabled' : ''}`}>
            <a className={`page-link ${this.state.currentPage === this.state.totalPages ? '' : 'supp-forest-green'}`} style={{cursor:"pointer"}} onClick={() => this.handlePageChange(this.state.currentPage + 1)}>Nästa</a>
          </li>
        </ul>
      </nav>
      </div>
      </div>
    );
  }
}

Posts.defaultProps = {
  visibility: "",
  category: 1,
  pageSize: 3,
  backgroundColor: 'white-500',
  cardBackgroundColor: 'neutral-cloud-500',
  categoryBackgroundColor: 'supp-forest-green-500',
  categoryFontColor: 'white',
  fontColor: "neutral-charcoal",
  showPagination: "yes",
}

Posts.fields = {
  category: {type: "external",
    fetchList: async () => {
      // ... fetch data from a third party API, or other async source
      const resp = await fetch("/admin-api/category/");
      const resp_json = await resp.json();
      return resp_json.map((cat) => {return {"label": cat.name, "value": cat.id}});
    },
  },
  pageSize: {
    type: "number",
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  cardBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryFontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  fontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  showPagination: {
    type: "select",
    options: Opt.yesNo
  }
}

export default Posts;
