import React from 'react';
import styled from "styled-components";
import Cookies from 'js-cookie';
import Offcanvas from 'react-bootstrap/Offcanvas';

import UpdateMap from './handlers/UpdateMap';
import PropSelector from './PropSelector';
import PropModal from './handlers/PropModal';
import LeafletMapHandler from './handlers/LeafletMapHandler';
import HelpRisk from './handlers/help/HelpRisk';

const SectionPopupDesktop = styled(Offcanvas)`
    transition: all ease 300ms;
    left: 0px !important;

    &.show {
        left: 270px !important;
    }
`

const SectionPopupMobile = styled(Offcanvas)`
    height: 100% !important;
    max-width: 500px !important;
    
    @media (min-width: 500px) {
        left: 50% !important;
        margin-left: -250px !important;
    }
`

const CustomScrollbar = styled.div`
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #023436;
        border-radius: 10px;
    }
`


const HelpButton = styled.button`
    z-index: 1000;
    width: 80px;
    height: 40px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid;
    border-color: #D3D3D3;
    padding-left: 5px;
    position: absolute;
    top: 250px;
    left: auto;
    right: 15px;
  
    @media (min-width: 768px) {
        top: 150px;
        left: auto;
        right: 15px;
    }
  
    @media (min-width: 1024px) {
        top: 150px;
        left: auto;
        right: 15px;
    }
`;

const RiskFilter = styled.div`
    z-index: 1000;
    border-radius: 8px;
    border: 1px solid;
    border-color: #D3D3D3;
    position: absolute;
    top: 50%;
    left: auto;
    right: 15px;

    @media (min-width: 768px) {
        top: 210px;
        left: auto;
        right: 15px;
    }

    @media (min-width: 1024px) {
        top: 210px;
        left: auto;
        right: 15px;
    }
`;

class Risker extends React.Component {
    constructor(props) {
        super(props);
        this.riskImages = [
            { "src": Window.static_path + "img/map-markers/treebug-white.svg", "name": "Bug" },
            { "src": Window.static_path + "img/map-markers/onfire-white.svg", "name": "Fire" },
        ]

        this.state = {
            activeProps: [],
            sectionPopup: false,
            currentSectionData: [],
            currentlySelectedProp: {},
            currentlySelectedSect: {},
            clickedSectionCount: 0,
            ownedProps: Window.react_data.ownedProps,

            // Modals
            propModalVisible: (Window.react_data.ownedProps == 0),
            selectedRisk: this.riskImages[0] || null
        }

        this.mapHandler = React.createRef();
        this.updateMap = React.createRef();
    }


    //set pane either on left or bottom
    setSlidingPane(data) {
        this.updateMap.current.updateData(data)

        this.setState({
            sectionPopup: true,
            currentlySelectedSect: data,
            getRiskSectionsData: this.formatSectionRisks(data.risks)
        })
    }

    formatSectionRisks(data) {
        return <div>
            {data.map((itm) => {
                let icon = itm.type == "Brand Risk" ? "img/map-markers/marker_fire_white.png" : "img/map-markers/marker_bug_white.png"
                return (<div className="d-flex align-items-center py-2">
                    <div className="col-1"><img src={Window.static_path + icon} width="26px" height="26px" /></div>
                    <div className="col-6 px-2">{itm.type}</div>
                    <div className="col-5">{itm.severity}</div>
                </div>)
            })}
        </div>
    }

    populateSectiondata() {
        // Open section in risk
        return <CustomScrollbar className="ms-4 me-2 pe-2">
            <div className='supp-forest-green bg-neutral-cloud subtitle rounded my-3 p-3'  id='risk-filter'>
                <p className='fw-medium'>RiskFilter</p>
                <p>Vänligen välj en:</p>
                {this.riskImages.map((risk, index) => (
                    <div key={index}
                        className={this.state.selectedRisk && this.state.selectedRisk.name === risk.name ? 'bg-neutral-charcoal' : 'bg-neutral-cloud-700 hover-bg-neutral-charcoal'}
                        style={{
                            display: 'inline-block',
                            margin: '5px',
                            borderRadius: '5px',
                            padding: '5px',
                            backgroundColor:'bg-neutral-cloud-700',
                        }}
                        onClick={() => {
                            this.setState({ selectedRisk: risk },()=>{
                                this.reloadGeoJson()
                            })
                        }}>
                        <img
                            src={risk.src}
                            style={{
                                margin: '5px',
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                ))}

            </div> 
            <div className='supp-forest-green bg-neutral-cloud subtitle rounded my-3 p-3'>
                {this.state.selectedRisk && this.state.selectedRisk.name === "Fire" ?
                    <div>
                        <p className='fw-medium'>Brand Risk</p>
                        <p className='mb-0'>I Sverige utgör skogsbrandsrisken en betydande utmaning, särskilt under torra och varma perioder. Klimatförändringar ökar frekvensen av skogsbränder. Torra barr och mänsklig påverkan kan öka risken. Skogsbränder kan leda till omfattande förluster av skog, påverka biologisk mångfald och ha ekonomiska konsekvenser. Förebyggande åtgärder och samarbete mellan myndigheter och samhället är avgörande för att minimera risken och hantera konsekvenserna.
                        </p>
                    </div> :
                    <div>
                        <p className='fw-medium'> Granbarkborre Risk </p>
                        <p className='mb-0'>Granbarkborren (Ips typographus) utgör en allvarlig risk för granar och andra barrträd. Dess närvaro kan leda till omfattande skador, inklusive gulnande barr, barrfall och i värsta fall träddöd. Larverna gräver gångar under barken, stör trädens vaskulära system och ökar deras sårbarhet. Angreppet kan sprida sig snabbt och har betydande ekonomiska konsekvenser för skogsbruket. Förebyggande åtgärder, tidig upptäckt och samarbete mellan skogsägare och myndigheter är avgörande för att begränsa skadorna och bevara skogsbeståndet.</p>
                    </div>}
            </div>
    
        </CustomScrollbar>
    }

    updatePropData(data) {
        let props = this.state.activeProps
        if(data.lmID) {
            props.find(p => p.info.value == data.lmID).json = data
        } else if(data.dbID) {
            props.find(p => p.info.value == "FBA"+data.dbID).json = data
        }
        
        this.setState({ activeProps: props }, () => this.reloadGeoJson())
    }

    reloadGeoJson() {
        this.mapHandler.current.clearMap(isCleared => {
            if (isCleared) {
                this.state.activeProps.map(prop => {
                    setTimeout(() => {
                        this.mapHandler.current.setMapGeojson(prop)
                    }, 200);
                    //this.mapHandler.current.onFinish(prop)
                })
                this.mapHandler.current.setState({ loading: false });
            }
        })
    }

    handleHelpPopup() {
        const helpCookieName = `help_risk_${Window.user_data.loggedInUserID}`;
        const helpCookie = Cookies.get(helpCookieName);

        // If there is no cookie for this user ID then show modal
        if (!helpCookie) {
            let expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 60);
            // Set cookie with the current user ID
            Cookies.set(helpCookieName, 'completed', { expires: expirationDate });
            this.setState({ helpModalsVisible: true });
        }
    }

    propertyFinish(propsData) {
        this.setState({ activeProps: propsData }, () => {
            this.mapHandler.current.clearMap(isCleared => {
                if(isCleared) {
                    Promise.all(
                        propsData.map(prop => 
                            setTimeout(() => {
                                this.mapHandler.current.onFinish(prop)
                            }, 200)
                        )
                    ).then(() => {
                        this.mapHandler.current.stopLoading()
                        this.handleHelpPopup()
                    })
                }
            })
        })
    }

    render() {
        return <>
                <div className="w-100 h-100 position-relative bg-neutral-cloud" style={{zIndex: 0 }}>
                    <LeafletMapHandler
                        ref={this.mapHandler}
                        onSectionClick={data => {
                            if (this.mapHandler.current.map.current.state.editLayersMode) {
                                this.mapHandler.current.map.current.enableEditLayers()
                            } else {
                                this.setSlidingPane(data);
                                this.setState({ clickedSectionCount: this.state.clickedSectionCount + 1 });
                            }
                        }}
                        selectedRisk={(this.state.selectedRisk)}
                        hideToolkitButton={true}
                        {...this.props} />

                    <UpdateMap
                        ref={this.updateMap}
                        currentSectionData={this.state.currentlySelectedSect}
                        onSave={(data) => { this.updatePropData(data); this.updateMap.current.showPopupFunc(false) }} />

                    <div className="container-fluid d-flex position-absolute justify-content-center top-0 left-0 pt-3">
                        <PropSelector
                            onDivideStart={() => {this.mapHandler.current.startLoading();this.setState({sectionPopup:false})}}
                            onDivideFinish={data => this.propertyFinish(data)}
                            deleteProps={deletedProp => {
                                //If the selected property opened on the map matches the one to be deleted, clear the layer.
                                if (this.state.activeProps.find(prop => { prop.info.value === deletedProp.value })) {
                                    this.mapHandler.current.deleteLayersProperty();
                                }
                            }}
                            activeProps={this.state.activeProps}
                            ownedProps={this.state.ownedProps} />
                    </div>
                    <HelpButton className='position-fixed d-flex align-items-center' onClick={() => this.setState({ helpModalsVisible: true })}>
                        <img src={Window.static_path + "img/icons/BsInfoLg.svg"} className="neutral-charcoal-dark-filter" style={{ width:'18px', height:'18px' }}></img>
                        <span className="neutral-charcoal lh-1 ms-1" style={{fontSize:'13px'}}>Hjälp</span>
                    </HelpButton>
                    <RiskFilter id="risk-filter-button" className='position-fixed d-flex align-items-center bg-neutral-cloud'>
                    {this.riskImages.map((risk, index) => (
                        <div key={index}
                            className={this.state.selectedRisk && this.state.selectedRisk.name === risk.name ? 'bg-neutral-charcoal' : 'bg-neutral-cloud-700 hover-bg-neutral-charcoal'}
                            style={{
                                display: 'inline-block',
                                margin: '5px',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor:'bg-neutral-cloud-700',
                            }}
                            onClick={() => {
                                this.setState({ selectedRisk: risk },()=>{
                                    this.reloadGeoJson()
                                })
                            }}>
                            <img
                                src={risk.src}
                                style={{
                                    margin: '5px',
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                    ))}

                    </RiskFilter>

                </div>

                <SectionPopupDesktop className="d-none d-lg-block py-2 bg-neutral-cloud-100" show={this.state.sectionPopup} onHide={() => { this.setState({ sectionPopup: false }) }} placement='start' backdrop={false}>
                    <div className="row subtitle g-0 px-4 py-2 my-3 bg-neutral-cloud hover-bg-neutral-cloud-700" role="button" id="closeSectionDetailsContainer" onClick={() => this.setState({ sectionPopup: false })}>
                        <div className="col-12 d-flex justify-content-between align-items-center supp-forest-green">
                            <span className="fw-medium">Fastighet</span>
                            <span className="ms-2 supp-forest-green">{this.state.currentlySelectedSect.property_name}</span>
                            <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="30" height="30" />
                        </div>
                    </div>

                    {this.populateSectiondata()}
                </SectionPopupDesktop>

                <SectionPopupMobile className="d-block d-lg-none py-2 bg-neutral-cloud-100" show={this.state.sectionPopup} onHide={() => { this.setState({ sectionPopup: false }) }} placement='bottom' backdrop={false}>
                    <div className="row subtitle g-0 mx-4 my-2 align-items-center">
                        <div className="col-12 d-flex justify-content-start align-items-center py-2" role="button" id="closeSectionDetailsContainer" onClick={() => this.setState({ sectionPopup: false })}>
                            <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="35" height="35" />
                            <p className="fw-medium mb-0">Fastighet</p>
                        </div>
                        
                        <div className="col-12 supp-forest-green">{this.state.currentlySelectedProp.label}</div>
                    </div>

                    {this.populateSectiondata()}
                </SectionPopupMobile>

                
                {/*NEW USER OR USER WITHOUT PROPERTY POPUP */}
                <PropModal
                    visible={this.state.propModalVisible}
                    onHide={() => { this.setState({ propModalVisible: false }) }}
                    onDivideStart={() => this.mapHandler.current.startLoading()}
                    onDivideFinish={data => this.propertyFinish(data)}
                    updateOwnedProps={arr => this.setState({ownedProps: arr})} />

                {this.state.helpModalsVisible && <HelpRisk onClose={() => this.setState({ helpModalsVisible: false })} />}
                
        </>;
    }
}


export default Risker;