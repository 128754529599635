import React from 'react';
import VBLeaflet from "@virkesborsen/vb-js-commons/lib/components/vbLeaflet";
import {isMobile, isDesktop} from 'react-device-detect';
import txt from './txt';

class NHLeaflet extends React.Component {
    constructor(props) {
        super(props);

        this.map = React.createRef();

        this.state={
        }
    }

    componentDidMount(){
        if(this.props.geojson){
            const timeout = setTimeout(() => {
                if(this.map.current.state.map){
                    this.addGeojsonToMap(this.props.geojson, "#FFFFFF", "transparent", false)
                    this.props.sections.map((sect) => {
                        let layr = this.addGeojsonToMap(sect.geom, sect.geom_color ? sect.geom_color : "#03A086");

                        layr.on("click", () => {this.props.handleSectionClick(sect.id)})
                    })
                    //this.map.current.setLegendData(this.props.sections.map((sect) => {return {"color": sect.geom_color ? sect.geom_color : "#03A086", "label": sect.name}}), "", []);
                    
                    clearTimeout(timeout);
                }
            }, 50)
        }
    }

    addGeojsonToMap(geojson, color, fillColor, addToDrawn=true) {
        let layr = L.geoJSON(geojson, {
            interactive: true,
            style: (feature) => {
                let style = {
                    color: color,
                }
                if(fillColor){
                    style.fillColor = fillColor;
                }
                return style;
            },
            onEachFeature: (feature, layer) => {
                if(addToDrawn){
                    layer.addTo(this.map.current.state.drawn)
                    layer.bringToFront()

                } else{
                    layer.addTo(this.map.current.state.map)
                }
            },
        })
        if(this.map.current.state.drawn.getBounds().isValid()){
            this.map.current.state.map.fitBounds(this.map.current.state.drawn.getBounds())
        }

        return layr
        
    }

    render() {
        return (<div className="position-relative w-100 h-100">
                <VBLeaflet
                    mapid="main"
                    enableDrawControl={{
                        circle: false,
                        rectangle: false,
                        polyline: false,
                        edit: false,
                        circlemarker: false,
                        marker: false,
                    }}
                    mapOptions={{gestureHandling: isMobile}}
                    style={{}}
                    basemapName="Satellit"
                    ref={this.map}
                    enableLayers={true}
                    hideToolkitButton={true}
                    hideLeafletLayerButton={false}
                    onClick={this.handleMapClick}
                    onShapeCreate={(e, all_drawn) => { }}
                    onShapeClick={(e, all_drawn) => { }}
                    onDrawStart={(e) => { }}
                    
                    />
                    {this.props.sections.length > 0 ? <div className="bg-white no-scroll-bar position-absolute bottom-0 end-0 mb-5 me-2 p-2 corners-1-all" style={{zIndex:400, maxHeight:"150px", overflowY:"auto"}}>
                        {this.props.sections.map((sect) => {
                        return <div className="d-flex align-items-center justify-content-start n-fs-7 my-1">
                            <div className="me-1 corners-1-all" style={{width:"16px", height:"16px", background:sect.geom_color ? sect.geom_color : "#03A086"}}></div>
                            {txt({location_id:`ProjectForestSection_name_${sect.id}`, context_clue:"biodiversity project forest section name", text:sect.name})}
                        </div>
                        })}
                    </div>:null}
                    </div>
        )
    }
}
export default NHLeaflet;