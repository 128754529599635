import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class PostsVertical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalPages: 20,
      posts:[]
    };
    this.handlePageChange = this.handlePageChange.bind(this); // Binding the method
  }

  componentDidMount() {
    this.fetchTotalPages();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.category != this.props.category || prevProps.pageSize != this.props.pageSize){
      this.fetchTotalPages();
    }
  }

  // fetch request here & page count
  fetchTotalPages =  () => {
    fetch(`/cms-api/posts/?pagenr=${this.state.currentPage}&category=${this.props.category.value}&pagesize=${this.props.pageSize}`)
    .then((resp) => resp.json())
    .then((json) => {
      this.setState({posts:json.posts, totalPages: json.page_count})
    })
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.fetchTotalPages();
    });
    //logic here: also browser history
    window.history.pushState({ page: pageNumber }, `Page ${pageNumber}`, `?page=${pageNumber}`);
  };

  render() {
    const siblingCount = 2;
    const pageNumbers = [];

    //calculate page number to display
    for(let i = Math.max(1, this.props.currentPage - siblingCount); 1 <= Math.min(this.state.totalPages, this.props.currentPage + siblingCount); i++) {
      pageNumbers.push(i);
    }
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
        <section className={`container`}>
        
          {this.state.posts.map((post) => {
            return <div className='d-flex w-100 vb-rounded rounded-all overflow-hidden mb-3' style={{height:"205px"}}>
                <a href={post.url} className="d-flex w-100">
                <img src={post.image_url} style={{height:"205px", width:"40%", objectFit:"cover"}} className='d-none d-md-flex' />
                <div className={`p-3 bg-${this.props.cardBackgroundColor}`} style={{background: "#EFEFEF", width:(screen.width >= 768 ? "60%" : "100%")}}>
                  <div className="w-100 mb-4 d-flex flex-wrap h-100 align-content-start position-relative pb-4">
                    <span className={`rounded-pill mb-2 d-inline-block n-fs-8 bg-${this.props.categoryBackgroundColor} ${this.props.categoryFontColor}`} style={{padding:"2px 8px", lineHeight:"18px"}}>{post.category_name}</span>
                    <h4 className={`fw-medium n-fs-6 w-100 mb-1 ${this.props.fontColor}`} style={{fontSize:"20px"}}>{post.title}</h4>
                    <p className={`n-fs-7 w-100 overflow-hidden ${this.props.fontColor}`} style={{maxHeight:"64px"}}>{post.description}</p>
                    <p className={`n-fs-7 mb-0 w-100 bottom-0 position-absolute ${this.props.fontColor}`}>{post.published_date}</p>
                  </div>
                </div>
                </a>
            </div>;
          })}
      <nav aria-label="Page navigation" className="mt-3">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${this.state.currentPage === 1 ? 'disabled' : ''}`}>
            <a className={`page-link ${this.state.currentPage === 1 ? '' : 'supp-forest-green'}`} style={{cursor:"pointer"}} tabIndex="-1" aria-disabled={this.state.currentPage === 1} onClick={() => this.handlePageChange(this.state.currentPage - 1)}>Föregående</a>
          </li>
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${pageNumber === this.state.currentPage ? 'active' : ''}`}>
              <a className="page-link " style={{cursor:"pointer"}} href={`?page=${pageNumber}`} onClick={() => this.handlePageChange(pageNumber)}>{pageNumber}</a>
            </li>
          ))}
          <li className={`page-item ${this.state.currentPage === this.state.totalPages ? 'disabled' : ''}`}>
            <a className={`page-link ${this.state.currentPage === this.state.totalPages ? '' : 'supp-forest-green'}`} style={{cursor:"pointer"}} onClick={() => this.handlePageChange(this.state.currentPage + 1)}>Nästa</a>
          </li>
        </ul>
      </nav>
      </section>
      </div>
    );
  }
}

PostsVertical.defaultProps = {
  visibility: "",
  category: 1,
  pageSize: 6,
  backgroundColor: 'white-500',
  cardBackgroundColor: 'neutral-cloud-500',
  categoryBackgroundColor: 'supp-forest-green-500',
  categoryFontColor: 'white',
  fontColor: 'neutral-charcoal'
}

PostsVertical.fields = {
  category: {type: "external",
    fetchList: async () => {
      // ... fetch data from a third party API, or other async source
      const resp = await fetch("/admin-api/category/");
      const resp_json = await resp.json();
      return resp_json.map((cat) => {return {"label": cat.name, "value": cat.id}});
    },
  },
  pageSize: {
    type: "number",
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  cardBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryBackgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  categoryFontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  fontColor: {
    type: "select",
    options: Opt.colorVariables
  },
}

export default PostsVertical;
