import React from 'react';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import ColorSelector from '../editor/fields/ColorSelector';

class ContactCard extends React.Component {
  constructor(props) {
    super(props);


    this.state = {

    };


  }


  render() {

    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} container px-0 overflow-hidden bg-${this.props.containerBackground}`} style={{borderRadius: "32px", maxWidth: "386px"}}>
        <img className="w-100 mb-3" style={{objectFit:"cover", borderBottomRightRadius:"150px", height:"300px"}} src={`${this.props.src}`} />
        <div className="mx-3 pb-3">
          <div className={`w-100 bg-${this.props.boxBackground} mb-3 p-3`} style={{borderRadius: "32px"}}>
            <div className="mb-3">
              <p className={`mb-0 n-fs-7 ${this.props.fontColor}`}>{this.props.person.title}</p>
              <h4 className={`mb-0 n-fs-5 ${this.props.fontColor}`}>{this.props.person.name}</h4>
            </div>
            
            <div>
              {this.props.person.phonenumber && <p className={`${this.props.fontColor} n-fs-7 mb-0 n-link`}><a href={`tel:${this.props.person.phonenumber}`}>{this.props.person.phonenumber}</a></p>}
              {this.props.person.email && <p className={`${this.props.fontColor} n-fs-7 mb-0 n-link`}><a href={`mailto:${this.props.person.email}`}>{this.props.person.email}</a></p>}
              {this.props.person?.link?.link && <p className={`${this.props.fontColor} n-fs-7 mb-0 n-link`}><a href={`${this.props.person.link.link}`}>{this.props.person.link.text}</a></p>}
            </div>
          </div>
          <div className={`w-100 d-flex flex-wrap justify-content-between bg-${this.props.boxBackground} rounded-5 p-md-1 p-3`} style={{borderRadius: "32px"}}>
            <a className={`n-btn ${this.props.leftButton.buttonColor} btn-xs ${this.props.leftButton.buttonStyle} flex-grow-1 m-1`} href={this.props.leftButton.link}><span>{this.props.leftButton.iconBefore && <img className={`me-2 ${this.props.leftButton.iconColor}`} style={{ width: "18px", height: "18px" }} src={this.props.leftButton.iconBefore} />}</span>{this.props.leftButton.text}<span>{this.props.leftButton.iconAfter && <img className={`ms-2 ${this.props.leftButton.iconColor}`} style={{ width: "18px", height: "18px" }} src={this.props.leftButton.iconAfter} />}</span></a>
            <a className={`n-btn ${this.props.rightButton.buttonColor} btn-xs ${this.props.rightButton.buttonStyle} flex-grow-1 m-1`} href={this.props.rightButton.link}><span>{this.props.rightButton.iconBefore && <img className={`me-2 ${this.props.rightButton.iconColor}`} style={{ width: "18px", height: "18px" }} src={this.props.rightButton.iconBefore} />}</span>{this.props.rightButton.text}<span>{this.props.rightButton.iconAfter && <img className={`ms-2 ${this.props.rightButton.iconColor}`} style={{ width: "18px", height: "18px" }} src={this.props.rightButton.iconAfter} />}</span></a>
          </div>
        </div>
      </div>
    )
  }
}

ContactCard.defaultProps = {
  visibility: "",
  src: "https://place-hold.it/400x400",
  person:{
    title: "Storchefen",
    name:"Namn Namnsson",
    phonenumber: "070-000 00 00",
    email: "namn.namnsson@virkesborsen.se",
    link:{
      text: "",
      link: ""
    }
  },
  containerBackground: "neutral-stone-500",
  boxBackground: "white-500",
  fontColor: "neutral-charcoal",
  leftButton: {
    text: "Kontakta mig",
    link: "https://treebula.se/",
    buttonStyle: "",
    buttonColor: "btn-spruce",
    iconColor: "white-filter",
    iconBefore: null,
    iconAfter: null
  },
  rightButton: {
    text: "Boka en genomgång",
    link: "https://treebula.se/",
    buttonStyle: "btn-outline",
    buttonColor: "btn-spruce",
    iconColor: "white-filter",
    iconBefore: null,
    iconAfter: null
  },
  imageContain: "cover"
}

ContactCard.fields = {
  src: {
    type: "custom",
    render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  person:{
    type: "object",
    objectFields: {
      title: { type: "text" },
      name: { type: "text" },
      phonenumber: { type: "text" },
      email: { type: "text" },
      link:{
        type:"object",
        objectFields: {
          text: { type: "text" },
          link: { type: "text" }
        }
      }
    },
  },
  containerBackground: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  boxBackground: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  fontColor: {
    type: "select",
    options: Opt.colorVariables
  },
  leftButton:{
    type: "object",
    objectFields: {
      text: { type: "text" },
      link: { type: "text"},
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      },
      buttonColor: {
        type: "select",
        options: Opt.buttonOptions
      },
      iconBefore: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconBefore'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconAfter: {
          type: "custom",
          render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconColor: {
        type: "select",
        options: Opt.filterOptions
    },
    },
  },
  rightButton:{
    type: "object",
    objectFields: {
      text: { type: "text" },
      link: { type: "text"},
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      },
      buttonColor: {
        type: "select",
        options: Opt.buttonOptions
      },
      iconBefore: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'iconBefore'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconAfter: {
          type: "custom",
          render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
      },
      iconColor: {
        type: "select",
        options: Opt.filterOptions
    },
    },
  },
  imageContain: {
    type:"select",
    options: [
        {
          label: "cover",
          value: "cover"
        },
        {
          label: "contain",
          value: "contain"
        }
  ]
  }
}

export default ContactCard;