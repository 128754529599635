import React from 'react';
import { CalculateSpacing } from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import * as Opt from '../editor/Helpers'
import RadiusEditor from '../editor/fields/RadiusEditor';

class Icon extends React.Component {
    constructor() {
        super();
    }

    render() {
        return <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} w-100 ${this.props.horizontalAlignContent}`}>
            <div className={`bg-${this.props.imageBgColor} ${(Array.isArray(this.props.borderRadius)?this.props.borderRadius:[]).reduce((acum, itm) => acum+itm.radius+" ", "")} d-flex align-content-center justify-content-center ${CalculateSpacing(this.props.spacing)}`}>
                <img style={{ width: this.props.iconSize, height: this.props.iconSize }} alt={this.props.alt} src={`${this.props.src}`} className={`${this.props.iconColor} ${this.props.borderRadius}`} />
            </div>
        </div>
    }
}

Icon.defaultProps = {
    visibility: "",
    src: "https://placehold.co/1",
    iconSize:"64px",
    alt: "icon",
    horizontalAlignContent: "justify-content-between",
    spacing: [],
    iconColor: "neutral-charcoal-filter",
    borderRadius: ["corners-2-all",],
    imageBgColor: "neutral-stone"
}

Icon.fields={
    src: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'Icon'} type={'icon'} onChange={onChange} value={value} />,
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    iconSize: {
        type: "select",
        options: Opt.iconSizeOptions
      },
    alt: {
        type:"text"
    },
    horizontalAlignContent: {
        type: "select",
        options: Opt.contentJustify
    },
    imageBgColor: {
        type: "select",
        options: Opt.colorVariables
    },
    spacing: Opt.spacingOptions,
    iconColor:{
        type: "select",
        options: Opt.filterOptions
    },
    borderRadius: {
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    }
}

export default Icon;