import React from 'react';
import styled from "styled-components";

import Footer from "../components/Footer";

import KnowYourCustomerForm from '../forms/KnowYourCustomerForm';

const ContentContainer = styled.div`
    background-color: white;

    @media (min-width: 992px) {
        background: linear-gradient(90deg, #fff, #fff 50%,#192E27 50% , #192E27);
    }
`;

class KnowYourCustomerPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (<div className="container-fluid p-0">
            <ContentContainer>
                <div className="container">
                    <div className="row align-items-center py-4">
                        <div className="col-3">
                            <a href="/">
                                <img src={`${Window.static_path}img/virkesborsen/icon.svg`} className="d-lg-none" style={{ maxHeight: "70px"}} />
                                <img src={`${Window.static_path}img/virkesborsen/logo-text-closer.svg`} className="d-none d-lg-block" style={{ marginBottom: "10px" }} />
                            </a>
                        </div>
                        <div className="col-9">
                            <a href="/om-oss/kontakt/" className="d-lg-none btn btn-primary btn-sm float-end">KONTAKTA OSS</a>
                            <a href="/om-oss/kontakt/" className="d-none d-lg-block btn btn-secondary btn-sm float-end">KONTAKTA OSS</a>
                        </div>
                    </div>

                    <div className="row align-items-center py-5">
                        <div className="col-12 col-lg-6 px-lg-5">
                            <h1>VI VILL LÄRA KÄNNA DIG BÄTTRE</h1>

                            <KnowYourCustomerForm />
                        </div>

                        <div className="d-none d-lg-block col-6">
                            <img src={`${Window.static_path}img/illustration/log.png`} className="img-fluid opacity-6 p-4" style={{mixBlendMode: 'multiply'}} />
                        </div>
                    </div>
                </div>
            </ContentContainer>

            <Footer />
        </div>)
    }
}

export default KnowYourCustomerPage;