import React, { Fragment } from 'react';

class Footer extends React.Component {
    constructor() {
      super();
    }

    loadSocialMedia() {
        return(
            <Fragment>
                <a href="https://www.instagram.com/virkesborsen/" className="me-3">
                    <img src={`${Window.static_path}img/social-media/Instagram.svg`} className="max-h-50 primary-dark-filter" />
                </a>

                <a href="https://www.facebook.com/Virkesborsen/" className="me-3">
                    <img src={`${Window.static_path}img/social-media/Facebook.svg`} className="max-h-50 primary-dark-filter" />
                </a>
            </Fragment>
        )
    }

    render() {
        return (
            <section className="bg-secondary-light">
                <footer className="container">
                    <div className="row py-2 py-md-5">
                        <div className="col-12 col-md-2 mb-3">
                            <a href="/">
                                <img src={`${Window.static_path}img/virkesborsen/icon.svg`} className="max-h-100" />
                            </a>

                            <div onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' })}} role="button" className="d-md-none bg-primary-main rounded-circle float-end me-4 mt-3">
                                <img src={`${Window.static_path}img/icons/long-arrow-up.svg`} className="max-h-50" />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <h6 className="secondary-dark fw-bold mb-3">Treebula</h6>

                            <ul className="list-unstyled subtitle">
                                <li className="mb-2"><a href="/om-oss/">Om oss</a></li>
                                <li className="mb-2"><a href="/fastighetsvardering/">Vad är skogen värd?</a></li>
                                <li className="mb-2"><a href="/nyheter/vara-nojda-skogsagare/">Våra nöjda kunder</a></li>
                                <li className="mb-2"><a href="/om-oss/kontakt/">Kontakta oss</a></li>
                                <li className="mb-2"><a href="https://virkesborsen.teamtailor.com/">Jobba hos oss</a></li>
                            </ul>
                        </div>

                        <div className="col-12 col-md-4">
                            <h6 className="secondary-dark fw-bold mb-3">Marknadsinformation</h6>
                            <ul className="list-unstyled subtitle">
                                <li className="mb-2"><a href="/kunskapscentrum/">Kunskapscentrum</a></li>
                                {/*<li className="mb-2"><a href="/kunskapscentrum/artiklar/">Artiklar</a></li>*/}
                                <li className="mb-2"><a href="/kunskapscentrum/nyheter/">Nyheter</a></li>
                                <li className="mb-2"><a href="/virkesstudion/">Virkesstudion</a></li>
                            </ul>
                        </div>

                        <div className="col-12 d-md-none mb-3">
                            {this.loadSocialMedia()}
                        </div>

                        <div className="col-12 col-md-3">
                            <h6 className="secondary-dark fw-bold mb-3">Säg hej</h6>

                            <div className="subtitle mb-3">
                                <a href="mailto:info@virkesborsen.se" className="hover-primary-main mb-2">info@virkesborsen</a><br />
                                <a href="tel:00468339944" className="hover-primary-main">08-33 99 44</a>
                            </div>

                            <div className="subtitle mb-3">
                                <span className="mb-2">Nordiska Virkebörsen AB</span><br />
                                <span>559081-6756</span>
                            </div>

                            <div className="caption mb-3">
                                <a href="/terms/" className="hover-primary-main">Användarvillkor</a> & <a href="/privacy/" className="hover-primary-main">Integritetspolicy</a>
                            </div>

                            <span className="caption mb-3">Copyright © 2024 Nordiska Virkesbörsen AB</span>
                        </div>

                        <div className="col-12 justify-content-end d-none d-md-flex mt-3">
                            {this.loadSocialMedia()}
                        </div>
                    </div>
                </footer>
            </section>
        )
    }
}

export default Footer;