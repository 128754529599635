import React from 'react';
import * as Opt from '../Helpers'


class ColorSelector extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            color: props.value ? props.value.split("-").slice(0,props.value.split("-").length-1).join("-") :"",
            weight: props.value? props.value.split("-")[-1] :"500"
        }

    }

    componentDidMount(){

    }

    handleChange(name, value){
        let data = {[name]:value}
        if(name == "color"){
            data.weight="500"
        }
        this.setState(data, () => {
            this.props.onChange(this.state.color+"-"+this.state.weight);
        })
    }

    render(){
        let options = ["neutral-stone", "neutral-cloud", "neutral-charcoal", "supp-spruce", "supp-forest-green"]

        return (
        <>
            <div className="_Input-label_1v7zr_27">
                {this.props.name}
            </div>
            <div className='_Input-label_1v7zr_27'>
                Color
            </div>
            <select value={this.state.color} onChange={(e) => {this.handleChange("color", e.target.value)}} className='_Input-input_1v7zr_46'>
                {Opt.colorVariables.map((itm) => <option value={itm.value}>{itm.label}</option>)}
            </select>
            
            
            {options.includes(this.state.color) ? <>
            <div className='_Input-label_1v7zr_27'>
                Weight
            </div>
            <select value={this.state.weight} onChange={(e) => {this.handleChange("weight", e.target.value)}} className='_Input-input_1v7zr_46'>
                <option value="500">Default</option>
                <option value="200">200</option>
                <option value="300">300</option>
                {this.state.color == "supp-forest-green" ? <option value="400">400</option> : null}
                <option value="600">600</option>
                <option value="700">700</option>
            </select>
            </> : null}
        </>)
    }
}

export default ColorSelector;