import React from "react";


class NHTabs extends React.Component{
    constructor(props){
        super(props);

        this.state={
            activeTab: props.tabs.find(val => val[0] == props.activeDefault)
        }
    }

    onChange(itm){
        this.setState({activeTab:itm}); 
        if(this.props.onChange){
            this.props.onChange(itm[0])
        }
    }

    render(){
        return <div className="container">
            <div className="d-flex my-3 w-100 no-scroll-bar" style={{overflowX:"auto", overflowY:"hidden"}}>
                {this.props.tabs.map((itm) => {
                    return <div style={{width:(screen.width >= 992 ? "25%": "auto"), borderBottom:(this.state.activeTab[0] == itm[0] ? "1px solid #000" : "none")}}>
                    <p className="text-center px-2 m-1 n-fs-6" style={{"cursor":"pointer"}} onClick={() => {this.onChange(itm)}}>{itm[1]}</p>
                </div>
                })}
            </div>
        </div>;
    }
}

export default NHTabs;