import React from 'react';

import SBPNavigation from '../../components/SBP/SBPNavigation';
import Risker from '../../components/SBP/Risker';

class RiskerPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        return <div className="d-flex p-0">
            <SBPNavigation hamburgerBehavior="leaflet" />

            <Risker />
        </div>
    }
}

export default RiskerPage;