import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class HeroMetric extends React.Component {
    constructor() {
        super();

        this.state = {
            activeItem: 0
        }
    }

    render() {
        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
                <div className={`p-0 container`}>
                        <div className='row px-1 py-5 justify-content-between'>
                            {this.props.cards.map((card) => {
                                return <div className={`col-6 col-md-3 pb-2`}>
                                    <div className={`ms-1 mb-1 border-0 d-flex flex-column justify-content-end align-items-start ps-3 bg-${this.props.containterBackgroundColor}`} style={{ borderRadius: "0px 192px 0px 0px", alignItems: "end", height: "200px", width: "100%" }}>
                                        <p className={`${card.textColor} fs-1 fw-bold`}>{card.value} </p>
                                        <p className={`${card.textColor} fs-6 fw-normal`}>{card.text}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
        )
    }
}
HeroMetric.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    containterBackgroundColor: 'neutral-cloud',
    cards: [{ value: "65000+", text: "Medlemmar", textColor: "neutral-charcoal" }, { value: "29%", text: "Högre virkespriser" }, { value: "1500+", text: "Virkesköpare", textColor: "neutral-charcoal"  }, { value: "Value", text: "Label", textColor: "neutral-charcoal" }]
}
HeroMetric.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    containterBackgroundColor: {
        type: "select",
        options: Opt.colorVariables
      },
    cards: {
        type: "array",
        arrayFields: {
            value: { type: "text" },
            text: { type: "text" },
            textColor: {
                type: "select",
                options: Opt.colorVariables
            },
        },
        defaultItemProps: HeroMetric.defaultProps.cards[3],
    },

}
export default HeroMetric;
