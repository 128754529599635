import React from 'react';
import * as Opt from '../editor/Helpers';
import styled from 'styled-components';
import ColorSelector from '../editor/fields/ColorSelector';

const PlusIcon = styled.span`
  display: inline-block;
  margin-right: 25px;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  user-select: none; /* Prevent text selection */

  &::after {
    content: '+';
    display: inline-block;
    line-height: 0.8em;
    transform: rotate(0deg);
    transition: 300ms;
    ${({ isOpen }) => (isOpen && `transform:rotate(360deg)`)}
  }

`;


const AccordionItem = styled.div`
  border-bottom: ${({ isLastItem }) => (isLastItem ? 'none' : '1px solid #D9D7D4')};
  padding: 48px;
  background-color:"#F2EFE9"
  &.open {
    border-bottom-color: transparent;
  }
`;

const QuestionHeading = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AccordionBody = styled.div`
  margin-left: 17px;
  margin-top: 10px;
  padding: 0 20px;
`;
class FAQ extends React.Component {
    constructor() {
        super();

        this.state = {
            openItems: [],
        }
    }
    handleToggle = (index) => {
        this.setState((prevState) => ({
            openItems: [...prevState.openItems].map((open, i) => (i === index ? !open : open)),
        }));
    };

    componentDidMount() {
        this.setState({
            openItems: Array(this.props.items.length).fill(false),
        });
    }
    render() {
        return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-block' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-block'} p-0 bg-${this.props.backgroundColor}`}>
                <div className={`container`}>
                        <div className={`bg-${this.props.containerBackgroundColor}`} style={{ borderRadius: "40px" }}>
                            {this.props.items && this.props.items.map((i, index, array) => {
                                const isLastItem = index === array.length - 1;
                                return <AccordionItem key={index} className={this.state.openItems[index] ? 'open' : ''} isLastItem={isLastItem}>
                                    <QuestionHeading onClick={() => this.handleToggle(index)}>
                                        <PlusIcon isOpen={this.state.openItems[index]} />
                                        <span className={`${i.questionColor}`}>{i.question}</span>
                                    </QuestionHeading>
                                    {this.state.openItems[index] && <AccordionBody className={`${i.answerColor}`}>{i.answer}</AccordionBody>}
                                </AccordionItem>
                            })}
                        </div>
                </div>
            </div>
        )
    }
}
FAQ.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    containerBackgroundColor: 'neutral-cloud',
    textColor: "neutral-charcoal",
    items: [{ question: "Bacon ipsum dolor amet in flank ham commodo cupidatat veniam?", questionColor: "neutral-charcoal",
    answer: "Non reprehenderit magna, bresaola enim shoulder tempor aute id venison lorem velit. Andouille pig beef ribs ut, rump anim pariatur alcatra prosciutto pork belly pork cupidatat bresaola. Frankfurter ex in, strip steak jerky boudin incididunt doner ut pork belly brisket rump meatball minim.", answerColor: "neutral-charcoal", }, {
        question: "Bacon ipsum dolor amet in sed ground round ball tip laborum eu deserunt in?", questionColor: "neutral-charcoal", answer: "Ullamco officia tenderloin alcatra jowl corned beef ham hock. Capicola do cillum labore, prosciutto nostrud doner. Drumstick tail bresaola ribeye ut eu spare ribs ullamco leberkas shank picanha. Jowl excepteur ham cillum tempor hamburger laborum turkey mollit eiusmod.", answerColor: "neutral-charcoal",
    }]
}
FAQ.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    containerBackgroundColor: {
        type: "select",
        options: Opt.colorVariables
      },
    items: {
        type: "array",
        arrayFields: {
            question: { type: "text" },
            questionColor: {
                type: "select",
                options: Opt.colorVariables,
            },
            answer: { type: "text" },
            answerColor: {
                type: "select",
                options: Opt.colorVariables,
            },
        },
        defaultItemProps: {
            question: "Example question",
            answer: "Bacon ipsum dolor amet short ribs brisket tongue boudin. Ham kielbasa pork chop, cow doner alcatra pig pork loin bresaola. Leberkas tenderloin buffalo short ribs andouille landjaeger pig turkey drumstick ribeye brisket alcatra ground round",
        }
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },

}
export default FAQ;
