import { validateEmail, formatPhoneNumber} from '../../api';
import { isValidPhoneNumber } from 'react-phone-number-input';

  //this is the VBInput for email or mobile number for forms: Login, PropertyValue, PropertyValueForm, Seller & Grot
  export const handleInputChange = (val, setState, checkEmailOrPhonenumber, formKey) => {
    setState(prevState => ({...prevState, errors: { ...prevState.errors, email_phone: '' }}));
      
    if (validateEmail(val)) {
      checkEmailOrPhonenumber(`email=${encodeURIComponent(val)}`)
         
      setState(prevState => {
        const { mobile_phone_number, ...rest } = prevState[formKey];   
        return { ...prevState, isEmail: true, [formKey]: {...rest, email: val}};
      }); 
          
    } else if (formatPhoneNumber(val) !== null) {
      val = formatPhoneNumber(val)  

        if(isValidPhoneNumber(val)){ 
          checkEmailOrPhonenumber(`mobile_phone_number=${encodeURIComponent(val)}`)

          setState(prevState => {
            const { email, ...rest } = prevState[formKey];                 
            return { ...prevState, isEmail: false, [formKey]: {...rest,mobile_phone_number: val}};
          });
        }
    } 
  };
