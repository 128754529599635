import React from 'react';
import styled from 'styled-components';

import Accordion from 'react-bootstrap/Accordion';
import * as Opt from './editor/Helpers';

const StyledItem = styled.div`
    border-color: #F1F7F4 !important;
    color: #1C332A;

    .accordion-header {
        .accordion-button {
            color: #1C332A;
            transition: background-color .15s ease;
            div {
                transition: background-color .15s ease;
                img {
                    transition: filter .15s ease, transform .15s ease;
                }
            }
            &.collapsed {
                border-radius: 1rem;
            }
            &:not(.collapsed) {
                background-color: #F1F7F4;
                box-shadow: none;
                div {
                    background-color: #A2CCB7;
                    img {
                        transform: rotate(-90deg);
                        filter: invert(96%) sepia(60%) saturate(16%) hue-rotate(214deg) brightness(104%) contrast(100%);
                    }
                }
            }
            &:focus {
                border: 0;
                color: #1C332A;
                box-shadow: none;
            }
            &::after {
                content: none;
            }
        }
    }

    .accordion-collapse {
        background-color: white;
        &.collapsing, &.show {
            background-color: #F1F7F4;
        }
    }
`

class AskedQuestions extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return(
            <section className={`container ${this.props.theme} ${this.props.textColor}`}>
                <Accordion className="row" flush alwaysOpen>
                    <div className="col-12">
                        <h2>{this.props.title}</h2>
                    </div>

                    {this.props.items && this.props.items.map((i, index) => {
                        return <Accordion.Item eventKey={index} className={`col-12 col-lg-6 border-0 px-0 ${this.props.theme}`}>
                            <StyledItem className={`border border-3 rounded m-3 ${this.props.theme}`}>
                                <Accordion.Header as='h3'>
                                    <b className={`py-3 pe-3 ${this.props.textColor}`}>{i.question}</b><div className="border border-1 rounded-circle border-primary-main ms-auto"><img src={`${Window.static_path}img/icons/caret.svg`} width="30" height="30" className="primary-main-filter rotate-25" /></div>
                                </Accordion.Header>
                                <Accordion.Body className="pt-0">
                                    {i.answer}
                                </Accordion.Body>
                            </StyledItem>
                        </Accordion.Item>
                    })}
                </Accordion>
            </section>
        )
    }
}
AskedQuestions.defaultProps = {
    title:"Example title",
    textColor:"primary-dark",
    items: [{ question: "Bacon ipsum dolor amet in flank ham commodo cupidatat veniam?", answer: "Non reprehenderit magna, bresaola enim shoulder tempor aute id venison lorem velit. Andouille pig beef ribs ut, rump anim pariatur alcatra prosciutto pork belly pork cupidatat bresaola. Frankfurter ex in, strip steak jerky boudin incididunt doner ut pork belly brisket rump meatball minim. " }, {
        question: "Bacon ipsum dolor amet in sed ground round ball tip laborum eu deserunt in?", answer: "Ullamco officia tenderloin alcatra jowl corned beef ham hock. Capicola do cillum labore, prosciutto nostrud doner. Drumstick tail bresaola ribeye ut eu spare ribs ullamco leberkas shank picanha. Jowl excepteur ham cillum tempor hamburger laborum turkey mollit eiusmod."
    }]
}

AskedQuestions.fields={
    title: { type: "text" },
    items: {
        type: "array",
        arrayFields: {
            question: { type: "text" },
            answer: { type: "text" }
        },
        defaultItemProps:{
            question:"Example question",
            answer:"Bacon ipsum dolor amet short ribs brisket tongue boudin. Ham kielbasa pork chop, cow doner alcatra pig pork loin bresaola. Leberkas tenderloin buffalo short ribs andouille landjaeger pig turkey drumstick ribeye brisket alcatra ground round"
        }
    },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    theme: {
        type: "select",
        options: Opt.themeOptions
    },              
}
export default AskedQuestions;