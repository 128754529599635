import React from 'react';
import Joyride from 'react-joyride';

class Help extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            helpMapTools: false,
            run:this.props.visible,
            steps: [
                {
                    target: '#propSelector',
                    content: 'Klicka här för att välja eller söka efter en fastighet',
                    disableBeacon: true,
                },
                {
                    target: '#leaflet-layer-button',
                    content: 'Klicka här för att ändra karttyp samt lägga till kartdata och se observationer.',
                    disableBeacon: true,
                },
                {
                    target: '#toolkit-button',
                    content: 'Här kan du redigera avdelningar, markörer samt visa din plats mm.',
                    disableBeacon: true,
                },
                {
                    target: "#enableDrawPolygon",
                    content: "Här kan du rita nya avdelningar",
                    disableBeacon: true,
                },
                {
                    target: "#enableEditPolygon",
                    content: "Här kan du redigera befintliga avdelningar",
                    disableBeacon: true,
                },
                {
                    target: "#enableDeletePolygon",
                    content: "Här kan du radera avdelningar",
                    disableBeacon: true,
                },
                {
                    target: "#getLocation",
                    content: "Här kan du visa din plats i kartan",
                    disableBeacon: true,
                },
                {
                    target:"path.leaflet-interactive",
                    content:"Klicka på en avdelning i din skogsbruksplan för att se information",
                    disableBeacon: true,
                },
                {
                    target:"#sectionDataContainer",
                    content:"Här kan du se skogdata för den valda avdelningen",
                    disableBeacon: true,
                },
                {
                    target:"#editSectionDataPen",
                    content:"Klicka här för att redigera skogsdatan i den valda avdelningen",
                    disableBeacon: true,
                },
                {
                    target:screen.width >= 992 ? "#skogsoverblickdesktop" : "#skogsoverblickmobile",
                    content:"Här kan du få en överblick av din skogsdata",
                    disableBeacon: true,
                },
                {
                    target:"#help-button",
                    content:"Klicka här för att visa guiden igen eller om du behöver mer hjälp ring oss på 08-33 99 44",
                    disableBeacon: true,
                },

            ]

        }
    }
    handleJoyrideClose = () => {
        this.props.onClose();
    };

    render() {
        const customLocale = {
            back: 'Tillbaka',
            close: 'Stäng',
            last: 'Kom igång',
            next: 'Nästa',
            open: 'öppna',
            skip: (
                <button style={{borderRadius:'4px', borderColor: 'black', backgroundColor: 'white', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px'}} onClick={this.handleJoyrideClose}>Hoppa över</button>
            )
        };
        return (<React.Fragment>
            <Joyride
                continuous={true}
                floaterProps={{ placement: "left" }}
                steps={this.state.steps}
                close={this.handleJoyrideClose}
                showSkipButton={true}
                showProgress={true}
                scrollDuration={0}
                spotlightClicks={true}
                disableScrolling={true}
                hideBackButton={true}
                locale={customLocale}
                styles={{
                    options: {
                        textColor: '#2a2e33',
                        primaryColor:'#023436',
                        overlayColor:'rgba(0, 0, 0, 0.8)',
                        zIndex:10000,
                    }
                }}
                callback={(data) => {

                    if(data.type == "step:after" && data.index==1){
                        document.querySelector("#toolkit-button").click();
                    }
                    if(data.type == "step:after" && data.index==2){
                    }
                    if(data.type == "step:after" && data.index==6){
                        document.querySelector("#toolkit-button").click();

                        const layers = this.props.mapHandler.current.map.current.state.drawn.getLayers();
                        if (layers.length > 0) {
                            //if no property, skip step 8 and go to step 12
                            layers[0].fireEvent('click');
                            setTimeout(() => {
                                document.querySelector("#sectionDetailsContainer").style.opacity="0"
                            }, 100)
                        }
                    }

                    if(data.type == "step:after" && data.index==7){
                        document.querySelector("#sectionDetailsContainer").style.opacity="1"
                    }

                    if(data.type == "step:after" && data.index==8){
                        if(screen.width < 992){
                            document.querySelector("#MobileSBPNavbarHamburger").click();
                            setTimeout(() => {
                                document.querySelector("#SBPNavigationContainer").style.opacity="0"
                            }, 100)
                        }
                    }

                    if(data.type == "step:after" && data.index==9){
                        document.querySelector("#closeSectionDetailsContainer").click();
                        if(screen.width < 992){
                            document.querySelector("#SBPNavigationContainer").style.opacity="1"
                        }
                    }

                    if(data.type == "step:after" && data.index==10){
                        if(screen.width < 992){
                            document.querySelector("#sbpCloseNavImgmobile").click();
                        }
                    }
                    if (data.status === 'ready') {
                        this.handleJoyrideClose();
                    }
                    if (data.action === 'close'){
                        this.handleJoyrideClose();
                    }
                }} />

        </React.Fragment>)
    }
}
export default Help;



