import React from 'react';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import TextEditor from '../editor/fields/TextEditor';
import ColorSelector from '../editor/fields/ColorSelector';

class PricingSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  render() {
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor} `}>
        <div className={`container`}>
            <div className='col-12 d-flex justify-content-center flex-wrap'>
            {/* grab the first two only */}
              {this.props.options.slice(0,2).map((option, index) => (
                <div className={`col-12 col-lg-5 ${index === 0 ? 'mb-4 mb-lg-0 me-lg-3 me-0' : ''} ${option.borderWeight} border-${option.borderColor} bg-${option.backgroundColor} rounded-4 p-4`} key={index}> 
                  <p className={`n-fs-5 fw-bold mb-1 ${option.headerColor}`}>{option.header}</p>
                  <p className={`n-fs-6 ${option.bodyColor}`}>{option.body}</p>
                  <div className={`border border-1 border-${option.lineColor}`}></div>
                  <div className='d-block quill-text my-4' dangerouslySetInnerHTML={{ __html: option.price }}></div>

                  {/* button here */}
                  {(!Window.user_data.isLogged || (Window.user_data.isLogged && !option.hideButtonIfLogged)) && <a href={option.href} className={`n-btn ${option.buttonSize} text-center ${option.buttonStyle} ${option.buttonOptions} d-block w-100`} style={{ minWidth: "300px" }}>
                    {option.text}
                    <span>{option.iconAfter && <img className={`ms-1 ${option.iconColor}`} style={{ width: "18px", height: "18px" }} src={option.iconAfter} />}</span>
                  </a>}
                  <div className={`border border-1 border-${option.lineColor} my-4`}></div>

                  {/* List here */}
                  {option.list && option.list.map((list, listIndex) => (
                    <div className='d-flex align-items-start mb-3' key={listIndex}>
                      <img src={`${Window.static_path}img/icons/tickIconPriceSection.svg`} height="14px" className={`me-2 mt-1 ${list.tickColor}`} alt="tickList" />
                      <div className='d-block quill-text' dangerouslySetInnerHTML={{ __html: list.content }}></div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
      </div>
    );
  };
}

PricingSection.defaultProps = {
  visibility: "",
  backgroundColor: 'white-500',
  options: [
    {
      backgroundColor:'neutral-cloud-500',
      borderWeight: 'border-0',
      borderColor:'neutral-charcoal',
      header: 'Standard',
      headerColor:'neutral-charcoal',
      body: 'Detta får du med vårt Standard paket.',
      bodyColor:'neutral-charcoal',
      price: '0 kr/år',
      text: "Action",
      href: "/",
      buttonSize: "",
      buttonStyle: "btn-outline",
      buttonOptions: "btn-charcoal",
      hideButtonIfLogged: false,
      iconAfter: null,
      iconColor: "neutral-charcoal-filter",
      lineColor: 'neutral-charcoal-300',
      list: [
        {
          tickColor: 'neutral-charcoal-filter',
          content: 'Sälja virke och biobränsle',
        },
        {
          tickColor: 'neutral-charcoal-filter',
          content: 'Begränsad Prisjämförelse',
        },
        {
          tickColor: 'neutral-charcoal-filter',
          content: 'Skogsbruksplan med begränsad information (8 fastigheter)',
        },
      ],
    },
    {
      backgroundColor:'neutral-charcoal-500',
      borderWeight: 'border-0',
      borderColor:'neutral-charcoal',
      header: 'Plus',
      headerColor:'primary-core',
      body: 'Detta får du med vårt Standard paket.',
      bodyColor:'white',
      price: '699 kr/år',
      text: "Action",
      href: "/",
      buttonSize: "",
      buttonStyle: "",
      buttonOptions: "btn-core",
      iconAfter: null,
      iconColor: "white-filter",
      lineColor: 'white-500',
      list: [
        {
          tickColor: 'primary-core-filter',
          content: 'Sälja virke och biobränsle',
        },
        {
          tickColor: 'primary-core-filter',
          content: 'Begränsad Prisjämförelse',
        },
        {
          tickColor: 'primary-core-filter',
          content: 'Skogsbruksplan med begränsad information (8 fastigheter)',
        },
      ],
    },
  ],
}

PricingSection.fields = {
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  options: {
    type: "array",
    arrayFields: {
      backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      borderWeight: {
        type: 'select',
        options: Opt.border
      },
      borderColor: {
        type: "select",
        options: Opt.colorVariables
      },
      header: { type: "text" },
      headerColor: {
        type: "select",
        options: Opt.colorVariables
      },
      body: { type: "text" },
      bodyColor: {
        type: "select",
        options: Opt.colorVariables
      },
      price: {
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} />,
      },
      text: { type: "text" },
      href: { type: "text" },
      buttonSize: {
        type: "select",
        options: Opt.buttonSize
      },
      buttonStyle: {
        type: "select",
        options: Opt.buttonStyle
      },
      buttonOptions: {
        type: "select",
        options: Opt.buttonOptions
      },
      hideButtonIfLogged: {
        type: "radio",
        options: [{label: "Yes", value: true}, {label: "No", value: false}]
      },
      iconAfter: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'icon'} onChange={onChange} value={value} />,
      },
      iconColor: {
        type: "select",
        options: Opt.filterOptions
      },
      lineColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
      list: {
          type: "array",
          arrayFields: {
            tickColor: {
              type: "select",
              options: Opt.filterOptions
            },
            content: {
              type: "custom",
              render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} />,
            },
          },
      },
      
    },
    
    defaultOptionProps: PricingSection.defaultProps.options[0] || {},
    defaultListProps: PricingSection.defaultProps.options[0]?.list[0] || {},
  },

}

export default PricingSection;