import React from 'react';
import VBRadioGroup from '@virkesborsen/vb-js-commons/lib/components/new/vbRadioGroup';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import { uploadAdAttachments, deleteAdAttachments } from '../../api';
import Modal from 'react-bootstrap/Modal';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

class InfoIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  render() {
    return (
      <div>
        <img className="d-md-none neutral-charcoal-filter ms-2" style={{ width: "28px", height: "28px" }} src={Window.static_path + "img/icons/info-circle.svg"} onClick={this.toggleModal} />

          <Modal className='pe-0 modal-open' style={{maxWidth:'355px', paddingLeft:'0px'}} show={this.state.showModal} onHide={this.toggleModal}>
            <Modal.Header closeButton>
              <p className='fw-medium m-0'>{this.props.title}</p>
            </Modal.Header>
            <Modal.Body>
              {this.props.instructions}
              <button className='mt-2 n-btn btn-forest-green' onClick={this.toggleModal}>Stäng</button>
            </Modal.Body>
          </Modal>
          {/* this fix the weird padding that is applied when modal is opened */}
          <style>
            {`
              .modal-open {
                padding-right: 0px !important;
              }
            `}
          </style>
      </div>
    )
  }
}

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forestManagementOptions: null,
      sellGrotOptions: null,
      certificationStatus: null,
      otherInformation: '',
      temp_files: [],
      loading_message: <h2 className="w-100 text-center pt-1">Vänta...</h2>,
      loading:false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 100, behavior: 'smooth' })
  }

  handleFilesUpload = (event, index) => {
    let data = { ...this.state };
    if (index == null) {
        let files = event.target.files;
        !data.temp_files ? (data.temp_files = []) : null;
        Array.from(files).map((file) => {
            data.temp_files.push(file);
        });
    } else {
        data.temp_files.splice(index, 1);
        data.temp_files.length === 0 ? delete data.temp_files : null;
    }

    this.setState({ ...data,loading:true }, () => {
        const filesToSend = Array.isArray(data.temp_files) ? data.temp_files : [data.temp_files];
        uploadAdAttachments(filesToSend, this.props.adData.id).then(() => {
          this.props.updateFiles();
        }).finally(()=>{
        this.setState({loading:false, temp_files:[]})
        });
    });
}


  render() {

    const yesNoOptions = [
      { label: 'Ja', value: true },
      { label: 'Nej', value: false },
      { label: 'Vet ej', value: null },
    ];
    
    const certificationOptions = [
      { label: 'PEFC', value: 'PEFC' },
      { label: 'FSC', value: 'FSC' },
      { label: 'Dubbel', value: 'Both' },
      { label: 'Nej', value: 'No' },
      { label: 'Vet ej', value: 'donno' },
    ];

    return (
      <div className=''>
      <VBLoadingScreen
          show_loading={this.state.loading}
          disable_reload_timeout={true}
          loading_text={this.state.loading_message} />
        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
            <div className='d-flex'>
              <p className='w-lg-100 w-75 fw-medium neutral-charcoal'>1. Markbereding: Önskar du att ta in offert på markberedning i samband med din avverkning?</p>
              {/* Do you want any forest management in connection with your felling? */}
              {/* Mobile Modal */}
              <InfoIcon title="Vill du ha någon skogsvård i samband med din avverkning?" instructions={[
                <div>
                  <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
                </div>
              ]} />
            </div>
            <VBRadioGroup
              options={yesNoOptions}
              initial={this.props.adData.ground_preparation}
              onChange={(val) => {
                this.props.changeAdData({ ground_preparation: val })
              }}
            />
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
            <div className='rounded-3 bg-neutral-stone-300 p-3'>
              <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
            </div>
          </div>
        </div>
        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
            <div className='d-flex'>
              <p className='w-lg-100 w-75 fw-medium neutral-charcoal'>2. Förröjning: Önskar du att ta in offert på röjning i samband med din avverkning?</p>
              {/* Do you want any forest management in connection with your felling? */}
              {/* Mobile Modal */}
              <InfoIcon title="Vill du ha någon skogsvård i samband med din avverkning?" instructions={[
                <div>
                  <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
                </div>
              ]} />
            </div>
            <VBRadioGroup
              options={yesNoOptions}
              initial={this.props.adData.precleaning_needed}
              onChange={(val) => {
                this.props.changeAdData({ precleaning_needed: val })
              }}
            />
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
            <div className='rounded-3 bg-neutral-stone-300 p-3'>
              <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
            </div>
          </div>
        </div>
        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
            <div className='d-flex'>
              <p className='w-lg-100 w-75 fw-medium neutral-charcoal'>3. Återplantering: Önskar du att ta in offert på återplantering i samband med din avverkning?</p>
              {/* Do you want any forest management in connection with your felling? */}
              {/* Mobile Modal */}
              <InfoIcon title="Vill du ha någon skogsvård i samband med din avverkning?" instructions={[
                <div>
                  <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
                </div>
              ]} />
            </div>
            <VBRadioGroup
              options={yesNoOptions}
              initial={this.props.adData.replanting}
              onChange={(val) => {
                this.props.changeAdData({ replanting: val })
              }}
            />

          </div>

          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
            <div className='rounded-3 bg-neutral-stone-300 p-3'>
              <p className='mb-0'><span className='fw-medium'>Skogsvård:</span>Önskar du få någon skogsvård utförd i samband med din avverkning/gallring och vill få offert på utförandet av skogsvården klickar du ”Ja” på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket. Är du osäker? Klicka då på ”Vet ej”. Vill du inte ha någon åtgärd klickar du på ”Nej” istället.</p>
            </div>
          </div>
        </div>

        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
          <div className='d-flex'>
            <p className='neutral-charcoal fw-medium'>4. Vill du sälja GROT?</p>
            {/* Do you want to sell GROTS? */}
            {/* Mobile Modal */}
            <InfoIcon title="Vill du sälja GROT?" instructions={[
              <p className='mb-0'>Om du väljer ja här så får du även ett pris på biobränslet som faller ut när du avverkar. Vill du inte sälja biobränslet i samband med din avverkning väljer du nej.</p>
            ]} />
            </div>
            <VBRadioGroup
              options={yesNoOptions}
              initial={this.props.adData.grot_included}
              onChange={(val) => this.props.changeAdData({ grot_included: val })}
            />
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0'><span className='fw-medium'>Vill du sälja GROT?</span> Om du väljer ja här så får du även ett pris på biobränslet som faller ut när du avverkar. Vill du inte sälja biobränslet i samband med din avverkning väljer du nej.</p>
            </div>
          </div>
        </div>

        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
          <div className='d-flex'>
            <p className='neutral-charcoal fw-medium'>5. Är skogen certifierad?</p>
            {/* Is the forest certified? */}
            {/* Mobile Modal */}
            <InfoIcon title="Är skogen certifierad?" instructions={[
              <p className='mb-0'>Om din skog är certifierad välj då antingen PEFC, FSC alternativt dubbel om du har båda. Är du osäker? Välj då ”Vet ej”, är du inte certifierad väljer du ”Nej”.</p>
            ]} />
            </div>
            <VBRadioGroup
              options={certificationOptions}
              initial={this.props.adData.pefc_certified ? "PEFC" : this.props.adData.fsc_certified ? "FSC" : (this.props.adData.pefc_certified && this.props.adData.fsc_certified) ? "Both" : !(this.props.adData.pefc_certified && this.props.adData.fsc_certified) ? "No" : null}
              onChange={(selectedValue) => {
                let certificationData = {};
                if (selectedValue === "PEFC") {
                  certificationData = { pefc_certified: true, fsc_certified: false };
                } else if (selectedValue === "FSC") {
                  certificationData = { pefc_certified: false, fsc_certified: true };
                } else if (selectedValue === "Both") {
                  certificationData = { pefc_certified: true, fsc_certified: true };
                } else if (selectedValue === "No") {
                  certificationData = { pefc_certified: false, fsc_certified: false };
                } else {
                  certificationData = { pefc_certified: null, fsc_certified: null };
                }

                this.props.changeAdData(certificationData);
              }}
            />
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0'><span className='fw-medium'>Är skogen certifierad?</span> Om din skog är certifierad välj då antingen PEFC, FSC alternativt dubbel om du har båda. Är du osäker? Välj då ”Vet ej”, är du inte certifierad väljer du ”Nej”.</p>
            </div>
          </div>
        </div>

        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
          <div className='d-flex'>
            <p className='neutral-charcoal fw-medium'>6. Övrig information:</p>
            {/* Other information */}
            {/* Mobile Modal */}
            <InfoIcon title="Övrig information:" instructions={[
              <p className='mb-0'>Här anger du all övrig information som är bra för köparen att känna till. Detta kan exempelvis vara vilka bilvägar man får köra på, kod till vägbom, om du önskar förskott, om skogen är snitslad/bandad eller annan viktig information som hjälper köparen. Om det inte finns någon övrig information så lämna denna ruta tom.</p>
            ]} />
            </div>
            <VBTextarea
              onChange={(val)=>this.props.changeAdData({comments:val})}
              height="200px"
              initial={this.props.adData.comments !== undefined ? this.props.adData.comments : ''}
            />
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0'><span className='fw-medium'>Övrig information:</span> Här anger du all övrig information som är bra för köparen att känna till. Detta kan exempelvis vara vilka bilvägar man får köra på, kod till vägbom, om du önskar förskott, om skogen är snitslad/bandad eller annan viktig information som hjälper köparen. Om det inte finns någon övrig information så lämna denna ruta tom.</p>
          </div>
          </div>
        </div>

        <div className='row flex-md-row justify-content-between mt-5'>
          <div className='col-12 col-md-6'>
          <div className='d-flex'>
            <p className='neutral-charcoal fw-medium'>7. Bilagor:</p>
            {/* Appendices */}
            {/* Mobile Modal */}
            <InfoIcon title="Bilagor:" instructions={[
              <p className='mb-0'><span className='fw-medium'>Ladda upp bilagor:</span> Här kan du ladda upp samtliga bilagor som rör den aktuella avverkningen/gallringen. Exempelvis skogsbruksplan, karta, villkor, stämplingslängder, avverkningsanmälan etc.</p>
            ]} />
            </div>
            {this.props.adData.attachments && this.props.adData.attachments.length > 0 ? (
              <>
                <div className='bg-whiteoff rounded-top'>
                  {Array.from(this.props.adData.attachments).map((file, index) => {
                    return (
                      <a className='mt-2 bg-supp-forest-green-300 p-2 me-2 white rounded-pill n-fs-7 d-inline-block' key={index}>
                        {file.name}
                        <img
                          src={Window.static_path + 'img/icons/X.svg'}
                          className='ms-2 white-filter'
                          role='button'
                          width='16px'
                          height='16px'
                          onClick={async () => {
                            this.setState({ loading: true });
                            await deleteAdAttachments(file.id); 
                            this.props.updateFiles();
                            this.setState({ loading: false }); }}
                        />
                      </a>
                    );
                  })}
                </div>
              </>
            ) : null}
            <div className='bg-whiteoff rounded-bottom'>
              <label className="n-btn btn-forest-green btn-sm mt-3" role="button"> <img className={`me-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path + "img/icons/icon-left.svg"} htmlFor='attachments' />Välj filer
                <input
                  multiple
                  type='file'
                  id='attachments'
                  className='position-absolute'
                  style={{ opacity: 0, width: "1px", marginLeft: "24px" }}
                  onChange={(e) => {
                    this.handleFilesUpload(e, null);
                  }}
                />
              </label>
            </div>
          </div>
          <div className='d-md-block d-none mt-3 mt-md-0 col-12 col-md-5'>
          <div className='rounded-3 bg-neutral-stone-300 p-3'>
            <p className='mb-0'><span className='fw-medium'>Ladda upp bilagor:</span> Här kan du ladda upp samtliga bilagor som rör den aktuella avverkningen/gallringen. Exempelvis skogsbruksplan, karta, villkor, stämplingslängder, avverkningsanmälan etc.</p>
            </div>
          </div>
        </div>

      </div>
    )
  };

}

export default Step3;