import { useState } from "react";
import { Modal } from "react-bootstrap";
import VbTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import Cookies from 'js-cookie';



const saveTranslations = (translated) => {
    let promises = []
    Object.keys(translated).forEach((key) => {
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken'),
            'Content-Type': "application/json"
        }

        promises.push(fetch(`/lang-api/update-string/${Window.lang_data[key].id}/`, {
            method: "PATCH",
            headers: headers,
            body:JSON.stringify({
                result_txt: translated[key],
                requires_update: false
            })
        }));
    })

    Promise.all(promises).then(() => {
        window.location.reload();
    })
}



const TxtTranslateModal = (props) => {
    const [translated, setTranslated] = useState(Object.keys(Window.lang_data).reduce((acum, key) => {if(Window.lang_data[key].requires_update) {acum[key] = Window.lang_data[key].result_txt} return acum}, {}))


    let itms = [];
    Object.keys(Window.lang_data).forEach((key) => {
        if(!Window.lang_data[key].result_txt){
            itms.push(Window.lang_data[key])
        }
        else if(Window.lang_data[key].requires_update){
            itms.push(Window.lang_data[key])
        }
    })



    return <Modal size="lg" centered show={true} >
        <Modal.Body>
            <h3>Page is missing translations!</h3>
            <div style={{maxHeight:"500px", overflowY:"auto"}}>
            {itms.map((obj) => {
                return <div className="border-bottom border-top my-2">
                    <p className="mb-0">Source Text: <span className="fst-italic">{obj.source_txt}</span></p>
                    <p className="mb-0">Context clue: <span className="fst-italic">{obj.context_clue}</span></p>
                    <p className="mb-1">Translate to: <span className="fst-italic">{obj.target_lang}</span></p>
                    <VbTextarea initial={obj.result_txt} onChange={(val) => {setTranslated({...translated, [obj.location_id]:val})}} height="300px" />
                    
                </div>
            })}
            </div>
            <button className="n-btn btn-sm" onClick={() => {saveTranslations(translated)}}>Save</button>
        </Modal.Body>
    </Modal>
}

export default TxtTranslateModal;


