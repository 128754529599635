import React from 'react';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/vbInput";
import VBRadios from "@virkesborsen/vb-js-commons/lib/components/vbRadios";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/vbMultiOption";
import VBAlert from "@virkesborsen/vb-js-commons/lib/components/vbAlert";
import {get_ad_data} from "@virkesborsen/vb-js-commons/lib/api";
import BuyerSellerBoth from "@virkesborsen/vb-js-commons/lib/components/buyerSellerBoth"
import Cookies from 'js-cookie';

class Login extends React.Component {
    constructor(){
        super();
        this.state={
            isReg: null,
            alertMsg: "",
            forestRegionLabel:"I vilken kommun äger du skog? (flerval)"
        }
        this.emailInput = React.createRef();
        this.buyerSellerBoth = React.createRef();
        this.checkingEmail = null;
    }

    checkEmail(email){
        if(this.emailInput.current.validation(email)){
            const headers = {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            }
            
            //clearTimeout(this.checkingEmail);
            this.checkingEmail = setTimeout(function(){
                fetch("/user-api/exists/?email=" + encodeURIComponent(email), {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: headers
                })
                .then(resp => resp.json())
                .then((json) => {
                    this.setState({
                        'isReg': json.exists
                    });
                });
            }.bind(this), 200);
        }
        
    }

    componentDidMount(){
        if(this.props.formData["email"]){
            this.checkEmail(this.props.formData["email"]);
        }
    }

    changeActiveRegionLabel (buyerSellerBoth, checked) {
        if(checked){
            if(buyerSellerBoth.indexOf("seller") != -1){
                this.setState({forestRegionLabel:"I vilka kommuner äger du skog? (flerval)"});
            } else if(buyerSellerBoth.indexOf("buyer") != -1){
                this.setState({forestRegionLabel:"I vilka kommuner köper du skog? (flerval)"});
            } else{
                this.setState({forestRegionLabel:"I vilka kommuner är du verksam? (flerval)"});
            }
        }
    }

    render() {
    return <div>
        {this.props.errorMsg ? <h2 class="error">{this.props.errorMsg}</h2> : null}
        <VBInput 
            errors={this.props.formErrors} 
            onChangeFunc={this.checkEmail.bind(this)} 
            ad_data={this.props.formData} 
            inputType="email" 
            name="email" 
            title="E-postadress" 
            disable_ad_data={true} 
            ref={this.emailInput}
            larger={true} />
        {/* has account */}
            {this.state.isReg ? <div>
                <input type="hidden" name="register" value="0" />
                <div class="title" ><span>Vad kul, du har redan ett konto. Nu är det bara ange ditt lösenord och logga in!</span></div>
                <VBInput 
                    errors={this.props.formErrors} 
                    inputType="password" 
                    ad_data={this.props.formData} 
                    name="password" 
                    disable_ad_data={true} 
                    title="Lösenord" 
                    larger={true}/>
                <a class="left" href={this.props.forgot_password_link}>Glömt lösenord?</a>
                <button class="btn white right" type="submit" onClick={() => {
                    gtag("event", "login", {
                        method: "site"
                      });
                }}>Logga in</button>
            </div> : null}
        {/* needs to regiser */}
            {this.state.isReg === false ? <div>
                <input type="hidden" name="register" value="1" />
                <div class="title orange"><span>Du verkar inte ha något konto sedan tidigare. Fyll i informationen nedan för att registrera dig.</span></div>
                <BuyerSellerBoth 
                    data={this.props.buyerSellerBothData}
                    formData={this.props.formData}
                    errors={this.props.formErrors}
                    ref={this.buyerSellerBoth}
                    changeActiveRegionLabel={this.changeActiveRegionLabel.bind(this)}
                />
                <VBMultiOption 
                    errors={this.props.formErrors} 
                    name="muni" 
                    ad_data={this.props.formData} 
                    title={this.state.forestRegionLabel}
                    options={this.props.muni} 
                    disable_ad_data={true} 
                    larger={true} />
                <VBPhoneInput 
                    errors={this.props.formErrors} 
                    name="mobile_phone_number" 
                    ad_data={this.props.formData} 
                    title="Mobilnummer" 
                    larger={true}
                    disable_ad_data={true} />
                <VBInput 
                    errors={this.props.formErrors} 
                    inputType="password" 
                    ad_data={this.props.formData} 
                    name="password" 
                    disable_ad_data={true} 
                    title="Välj lösenord (8 tecken eller mer)" 
                    larger={true}/>
                <VBInput 
                    errors={this.props.formErrors} 
                    inputType="password" 
                    ad_data={this.props.formData} 
                    name="password2" 
                    disable_ad_data={true} 
                    title="Upprepa lösenordet" 
                    larger={true}/>
                <p>Genom att klicka på registrera godkänner du våra 
                    <a href="/privacy" target="_blank"> användarvillkor </a> 
                    och vår <a href="/privacy" target="_blank">integritetspolicy</a>
                </p>
                <button class="btn white right" type="submit" onClick={() => {
                    mixpanel.track('Clicked register');
                    gtag('event', 'Registered', {});
                    gtag("event", "generate_lead", {
                        currency: "SEK",
                        value: 190
                      });
                    gtag("event", "sign_up", {
                        method: "Site"
                      });
                    }}>Registrera</button>
            </div> : null}
            {this.state.isReg == null ? <button class="btn white right" type="submit" onClick={() => {mixpanel.track('Clicked login');}}>Logga in</button> : null}
    </div>;
    }
}

export default Login;