import React from 'react';

import Overblick from '../../components/SBP/Overblick';
import SBPNavigation from '../../components/SBP/SBPNavigation';

class OverblickPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (<div className="d-flex p-0 overflow-hidden vh-100">
            <SBPNavigation />

            <Overblick />
        </div>)
    }
}

export default OverblickPage;