import React, { Fragment } from 'react';
import * as Opt from '../editor/Helpers';

class Footer extends React.Component {
    constructor(props) {
      super();
      this.state = {
        prevTheme: props.theme,
        textColor: props.theme == 'neutral-charcoal' ? 'neutral-cloud' : 'neutral-charcoal',
        svgColor: props.theme == 'neutral-charcoal' ? 'primary-core-filter' : '',
        socialMediaColor: props.theme == 'neutral-charcoal' ? 'neutral-cloud-filter' : '',
        linkStyle: props.theme == 'neutral-charcoal' ? 'neutral-cloud hover-primary-core' : '',

      }
    }

    loadSocialMedia() {
        return(
            <Fragment>
                <a href="https://www.facebook.com/Treebula/" className="me-3" target="_blank">
                    <img src={`${Window.static_path}img/social-media/FacebookNew.svg`} className={`max-h-50 ${this.state.socialMediaColor}`} />
                </a>
                <a href="https://www.instagram.com/treebula.se/" className="me-3" target="_blank">
                    <img src={`${Window.static_path}img/social-media/InstagramNew.svg`} className={`max-h-50 ${this.state.socialMediaColor}`} />
                </a>
                <a href="https://se.linkedin.com/company/treebula" className="me-3" target="_blank">
                    <img src={`${Window.static_path}img/social-media/LinkedIn.svg`} className={`max-h-50 ${this.state.socialMediaColor}`} />
                </a>
                <a href="https://www.youtube.com/@treebula1721/featured" className="me-3" target="_blank">
                    <img src={`${Window.static_path}img/social-media/Youtube.svg`} className={`max-h-50 ${this.state.socialMediaColor}`} />
                </a>
            </Fragment>
        )
    }

    componentDidUpdate() {
        if (this.state.prevTheme != this.props.theme) {
            this.setState({
                prevTheme: this.props.theme,
                textColor: this.props.theme == 'neutral-charcoal' ? 'neutral-cloud' : 'neutral-charcoal',
                svgColor: this.props.theme == 'neutral-charcoal' ? 'primary-core-filter' : '',
                socialMediaColor: this.props.theme == 'neutral-charcoal' ? 'neutral-cloud-filter' : '',
                linkStyle: this.props.theme == 'neutral-charcoal' ? 'neutral-cloud hover-primary-core' : '',
            })
        }
    }

    render() {
        return (
            <div className={`bg-${this.props.theme} ${this.state.textColor} p-0`}>
                <div className='container'>
                    <div className="row p-2 p-lg-0 pt-4 pt-lg-4">
                        <div className="col-12 col-lg-6 mb-3">
                            <a className="py-2" href="/">
                                <img src={`${Window.static_path}img/icons/treebula_black_filter.svg`} className={`py-2 ${this.state.svgColor}`} style={{width: "190px"}}/>
                            </a>
                            <div onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' })}} role="button" className={`d-lg-none bg-${this.props.theme == 'neutral-charcoal' ? 'primary-core' : 'neutral-pebble'} rounded-circle float-end me-4 mt-3`}>
                                <img src={`${Window.static_path}img/icons/long-arrow-up.svg`} className={`max-h-50 ${this.props.theme == 'neutral-charcoal' ? 'neutral-charcoal' : this.props.theme}-filter`} />
                            </div>
                            <p className="n-fs-7 mt-3 mb-1">Adress:</p>

                            <div className="n-fs-7">
                            <span className="">Pyramidvägen 7, 169 56 Solna</span><br />
                            </div>  
                            <p className="n-fs-7 mt-3 mb-1">Kontakt:</p>
                                <div>
                                <a href="tel:00468339944" className={`n-fs-7 ${this.state.linkStyle}`}><u>08-33 99 44</u></a>
                                </div>
                                <div>
                                <a href="mailto:info@treebula.se" className={`n-fs-7 mb-2 ${this.state.linkStyle}`}>info@treebula.se</a><br />
                            </div>  
                            <div className="col-12 d-flex py-2">
                            {this.loadSocialMedia()}
                            </div>                         
                        </div>

                        <div className="col-12 col-lg-2">
                            <p className="n-fs-7 fw-bold my-3">Treebula</p>

                            <ul className={`list-unstyled n-fs-7`}>
                                <li className="mb-2"><a href="/om-oss/" className={`${this.state.linkStyle}`}>Om oss</a></li>
                                <li className="mb-2"><a href="/fastighetsvardering/" className={`${this.state.linkStyle}`}>Vad är skogen värd?</a></li>
                                <li className="mb-2"><a href="/referenser/" className={`${this.state.linkStyle}`}>Våra nöjda kunder</a></li>
                                <li className="mb-2"><a href="/om-oss/kontakt/" className={`${this.state.linkStyle}`}>Kontakta oss</a></li>
                                <li className="mb-2"><a href="https://careers.treebula.se/" className={`${this.state.linkStyle}`}>Jobba hos oss</a></li>
                            </ul>
                        </div>    
                        <div className="col-12 col-lg-2">
                            <p className="n-fs-7 fw-bold my-3">Våra Tjänster</p>

                            <ul className="list-unstyled n-fs-7">
                                <li className="mb-2"><a href="/skogsbruksplan/" className={`${this.state.linkStyle}`}>Generera skogsbruksplan</a></li>
                                <li className="mb-2"><a href="/skogsbruksplan/karta/" className={`${this.state.linkStyle}`}>Din fastighetskarta</a></li>
                                <li className="mb-2"><a href="/skogsbruksplan/overblick/" className={`${this.state.linkStyle}`}>Din fastighetsöverblick</a></li>
                                <li className="mb-2"><a href="/salj-virke/" className={`${this.state.linkStyle}`}>Sälj virke</a></li>
                                <li className="mb-2"><a href="/biobransle/" className={`${this.state.linkStyle}`}>Sälj biobränsle</a></li>
                                <li className="mb-2"><a href="/virkespriser/" className={`${this.state.linkStyle}`}>Jämför virkespriser</a></li>
                                <li className="mb-2"><a href="/virkespriskollen/" className={`${this.state.linkStyle}`}>Virkespriskollen</a></li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg-2">
                            <p className="n-fs-7 fw-bold my-3">Marknadsinformation</p>
                            <ul className="list-unstyled n-fs-7">
                                <li className="mb-2"><a href="/kunskapscentrum/" className={`${this.state.linkStyle}`}>Kunskapscentrum</a></li>
                                <li className="mb-2"><a href="/kunskapscentrum/artiklar/" className={`${this.state.linkStyle}`}>Artiklar</a></li>
                                <li className="mb-2"><a href="/kunskapscentrum/nyheter/" className={`${this.state.linkStyle}`}>Nyheter</a></li>
                                <li className="mb-2 text-nowrap"><a href="/kunskapscentrum/treebula-studio/" className={`${this.state.linkStyle}`}>Treebula Studio</a></li>
                            </ul>
                        </div>

                        
                        <div className="row flex-column-reverse flex-lg-row d-flex n-fs-7 my-3">
                        <div className="col-12 col-md-8 col-lg-10 n-fs-7 mb-3 text-nowrap">Copyright © 2024 Treebula</div>
                        <div className="col-12 col-md-2 col-lg-1 n-fs-8 mb-3 px-lg-2 text-lg-end text-nowrap">
                            <a href="/privacy/" className={`n-fs-7 ${this.state.linkStyle}`}>Integritetspolicy</a>
                        </div>
                        <div className="col-12 col-md-2 col-lg-1 n-fs-8 mb-3 text-lg-end text-nowrap">                     
                            <a href="/terms/" className={`n-fs-7 ${this.state.linkStyle}`}>Användarvillkor</a> 
                        </div>
                        </div>
                        </div>
                        
                    
                </div>
            </div>
        )
    }
}

Footer.defaultProps = {
    theme: 'neutral-charcoal',
}
  
Footer.fields = {
    theme: {
        type: "select",
        options: Opt.themes,
    }
}

export default Footer;