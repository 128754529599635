import React from 'react';
import * as Opt from '../editor/Helpers'
import { CalculateSpacing } from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

class PostCommon extends React.Component {
    constructor() {
        super();
    }

    render() {
        let breadcrumb = Window.react_data.breadcrumbs ? Window.react_data.breadcrumbs : [{slug: "/link/", name: "Category1"}, {slug: "/link/", name: "Category2"}]
        let title = Window.react_data.title ? Window.react_data.title : "Page Title"

        let published_date = Window.react_data.published_date ? Window.react_data.published_date : "2024-03-04"
        let dateobject = new Date(Date.parse(published_date))
        let formatted_date = dateobject.getDate().toString() + " " + dateobject.toLocaleString('default', { month: 'short' }) + " " + dateobject.getUTCFullYear().toString();

        return <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} bg-${this.props.backgroundColor}`}>
            <div className={`container ${CalculateSpacing(this.props.spacing)} overflow-hidden`}>
                <div className="row">
                    <div className="col-12">
                        <p className="n-fs-5 d-flex align-content-center mb-5 mt-5 flex-wrap">
                        {breadcrumb.map((itm) => {
                            return <>
                            <a style={{lineHeight:"28px"}} href={itm.slug}>{itm.name}</a>
                            <img className="mx-2" src={`${Window.static_path}img/icons/caret.svg`} style={{height:"28px", width:"28px"}} />
                            </>;
                        })}
                        <a style={{lineHeight:"28px"}} href="">{title}</a>
                        </p>

                        <h1 className="n-h3 fw-bold mb-5">{title}</h1>
                        <p className="n-fs-5">{formatted_date}</p>
                    </div>
                </div>
            </div>
        </div>;
    }
}

PostCommon.defaultProps = {
    visibility: "",
    backgroundColor: 'white-500',
    spacing: [],
}

PostCommon.fields={
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    spacing: Opt.spacingOptions,
}

export default PostCommon;
