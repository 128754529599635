import React from 'react';


class RadiusEditor extends React.Component{
    constructor(props){
        super(props);
        if(this.props.value){
            const classes = this.props.value.split(" ");
            let type = "vb-rounded"
            let location = classes.slice(1).map(itm => itm.replace("rounded-", "")).join(" ");
            let breakpoint = "";
            let size = "1";

            if(classes[0].includes("corners")){
                type="corners"
                location = ""
                let attrs = classes[0].split("-");
                //if has breakpoint grab that else only size
                if(attrs.length > 3){
                    breakpoint = attrs[1]
                    size = attrs[2]
                } else{
                    size = attrs[1]  
                }
                classes.map((itm) => {
                    //If has breakpoint location is different in string
                    if(itm.split("-").length == 4){
                        location += itm.split("-")[3] + " "
                    } else{
                        location += itm.split("-")[2] + " "
                    }
                    
                })
                location = location.slice(0,-1)
            } 
            

            this.state = {
                "type":  type,
                "location": location,
                "breakpoint": breakpoint,
                "size": size
            }
        } else{
            this.state = {
                "type":  "vb-rounded",
                "location": "",
                "breakpoint": "",
                "size": "1"
            }
        }
        
    }

    componentDidMount(){

    }

    handleChange(name, value){
        this.setState({[name]:value}, () => {
            
            if(this.state.type == "corners"){
                let result = ""
                let base = "corners"
                if(this.state.breakpoint){
                    base += "-"+this.state.breakpoint
                }
                if(this.state.size){
                    base += "-"+this.state.size
                }
                this.state.location.split(" ").map((itm) => {result += base + "-" + itm + " "})
                this.props.onChange(result.slice(0,-1))
            } else{
                let locations = this.state.location.split(" ").map(itm => "rounded-"+itm).join(" ")

                this.props.onChange(this.state.type + " " + locations)
            }
            
        })
    }

    render(){
        return (
        <div>
            <div className="_Input-label_1v7zr_27">
                {this.props.name}
            </div>
            <div className='_Input-label_1v7zr_27'>
                Type
            </div>
            <select value={this.state.type} onChange={(e) => {this.handleChange("type", e.target.value)}} className='_Input-input_1v7zr_46'>
                <option value="vb-rounded">20%</option>
                <option value="corners">Static</option>
            </select>
            <div className='_Input-label_1v7zr_27'>
                Location
            </div>
            <select value={this.state.location} onChange={(e) => {this.handleChange("location", e.target.value)}} className='_Input-input_1v7zr_46'>
                <option value="">None</option>
                <option value="sb eb">Bottom</option>
                <option value="sb st">Left</option>
                <option value="eb et">Right</option>
                <option value="st et">Top</option>
                <option value="st">Top right</option>
                <option value="et">Top left</option>
                <option value="eb">Bottom right</option>
                <option value="sb">Bottom left</option>
                <option value="all">All</option>
            </select>
            {/* Options for corner */}
            {this.state.type == "corners" && <>
                <div className='_Input-label_1v7zr_27'>
                    Breakpoint
                </div>
                <select value={this.state.breakpoint} onChange={(e) => {this.handleChange("breakpoint", e.target.value)}} className='_Input-input_1v7zr_46'>
                    <option value="">None (0px and up)</option>
                    <option value="sm">Mobile (576px and up)</option>
                    <option value="md">Tablet (768px and up)</option>
                    <option value="lg">Desktop (992px and up)</option>
                    <option value="xl">Extra Large (1200px and up)</option>
                    <option value="xxl">Huge Large (1400px and up)</option>
                </select>
                <div className='_Input-label_1v7zr_27'>
                    Size
                </div>
                <select value={this.state.size} onChange={(e) => {this.handleChange("size", e.target.value)}} className='_Input-input_1v7zr_46'>
                    <option value="0">None</option>
                    <option value="1">1 (DT 12px | M 8px)</option>
                    <option value="2">2 (DT 16px | M 12px)</option>
                    <option value="3">3 (DT 24px | M 16px)</option>
                    <option value="4">4 (DT 32px | M 24px)</option>
                    <option value="5">5 (DT 40px | M 32px)</option>
                    <option value="6">6 (DT 80px | M 72px)</option>
                    <option value="7">7 (Round)</option>
                </select>
            </>}
        </div>)
    }
}

export default RadiusEditor;