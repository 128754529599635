const breakpoints = [
    {label: "None (0px and up)", value: ""},
    {label: "Mobile (576px and up)", value: "sm-"},
    {label: "Tablet (768px and up)", value: "md-"},
    {label: "Desktop (992px and up)", value: "lg-"},
    {label: "Extra Large (1200px and up)", value: "xl-"},
    {label: "Huge Large (1400px and up)", value: "xxl-"}
]

const visibilityOptions = [
    {label: "Show On Both", value: ""}, 
    {label: "Only on Mobile", value: "mob"}, 
    {label: "Only on Desktop", value: "dt"}
]

const yesNo = [
    {label: "Yes", value: "yes"}, 
    {label: "No", value: "no"},
]

const buttonOptions = [
    { label: "Core", value: "btn-core" },
    { label: "Charcoal", value: "btn-charcoal" },
    { label: "Spruce", value: "btn-spruce" },
    { label: "Forest Green", value: "btn-forest-green" }
]

const targetOptions = [
    { label: "Default", value: "_self" },
    { label: "New tab", value: "_blank" },
    { label: "Open parent", value: "_parent" },
    { label: "Full body of window", value: "_top" }
]

const buttonStyle = [
    { label: "Regular", value: "" },
    { label: "Outline", value: "btn-outline" },
    { label: "Ghost", value: "btn-ghost" },
    { label: "Outline Core", value: "btn-outline primary-core" },
]

const buttonSize = [
    { label: "Regular", value: "" },
    { label: "Small", value: "btn-sm" },
    { label: "X-Small", value: "btn-xs" }
]

const headerElements = [
    { label: "Heading 1 (95px)", value: "h1" },
    { label: "Heading 2 (76px)", value: "h2" },
    { label: "Heading 3 (61px)", value: "h3" },
    { label: "Heading 4 (49px)", value: "h4" },
]

const iconOptions = [
    { label: "Forest money", value: "img/icon-detail/forest-money.svg" },
    { label: "Find Buyers", value: "img/icon-detail/find-buyers.svg" },
    { label: "Safety", value: "img/icon-detail/safe-trade.svg" }
]

const colorVariables = [
    //Doberman colors
    { label: "Primary Core", value: "primary-core" },
    { label: "White", value: "white"},
    { label: "Neutral Stone", value: "neutral-stone" },
    { label: "Neutral Cloud", value: "neutral-cloud" },
    { label: "Neutral Pebble", value: "neutral-pebble" },
    { label: "Neutral Charcoal", value: "neutral-charcoal" },
    { label: "Supp Spruce", value: "supp-spruce" },
    { label: "Supp Pine", value: "supp-pine" },
    { label: "Supp Forest Green", value: "supp-forest-green" },
    { label: "Supp Plum", value: "supp-plum" },
    { label: "Supp Haze", value: "supp-haze" },
    { label: "Supp Blackberry", value: "supp-blackberry" },
    { label: "Supp Scarlet", value: "supp-scarlet" },
    { label: "Supp Redwood", value: "supp-redwood" },
    { label: "Supp Ceder", value: "supp-ceder" },
    { label: "Transparent", value: "transparent" },
]

const TextAlign = [
    { label: "Start", value: "text-start"},
    { label: "Center", value: "text-center"},
    { label: "End", value: "text-end"}
]

const TextSizes = [
    { label: "Regular", value: ""},
    { label: "Eight (10px)", value: "n-fs-8"},
    { label: "Seven (13px)", value: "n-fs-7"},
    { label: "Six (16px)", value: "n-fs-6"},
    { label: "Five (20px)", value: "n-fs-5"},
    { label: "Four (25px)", value: "n-fs-4"},
    { label: "Three (31px)", value: "n-fs-3"},
    { label: "Two (39px)", value: "n-fs-2"},
    { label: "One (49px)", value: "n-fs-1"},
    { label: "Heading Four (49px)", value: "n-h4"},
    { label: "Heading Three (61px)", value: "n-h3"},
    { label: "Heading Two (76px)", value: "n-h2"},
    { label: "Heading One (95px)", value: "n-h1"}
]

// Variable above should go away if we will allow breakpoints everywhere e.g. The one below is used for the breakpoints options
const textSizes = [
    { label: "Regular", value: ""},
    { label: "Eight (10px)", value: "fs-8"},
    { label: "Seven (13px)", value: "fs-7"},
    { label: "Six (16px)", value: "fs-6"},
    { label: "Five (20px)", value: "fs-5"},
    { label: "Four (25px)", value: "fs-4"},
    { label: "Three (31px)", value: "fs-3"},
    { label: "Two (39px)", value: "fs-2"},
    { label: "One (49px)", value: "fs-1"},
    { label: "Heading Four (49px)", value: "h4"},
    { label: "Heading Three (61px)", value: "h3"},
    { label: "Heading Two (76px)", value: "h2"},
    { label: "Heading One (95px)", value: "h1"}
]

const TextStyles = [
    { label: "Regular", value: ""},
    { label: "Forced Normal", value: "fw-normal"},
    { label: "Italic", value: "fst-italic"},
    { label: "Medium", value: "fw-medium"},
    { label: "Medium Italic", value: "fw-medium fst-italic"},
    { label: "Bold", value: "fw-bold"},
    { label: "Bold Italic", value: "fw-bold fst-italic"}
]

const opacityOptions = [
    { label: "50%", value: "50" },
    { label: "25%", value: "25" },
    { label: "75%", value: "75"},
    { label: "100%", value: "100"} 
]

/* no need for theme now we can use bg-${className} */
/* Do  not uncomment this for Salja virke */
const themeOptions = [
    { label: "Light", value: "bg-white" },
    { label: "Dark", value: "bg-primary-dark" },
    { label: "Secondary Light", value: "bg-secondary-light"} 
]

// New Themes
const themes = [
    { label: "Cloud", value: "neutral-cloud" },
    { label: "Charcoal", value: "neutral-charcoal" },
    { label: "White", value: "white"} 
]

const styleJustifyContent = [
    {label: "Start", value: "start"},
    {label: "End", value: "end"},
    {label: "Center", value: "center"},
    {label: "Around", value: "space-around"},
    {label: "Between", value: "space-between"},
    {label: "Evenly", value: "space-evenly"}
]

const styleAlignItems = [
    {label: "Start", value: "start"},
    {label: "End", value: "end"},
    {label: "Center", value: "stretch"},
    {label: "Around", value: "center"},
]

const contentJustify = [
    {label: "Start", value: "justify-content-start"},
    {label: "End", value: "justify-content-end"},
    {label: "Center", value: "justify-content-center"},
    {label: "Around", value: "justify-content-around"},
    {label: "Between", value: "justify-content-between"},
    {label: "Evenly", value: "justify-content-evenly"}
]

const contentAlignItems = [
    {label: "Start", value: "align-items-start"},
    {label: "End", value: "align-items-end"},
    {label: "Center", value: "align-items-center"},
    {label: "Baseline", value: "align-items-baseline"},
    {label: "Stretch", value: "align-items-stretch"}
]

const contentAlignSelf = [
    {label: "Start", value: "align-self-start"},
    {label: "End", value: "align-self-end"},
    {label: "Center", value: "align-self-center"},
    {label: "Baseline", value: "align-self-baseline"},
    {label: "Stretch", value: "align-self-stretch"}
]


const filterOptions = [
    // Doberman colors
    { label: "Primary Core", value: "primary-core-filter" },
    { label: "White", value: "white-filter" },
    { label: "Neutral Stone", value: "neutral-stone-filter" },
    { label: "Neutral Cloud", value: "neutral-cloud-filter" },
    { label: "Neutral Pebble", value: "neutral-pebble-filter" },
    { label: "Neutral Charcoal", value: "neutral-charcoal-filter" },
    { label: "Supp Spruce", value: "supp-spruce-filter" },
    { label: "Supp Pine", value: "supp-pine-filter" },
    { label: "Supp Forest Green", value: "supp-forest-green-filter" },
    { label: "Supp Plum", value: "supp-plum-filter" },
    { label: "Supp Haze", value: "supp-haze-filter" },
    { label: "Supp Blackberry", value: "supp-blackberry-filter" },
    { label: "Supp Scarlet", value: "supp-scarlet-filter" },
    { label: "Supp Redwood", value: "supp-redwood-filter" },
    { label: "Supp Ceder", value: "supp-ceder-filter" },
]


const roundedOptions = [
    { label: "None", value: "rounded-0" },
    { label: "Rounded One", value: "rounded-1" },
    { label: "Rounded Two", value: "rounded-2" },
    { label: "Rounded Three", value: "rounded-3" },
    { label: "Rounded Four", value: "rounded-4" },
    { label: "Rounded Five", value: "rounded-5" }
]

const colorHex = [
        //Old new colors
        {label:"black",value:"#000000"},
        
        // Primary
        {label:"sage",value:"#A2CCB7"},
        {label:"primary-main",value :"#A2CCB7"},// rgba(162, 204, 183, 1)
        {label:"pine", value:"#1C332A"},
        {label:"primary-dark", value:"#1C332A"}, // rgba(28, 51, 42, 1)
    
        // Secondary
        {label:"secondary-light", value:"#EAEEED"}, // rgba(234, 238, 237, 1)
        {label:"secondary-main",value:"#C4D0CD"}, // rgba(196, 208, 205, 1)
        {label:"grey", value:"#8D9392"},    
        {label:"secondary-grey", value:"#8D9392"}, // rgba(141, 147, 146, 1)
        {label:"secondary-dark" ,value:"#0A0A0A"}, // rgba(10, 10, 10, 1)
        {label:"secondary-blue", value:"#00ABAC"}, // rgba(0, 171, 172, 1)
    
        // Tertiary
        {label:"tertiary-light", value:"#CAEDDC"}, // rgba(202, 237, 220, 1)
        {label: "tertiary-blue", value:"#60DFE0"}, // rgba(96, 223, 224, 1)
        {label: "tertiary-main", value:"#0C9B63"}, // rgba(12, 155, 99, 1)
        {label: "tertiary-dark", value: "#1C5740"}, // rgba(28, 87, 64, 1)
        {label: "highlight",  value: "#FF6E7E"},
        {label: "tertiary-red",  value: "#FF6E7E"}, // rgba(255, 110, 126, 1)
    
        // Alerts
        {label: "red-alert", value: "#D0342C"},
    
        // Old old colors
        {label: "blue-old", value: "#3660b9"}, // rgba(54, 96, 185, 1)
        {label: "orange-old", value: "#ec842d"}, // rgba(236, 132, 45, 1)

        // Doberman colors
        { label: "primary-core", value: "#dfff5d"},
        { label: "white", value: "#ffffff"},
        { label: "neautral-stone", value: "#d9cec1" },
        { label: "neautral-cloud", value: "#e9e9e9" },
        { label: "neutral-pebble", value: "#50514f" },
        { label: "neutral-charcoal", value: "#2a2e33" },
        { label: "supp-spruce", value: "#2faf9a" },
        { label: "supp-pine", value: "#037971" },
        { label: "supp-forest-green", value: "#023436" },
        { label: "supp-plum", value: "#6a58c6" },
        { label: "supp-haze", value: "#3c416d" },
        { label: "supp-blackberry", value: "#232347" },
        { label: "supp-scarlet", value: "#f26157" },
        { label: "supp-redwood", value: "#a84343" }, 
        { label: "supp-ceder", value: "#4c2b36" }, 
];

const boxShadow = [
    {label:"No shadow",value:"shadow-none"},
    {label:"Small Shadow",value:"shadow-sm"},
    {label:"Regular Shadow",value:"shadow"},
    {label:"Large Shadow",value:"shadow-lg"},
]

const border = [
    {label:"None", value:"border-0"},
    {label:"Border-1", value:"border border-1"},
    {label:"Border-2", value:"border border-2"},
    {label:"Border-3", value:"border border-3"},
    {label:"Border-4", value:"border border-4"},
    {label:"Border-5", value:"border border-5"},
]

const paddingOptions = [
    { label: "1 (4px)", value: "p-1" },
    { label: "2 (8px)", value: "p-2" },
    { label: "3 (12px)", value: "p-3" },
    { label: "4 (16px)", value: "p-4" },
    { label: "5 (20px)", value: "p-5" },
]

const paddingOptionsStart = [
    { label: "0 (0px)", value: "ps-0" },
    { label: "1 (4px)", value: "ps-1" },
    { label: "2 (8px)", value: "ps-2" },
    { label: "3 (12px)", value: "ps-3" },
    { label: "4 (16px)", value: "ps-4" },
    { label: "5 (20px)", value: "ps-5" }
]

const paddingOptionsEnd = [
    { label: "0 (0px)", value: "pe-0" },
    { label: "1 (4px)", value: "pe-1" },
    { label: "2 (8px)", value: "pe-2" },
    { label: "3 (12px)", value: "pe-3" },
    { label: "4 (16px)", value: "pe-4" },
    { label: "5 (20px)", value: "pe-5" }
]

const paddingOptionsTop = [
    { label: "0 (0px)", value: "pt-0" },
    { label: "1 (4px)", value: "pt-1" },
    { label: "2 (8px)", value: "pt-2" },
    { label: "3 (12px)", value: "pt-3" },
    { label: "4 (16px)", value: "pt-4" },
    { label: "5 (20px)", value: "pt-5" }
]

const paddingOptionsBottom = [
    { label: "0 (0px)", value: "pb-0" },
    { label: "1 (4px)", value: "pb-1" },
    { label: "2 (8px)", value: "pb-2" },
    { label: "3 (12px)", value: "pb-3" },
    { label: "4 (16px)", value: "pb-4" },
    { label: "5 (20px)", value: "pb-5" }
]

const iconSizeOptions=[
    { label: "16px", value: "16px" },
    { label: "24px", value: "24px" },
    { label: "32px", value: "32px" },
    { label: "48px", value: "48px" },
    { label: "64px", value: "64px" },
]

const colOptions = [
    {label: "One", value: 1}, 
    {label: "Two", value: 2},
    {label: "Three", value: 3},
    {label: "Four", value: 4},
]

const highlightOptions = {
    type: "object",
    objectFields: {
        words: {
            type: "array",
            arrayFields: {
                word: {
                    type: "text"
                }
            },
            getItemSummary: (t) => t.words
        },
        color: {
            type: "select",
            options: colorVariables
        },
        backgroundColor: {
            type: "select",
            options: colorVariables
        },
        rounded: {
            type: "select",
            options: roundedOptions
        },
        padding: {
            type: "select",
            options: paddingOptions
        },
    }
}

const verticalSpaceOptions=[
        { label: "8px", value: "8px" },
        { label: "16px", value: "16px" },
        { label: "24px", value: "24px" },
        { label: "32px", value: "32px" },
        { label: "40px", value: "40px" },
        { label: "48px", value: "48px" },
        { label: "56px", value: "56px" },
        { label: "64px", value: "64px" },
        { label: "72px", value: "72px" },
        { label: "80px", value: "80px" },
        { label: "88px", value: "88px" },
        { label: "96px", value: "96px" },
        { label: "104px", value: "104px" },
        { label: "112px", value: "112px" },
        { label: "120px", value: "120px" },
        { label: "128px", value: "128px" },
        { label: "136px", value: "136px" },
        { label: "144px", value: "144px" },
        { label: "152px", value: "152px" },
        { label: "160px", value: "160px" },
]

const textWidth = [
    { label: "1", value: "1"},
    { label: "2", value: "2"},
    { label: "3", value: "3"},
    { label: "4", value: "4"},
    { label: "5", value: "5"},
    { label: "6", value: "6"},
    { label: "7", value: "7"},
    { label: "8", value: "8"},
    { label: "9", value: "9"},
    { label: "10", value: "10"},
    { label: "11", value: "11"},
    { label: "12", value: "12"},
]

const spacingOptions = {
    type: "array",
    arrayFields: {
        type: 
            { 
                type: "select", 
                options: [
                    {label: "Margin", value: "m"}, 
                    {label: "Padding", value: "p"}
                ] },
        direction: 
            { 
                type: "select", 
                options: [
                    {label: "All", value: "-"}, 
                    {label: "Horizontal", value: "x-"},
                    {label: "Vertical", value: "y-"},
                    {label: "Start", value: "s-"},
                    {label: "End", value: "e-"},
                    {label: "Top", value: "t-"},
                    {label: "Bottom", value: "b-"},
                ] },
        size: 
            { 
                type: "select", 
                options: [
                    {label: "One", value: "1"}, 
                    {label: "Two", value: "2"},
                    {label: "Three", value: "3"},
                    {label: "Four", value: "4"},
                    {label: "Five", value: "5"}
                ] },
        breakpoint:
            {
                type: "select",
                options: breakpoints
            }
    },
    defaultItemProps: { type: "m", direction: "-", size: "1", breakpoint: "" },
    getItemSummary: (s) => s.type+s.direction+s.breakpoint+s.size
}

const CalculateSpacing = (spacing) => {
    let elemClass = ''

    spacing.map((i) => {
        elemClass = elemClass+(i.type+i.direction+i.breakpoint+i.size+' ')
    })

    return elemClass
}

const fontSizeOptions = {
    type: "array",
    arrayFields: {
        size: 
            { 
                type: "select", 
                options: textSizes
            },
        breakpoint:
            {
                type: "select",
                options: breakpoints
            }
    },
    defaultItemProps: { size: "", breakpoint: "" },
    getItemSummary: (s) => "n-"+s.breakpoint+s.size
}

const CalculateFontSize = (sizes) => {
    let elemClass = ''

    if(sizes) {
        sizes.map((i) => {
            if(i.size && i.size != '') {
                elemClass = elemClass+('n-'+i.breakpoint+i.size+' ')
            }
        })
    }

    return elemClass
}

const highlightWordsFunc = (text, words, color, backgroundColor, rounded, padding) => {
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => (
        regex.test(part) ? <span key={index} className={`${color} bg-${backgroundColor} ${rounded} ${padding}`}>{part}</span> : part
    ))
}


export { 
    spacingOptions, 
    visibilityOptions,
    yesNo,
    CalculateSpacing, 
    buttonOptions,
    targetOptions,
    buttonStyle,
    buttonSize,
    headerElements, 
    iconOptions, 
    colorVariables,
    TextAlign, 
    TextSizes, 
    TextStyles, 
    opacityOptions, 
    themeOptions, 
    themes, 
    filterOptions,
    roundedOptions, 
    highlightOptions, 
    highlightWordsFunc,
    styleJustifyContent,
    styleAlignItems,
    contentJustify,
    contentAlignItems,
    contentAlignSelf,
    colorHex, 
    boxShadow, 
    border, 
    paddingOptions,
    paddingOptionsStart,
    paddingOptionsEnd,
    paddingOptionsTop,
    paddingOptionsBottom,
    iconSizeOptions,
    colOptions,
    verticalSpaceOptions,
    textWidth,
    fontSizeOptions,
    CalculateFontSize
}
