import { useState } from "react";

import { Modal } from "react-bootstrap";

const NHInfo = (props) => {
    const [visible, setVisible] = useState(false)
    return <>
    <img className="ps-1" src={`${Window.static_path}img/icons/info-circle.svg`} style={{height:"16px", cursor:"pointer"}} onClick={() => {setVisible(true);}} />
        <Modal centered show={visible} onHide={() => {setVisible(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
}

export default NHInfo;