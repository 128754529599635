import React from 'react'
import PropTypes from "prop-types";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import styled from 'styled-components';
import ChartSection from './ChartSection';

const PriceRoot = styled.div`
    canvas {
        min-height: 220px;
        @media (min-width: 1200px) {
            min-height: 320px;
        }
    }
`

ChartJS.register(
    CategoryScale,
    ChartDataLabels,
    annotationPlugin,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: true,
            position: 'nearest'
        },
        datalabels: {
            color: 'transparent'
        },
        autocolors: false
    },
    scales: {
        x: {
            ticks: {
                color: '#1C332A',
                fontSize: 10,
                fontWeight: 'bold',
                autoSkip: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            ticks: {
                color: '#1C332A',
                fontSize: 60,
                fontWeight: 'bold',
                beginAtZero: true,
                stepSize: 1000000,
                grace: "0.005%",
                callback: function (value, index, ticks) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " kr " + '-   ';
                },
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
    },
    maintainAspectRatio: false,
}


function LineChart({ data, title, topRight, description, value }) {
    return (
        <ChartSection
            title={title}
            component={topRight}
            page={"sbp"}
            description={description}
            value={value}>
            <PriceRoot>
                <Line data={data} options={options} />
            </PriceRoot>
        </ChartSection>
    )
}

LineChart.propTypes = {
    data: PropTypes.shape({}),
};

LineChart.defaultProps = {
    data: undefined,
}

export default LineChart;