import React from 'react';
import * as Opt from '../editor/Helpers';

class Anchor extends React.Component {
  constructor() {
      super();

      this.state = {

      };
      
  }

  render() {
    return (
        <div id={`${this.props.jumpLink}`} className={`d-block w-100`} style={{ height: "10px"}}></div>
    )
  }
}

Anchor.defaultProps = {
  jumpLink: "",
}

Anchor.fields = {
  jumpLink: {
    type: "text"
  }
}

export default Anchor;