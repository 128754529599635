import React from 'react';

import Economy from '../../components/SBP/Economy';
import SBPNavigation from '../../components/SBP/SBPNavigation';

class EconomyPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (<div className="d-flex p-0">
            <SBPNavigation />

            <Economy />
        </div>)
    }
}

export default EconomyPage;