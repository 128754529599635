import React, { Fragment } from 'react';
import Cookies from 'js-cookie';

import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';

class KnowYourCustomerForm extends React.Component {
    constructor() {
        super();

        this.state = {
            errors: {},
            properties: Window.react_data.props
        }
    }

    addProp(prop) {
        if (this.state.properties.indexOf(prop) == -1) {
            this.setState({ properties: [...this.state.properties, prop], searchResult: this.state.searchResult.filter(item => item != prop) })
        }
    }

    beteckingSeach(query) {
        if (query && query.length > 1) {
            let url = '/property-api/search/'
            let data = { 'query': query }

            if (this.fetchBeteckningTimeout) { clearTimeout(this.fetchBeteckningTimeout) }

            this.fetchBeteckningTimeout = setTimeout(() => {
                const headers = {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                }

                fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                }).then(res => res.json()).then(json => {
                    this.setState({ searchResult: json })
                })
            }, 350)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let data = this.state
        let validation = {}

        let checkList = ['has_forestplan', 'thinning', 'final_felling', 'forest_care']
        checkList.forEach(check => {
            if (!data[check] || data[check].length < 1) {
                validation[check] = "Dette fält behöver fyllas i";
            }
        })

        if (Object.keys(validation).length !== 0) {
            this.setState({ errors: validation })
        } else {
            let url = '/user-api/kyc/'
            let requestData = {
                "kyc": {
                    "final_felling": data.final_felling,
                    "forest_care": data.forest_care,
                    "thinning": data.thinning,
                    "has_forestplan": data.has_forestplan,
                },
                "props": data.properties
            }

            const headers = {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            }

            fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData)
            }).then(res => {
                if (res.ok) {
                    const urlParams = new URLSearchParams(window.location.search);

                    if (urlParams.get('next')) {
                        window.location = urlParams.get('next')
                    } else {
                        window.location = '/my-account/'
                    }
                } else {
                    alert('Oops! Något gick fel...')
                }

            })
        }
    }

    render() {
        return <Fragment>
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <VBDropdown
                    label={<label className='subtitle pb-1'>Har du en skogsbruksplan?</label>}
                    options={Window.react_data.lists.kyc_forestplan}
                    error={this.state.errors.has_forestplan}
                    onFocus={() => this.setState({ errors: { ...this.state.errors, has_forestplan: '' } })}
                    onChange={(val) => { this.setState({ has_forestplan: val.value }) }} />

                <p className="fw-bold subtitle mt-4 mb-1">Planerar du någon av följande åtgärder de kommande 2 åren?</p>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <VBDropdown
                            label={<label className='subtitle pb-1'>Avverka</label>}
                            options={Window.react_data.lists.kyc_default}
                            error={this.state.errors.final_felling}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, final_felling: '' } })}
                            onChange={(val) => { this.setState({ final_felling: val.value }) }} />
                    </div>

                    <div className="col-12 col-md-4">
                        <VBDropdown
                            label={<label className='subtitle pb-1'>Gallra</label>}
                            options={Window.react_data.lists.kyc_default}
                            error={this.state.errors.thinning}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, thinning: '' } })}
                            onChange={(val) => { this.setState({ thinning: val.value }) }} />
                    </div>

                    <div className="col-12 col-md-4">
                        <VBDropdown
                            label={<label className='subtitle pb-1'>Skogsvård</label>}
                            options={Window.react_data.lists.kyc_default}
                            error={this.state.errors.forest_care}
                            onFocus={() => this.setState({ errors: { ...this.state.errors, forest_care: '' } })}
                            onChange={(val) => { this.setState({ forest_care: val.value }) }} />
                    </div>

                    <VBSearchInput
                        label={<label className='subtitle fw-bold pb-2 mt-4'>För den fulla upplevelsen behöver vi din fastighet</label>}
                        placeholder='Hitta din fastighet'
                        onChange={(val) => { this.beteckingSeach(val); }}
                        searchResult={this.state.searchResult ? this.state.searchResult.map(prop => { return (<p className="option w-100 ps-2" onClick={() => { this.addProp(prop) }}>{prop.beteckning}</p>) }) : null} />

                    <div className="mt-4">
                        {this.state.properties.map(prop => {
                            return <h4>{prop.beteckning} <span class="highlight align-middle caption ms-2" role="button" onClick={() => { this.setState({ properties: this.state.properties.filter(item => item != prop) }) }}>Ta bort</span></h4>
                        })}
                    </div>
                    
                    <div className="d-flex justify-content-center justify-content-lg-start mt-4">
                        <button type="submit" className="btn btn-primary">FORTSÄTT</button>
                    </div>
                </div>
            </form>
        </Fragment>
    }
}

export default KnowYourCustomerForm;