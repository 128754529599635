import React from 'react';

import Karta from '../../components/SBP/Karta';
import SBPNavigation from '../../components/SBP/SBPNavigation';

class KartaPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (<div className="d-flex p-0">
            <SBPNavigation hamburgerBehavior="leaflet" />

            <Karta />
        </div>)
    }
}

export default KartaPage;