import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import * as Opt from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import Avatar from '../commons/Avatar';
import ColorSelector from '../editor/fields/ColorSelector';
import TextEditor from '../editor/fields/TextEditor';

const NUMTESTIMONIALSMOBILE = 8;

class TestimonialWithImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0,
      activeGroup: 0,
    };
  }

  // Handle the selection of the carousel item
  handleSelect = (selectedIndex) => {
    this.setState({ activeItem: selectedIndex });
  };

  // Handle moving to the next carousel item
  handleNextMobile = () => {
    const { activeItem } = this.state;
    const { items } = this.props;
    // calculate the index of the next item, looping back to the 1st testimonial
    const nextItem = (activeItem + 1) % Math.min(items.length, NUMTESTIMONIALSMOBILE);
    this.setState({ activeItem: nextItem });
  };

  // Handle moving to the previous carousel item
  handlePrevMobile = () => {
    const { activeItem } = this.state;
    const { items } = this.props;
    // Calculate the index of the previous item, looping if needed
    const prevItemMobile = (activeItem - 1 + Math.min(items.length, NUMTESTIMONIALSMOBILE)) % Math.min(items.length, NUMTESTIMONIALSMOBILE);
    this.setState({ activeItem: prevItemMobile });
  };

  handleNextDesktop = () => {
    const { activeGroup } = this.state;
    const { items } = this.props;
    // calculate the index of the next item, looping back to the 1st testimonial
    const nextGroup = (activeGroup + 1) % Math.ceil(items.length / 2);
    this.setState({ activeGroup: nextGroup });
  };

  // Handle moving to the previous carousel group on desktop
  handlePrevDesktop = () => {
    const { activeGroup } = this.state;
    const { items } = this.props;
    // Calculate the index of the previous group, looping if needed
    const prevGroup = (activeGroup - 1 + Math.ceil(items.length / 2)) % Math.ceil(items.length / 2);
    this.setState({ activeGroup: prevGroup });
  };

  renderNavigationDots() {
    const numDots = Math.ceil(this.props.items.length / this.state.numItemsToShow);
    const dots = [];
    for (let i = 0; i < numDots; i++) {
      dots.push(
        <span
          key={i}
          className={`mx-1 p-1 rounded-circle ${this.state.currentIndex / this.state.numItemsToShow === i ? 'bg-neutral-charcoal' : 'bg-neutral-pebble'}`}
          onClick={() => this.setState({ currentIndex: i * this.state.numItemsToShow })}
          style={{ cursor: 'pointer' }}
        ></span>
      );
    }
    return dots;
  }

  render() {
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
        <div className={`container`}>
            <div className='col-12 col-lg-8'>
              <h4 className={`n-h4 text-break ${this.props.headerColor}`}>{this.props.header}</h4>
              <p className={`n-fs-5 mb-0 ${this.props.bodyColor}`}>{this.props.body}</p>
            </div>

            {/* Mobile Carousel */}
            <div className={`col-12 col-md-12 col-lg-12 d-lg-none`}>
              <Carousel activeIndex={this.state.activeItem} onSelect={this.handleSelect} controls={false} indicators={false} interval={null}>
                {/* Display up to 8 testimonials on mobile */}
                {this.props.items.slice(0, NUMTESTIMONIALSMOBILE).map((item, index) => (
                  <Carousel.Item key={index} className="mb-4">
                    <Card className='m-0 border-0 bg-transparent rounded-4'>
                      <Card.Body className='p-0 pt-4'>
                        <div className="col">
                        <div className='d-block quill-text mb-4' dangerouslySetInnerHTML={{ __html: item.testimonial }}></div>
                          <Avatar
                              showAvatarImage={true}
                              showCompanyImage={true}
                              hideAvatarOnlyMobile={this.props.hideAvatarOnlyMobile}
                              avatarSrc={item.avatarSrc} 
                              avatarAlt={item.avatarAlt} 
                              name={item.name} 
                              nameColor={item.nameColor}
                              company={item.company} 
                              borderColor={item.borderColor}
                              imageSrc={item.imageSrc} 
                              imageAlt={item.imageAlt} 
                            />
                        </div>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                ))}
              </Carousel>

              {/* Render navigation dots and next/prev buttons for mobile */}
              {this.props.items.length > 1 && (
                <div className={`col-12 d-flex justify-content-center align-items-center mt-2`}>
                  <div className="rounded-circle border border-3 border-black d-flex me-2 align-items-center justify-content-center" role="button" onClick={this.handlePrevMobile} style={{ width: '48px', height: '48px' }}>
                    <img src={`${Window.static_path}img/icons/arrowBlack.svg`} height="16px" className="neutral-charcoal-filter" alt="Previous" />
                  </div>
                  {/* Render navigation dots */}
                  {this.props.items.slice(0, NUMTESTIMONIALSMOBILE).map((_, index) => (
                    <div key={index} type="button" onClick={() => this.setState({ activeItem: index })} className={`border border-2 border-neutral-charcoal rounded-circle mx-2 ${this.state.activeItem === index ? 'bg-neutral-charcoal' : ''}`} style={{ transition: '.6s ease', cursor: 'pointer', height: '10px', width: '10px' }} />
                  ))}
                  <div className="rounded-circle border border-3 border-black d-flex ms-2 align-items-center justify-content-center" role="button" onClick={this.handleNextMobile} style={{ width: '48px', height: '48px' }}>
                    <img src={`${Window.static_path}img/icons/arrowBlack.svg`} height="16px" className="neutral-charcoal-filter" alt="Next" style={{ transform: 'rotate(180deg)' }} />
                  </div>
                </div>
              )}
            </div>

            {/* Desktop Carousel- show 2 testimonials at a time - render all testimonials*/}
            <div className={`mt-4 col-12 col-md-12 col-lg-12 d-none d-lg-block`}>
              <Carousel activeIndex={this.state.activeGroup} onSelect={this.handleSelect} controls={false} indicators={false} interval={null}>
                {/* Display testimonials in groups of three on desktop */}
                {this.props.items.reduce((chunks, item, index) => {
                  if (index % 2 === 0) {
                    chunks.push(this.props.items.slice(index, index + 2));
                  }
                  return chunks;
                }, []).map((itemGroup, groupIndex) => (
                  <Carousel.Item key={groupIndex}>
                    <div className="row align-items-stretch">
                      {itemGroup.map((item, index) => (
                        <div key={index} className='col mt-3'>
                          <div className="h-100 d-flex align-items-start flex-column">
                          <div className='d-block quill-text mb-4' dangerouslySetInnerHTML={{ __html: item.testimonial }}></div>
                          <div className='mt-auto'>
                            <Avatar 
                              showAvatarImage={this.props.showAvatarImage}
                              showCompanyImage={this.props.showCompanyImage}
                              avatarSrc={item.avatarSrc} 
                              avatarAlt={item.avatarAlt} 
                              name={item.name} 
                              nameColor={item.nameColor}
                              company={item.company} 
                              borderColor={item.borderColor}
                              imageSrc={item.imageSrc} 
                              imageAlt={item.imageAlt} 
                            />
                          </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>


              <div className='mt-3 d-flex align-items-center justify-content-between'>
                {/* Render navigation dots - dynamic number of dots */}
                <div className="d-flex justify-content-start">
                  {Array.from({ length: Math.ceil(this.props.items.length / 2) }).map((_, index) => (
                    <div
                      key={index}
                      type="button"
                      onClick={() => this.setState({ activeGroup: index })}
                      className={`border border-2 border-neutral-charcoal rounded-circle size-25 mx-2 ${this.state.activeGroup === index ? 'bg-neutral-charcoal' : ''
                        }`}
                      style={{ transition: '.6s ease', height: '16px', width: '16px' }}
                    />
                  ))}
                </div>

                {this.props.items.length > 1 && (
                  <div className='d-flex justify-content-end'>
                    {/* Previous Button */}
                    <div className="rounded-circle border border-3 border-black d-flex me-2 align-items-center justify-content-center" role="button" onClick={this.handlePrevDesktop} style={{ width: '48px', height: '48px' }}>
                      <img src={`${Window.static_path}img/icons/arrowBlack.svg`} height="16px" className="neutral-charcoal-filter" alt="Previous" />
                    </div>

                    {/* Next button */}
                    <div className={`rounded-circle border border-3 border-neutral-charcoal d-flex ms-2 align-items-center justify-content-center`} role="button" onClick={this.handleNextDesktop} style={{ width: '48px', height: '48px' }}>
                      <img src={`${Window.static_path}img/icons/arrowBlack.svg`} height="16px" className="neutral-charcoal-filter" alt="Next" style={{ transform: 'rotate(180deg)' }} />
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
    )
  }
}

TestimonialWithImage.defaultProps = {
  visibility: "",
  showAvatarImage: true,
  showCompanyImage: true,
  hideAvatarOnlyMobile: false,
  backgroundColor: 'white-500',
  header: 'Customer testimonials',
  headerColor: 'neutral-charcoal',
  body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  bodyColor: 'neutral-charcoal',
  text: "Action",
  href: "/",
  variant: "outline",
  iconAfter: null,
  iconColor: "neutral-charcoal-filter",
  items: [
    {
      testimonial: '"ONE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"TWO Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"THREE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"FOUR Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"FIVE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"SIX Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"SEVEN Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
    {
      testimonial: '"EIGHT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."',
      avatarSrc: "https://placehold.co/600x400",
      avatarAlt: "scenic image",
      name: 'Name Surname',
      nameColor: 'neutral-charcoal',
      company: 'Position, Company name',
      companyColor: 'neutral-charcoal',
      borderColor: 'neutral-charcoal',
      imageSrc: "https://placehold.co/600x400",
      imageAlt: "scenic image",
    },
  ]

}

TestimonialWithImage.fields = {
  showAvatarImage:{
    type: "radio",
    options:[
        {label: "No", value: false},
        {label: "Yes", value: true}
    ]
  },
  showCompanyImage:{
    type: "radio",
    options:[
        {label: "No", value: false},
        {label: "Yes", value: true}
    ]
  },
  hideAvatarOnlyMobile:{
    type: "radio",
    options:[
        {label: "No", value: false},
        {label: "Yes", value: true}
    ]
  },
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  header: { type: "text" },
  headerColor: {
    type: "select",
    options: Opt.colorVariables
  },
  body: { type: "text" },
  bodyColor: {
    type: "select",
    options: Opt.colorVariables
  },
  text: { type: "text" },
  href: { type: "text" },
  variant: { type: "select", options: Opt.buttonOptions },
  iconAfter: {
    type: "custom",
    render: ({ name, onChange, value }) => <MediaSelector name={'iconAfter'} type={'icon'} onChange={onChange} value={value} />,
  },
  iconColor: {
    type: "select",
    options: Opt.filterOptions
  },
  items: {
    type: "array",
    arrayFields: {
      testimonial: { 
        type: "custom",
        render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} /> 
      },
      avatarSrc: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
      },
      avatarAlt: {
        type: "text"
      },
      name: { type: "text" },
      nameColor: {
        type: "select",
        options: Opt.colorVariables
      },
      company: { type: "text" },
      companyColor: {
        type: "select",
        options: Opt.colorVariables
      },
      borderColor: {
        type: "select",
        options: Opt.colorVariables
      },
      imageSrc: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
      },
      imageAlt: {
        type: "text"
      },
    },
    defaultItemProps: TestimonialWithImage.defaultProps.items[0],
  },
}

export default TestimonialWithImage;