import React from 'react';
import AskedQuestions from '../components/FAQ';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


class FAQPage extends React.Component {
    constructor() {
        super();
        this.navigation = React.createRef();
    }
    render() {
        return (
            <div className="container-fluid p-0">
                <div className="bg-white">
                <Navigation ref={this.navigation} />

                {/* HERO */}
                <AskedQuestions 
                    title={<h1 className="primary-main">FRÅGOR & SVAR</h1>}
                    items={[
                        {question: 'Hur funkar Treebula?', answer:<p>Börja med att klicka på "sälj virke", välj vilket alternativt som passar dig bäst och registrera dig, det är helt kostnadsfritt! Vi hjälper dig också gärna med hela din virkesaffär gratis! Det är bara att kontakta oss!
                            <br/>1. Som skogsägare/säljare börjar du med att välja den försäljningsform som passar dig bäst (leveransvirke, leveransrotpost, avverkningsuppdrag eller rotpost) och sedan vilken åtgärd du vill ha (avverkning, gallring, vindfällen). Efter dessa val fyller du i annonsformuläret och laddar upp eventuella bilagor för att färdigställa din annons. Efter att din annons är skickad till gransking så granskas den av Treebula och publiceras på plattformen. Om vi skulle sakna information så kontaktar vi er för att komplettera annonsen.
                            <br/>2. Som virkesköpare ser du alla virkesannonser som finns uppe och kan enkelt lämna anbud. Du som skogsägare/säljare ser löpande alla anbud och alla detaljer runt dessa. När anbudsperioden är slut väljer du det anbud och den köpare som passar dig.
                            <br/>3. När detta är klart genereras ett kontrakt baserat på informationen från virkesannonsen och anbudet som köparen lämnat. Detta kontrakt kan ni båda signera digitalt med Bank ID eller skriva ut och signera fysiskt, alternativt kan ni träffas och skriva ett eget kontrakt.
                            <br/>Skulle ni behöva någon som helst hjälp under processen kan ni kontakta oss <a href="/om-oss/kontakt/" className="primary-main fw-bold">här</a>.</p> },
                        {question: 'Kostar det något att skapa ett konto på Treebula?', answer: 'Nej, du skapar ett konto helt kostnadsfritt.'},
                        {question: 'Vad kostar det att sälja virke på Treebula?', answer: 'Det är helt kostnadsfritt att sälja virke på Treebula, dessutom får du all hjälp och rådgivning som du behöver utan någon extra kostnad.'},
                        {question: 'Vad kostar det att köpa virke på Treebula?', answer: <p>För att bidra till att täcka kostnaderna för att driva Treebula debiterar vi köparen en serviceavgift på 3% av virkesvärdet. Köper du större mängder virke på Treebula kan du kontakta oss <a href="/om-oss/kontakt/" className="primary-main fw-bold">här</a>.</p>},
                        {question: 'Vad händer om det inmätta virkesvärdet är lägre än det virkesvärde som Treebula har debiterat 3% på?', answer: 'Om det är så att Treebula har debiterat ett för högt värde kan du skicka in fakturan plus underlag för det inmätta virkesvärdet (SDC mätkvitto) så betalar vi tillbaka mellanskillnaden.'},
                        {question: 'Hur betalar man serviceavgiften till Treebula?', answer: <p>Du får ett mail med en faktura till den mailadress som du registrerat dig med. Där står all information om OCR-nummer, bankgiro och vad som debiterats. Har du några frågor runt fakturan eller inte fått den kan du kontakta oss på <a href="mailto:faktura@virkesborsen.se" className="primary-main fw-bold">faktura@virkesborsen.se</a>.</p>},
                        {question: 'Vad händer om affären faller efter signering och affären inte går igenom?', answer: <p>Om du som köpare kan visa att affären inte gick igenom så behöver du inte betala fakturan. Du skickar ditt underlag som visar att affären inte gick igenom till <a href="mailto:faktura@virkesborsen.se" className="primary-main fw-bold">faktura@virkesborsen.se</a>. Vi kontaktar därefter säljaren och bekräftar.</p>},
                        {question: 'Är jag bunden att sälja genom Treebula om jag laddar upp min annons?', answer: 'Bara under den anbudsperiod som du anger i annonsen, efter det kan du göra hur du vill. Kom ihåg att vi inte har möjlighet att hjälpa dig om något går fel med din virkesaffär om du väljer att sälja utanför Treebula. Treebulas förmåner för säljare gäller då inte heller.'},
                        {question:'Är jag bunden att köpa om jag har lämnat ett anbud?',answer:'Ja, ditt anbud är bindande. I särskilda fall kan vi dock godkänna att ditt anbud dras tillbaka, kontakta oss då för att få hjälp.'},
                        {question:'Hur genererar Treebula kontrakten och vilken information finns?',answer:'Kontrakten som genereras baseras på den information som säljaren har lämnat i annonsen samt information som köparen lämnar vid anbud. Efter avslutad affär är köpare och säljare fria att använda kontraktet som Treebula genererat eller att skapa ett eget kontrakt.'},
                        {question:'Hur väljer jag hur jag vill signera kontraktet?',answer:'När du som säljare lägger upp din virkesannons, gör du ett val om hur du vill signera kontraktet (digitalt eller fysiskt). Därefter när anbudsperioden är över och du har valt köpare, genereras ett kontrakt som både köpare och säljare kan signera på det sätt som du har valt. Skulle ni vilja skapa ett eget kontrakt istället för att använda det som Treebula genererat, kan ni ta kontakt med varandra och skapa ett eget.'},
                        {question:'Hur signerar man det digitala kontraktet som Treebula genererar?',answer:'Du signerar det tryggt och säkert genom att använda BankID.'},
                        {question:'Hur fungerar det om man vill signera kontraktet fysiskt?',answer:'När du som säljare lägger upp din virkesannons anger du att du vill signera kontraktet på papper. När kontraktet sedan genereras får ni en PDF-fil som ni skriver ut och signerar fysiskt.'},
                        {question:'Kan jag som Virkesköpare skapa ett kontrakt på Treebula?',answer:'Vi erbjuder alla användare ett kostnadsfritt kontraktsystem som kan användas för att generera ett virkeskontrakt som köpare och säljare kan använda för att utföra en virkesaffär.'},
                        {question:'Kan vi integrera Treebula mot våra befintliga system (ERP, redovisningssystem, inköpssystem)?',answer:<p>Treebula går att integrera mot många olika system! Kontakta oss <a href="/om-oss/kontakt/" class="primary-main fw-bold">här</a>.</p>}
                    ]} /> 
            </div>
            <Footer/>
                </div >
        )
    }
}
export default FAQPage;