import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from "prop-types";
import styled from 'styled-components';
import ChartSection from './ChartSection';

const BarRoot = styled.div`
    canvas {
        min-height: 220px;
        @media (min-width: 1200px) {
            min-height: 320px;
        }
    }
`

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

function GroupedBarGraph({ title, topRight, description, legend, options, data, page }) {
    return (
        <div>
            <ChartSection
                title={title}
                description={description}
                page={page}
                component={topRight} >
                <BarRoot>
                    <Bar options={options} data={data} />
                </BarRoot>
            </ChartSection>
            {legend}
        </div>
    )
}

GroupedBarGraph.propTypes = {
    data: PropTypes.shape({}),
    options: PropTypes.shape({})
};

GroupedBarGraph.defaultProps = {
    topRight: null,
    legend: null,
    data: undefined,
    options: undefined
}
export default GroupedBarGraph