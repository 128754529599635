import React from 'react';

class PostSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      searchQuery: '',
      selectedPostIds: Array.isArray(props.value) ? props.value : []
    };
  }

  componentDidMount() {
    this.fetchPosts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const newSelectedPostIds = Array.isArray(this.props.value) ? this.props.value : [];
      this.setState({ selectedPostIds: newSelectedPostIds }, () => {
      });
    }
  }

  fetchPosts = async () => {
    try {
      const response = await fetch(`/admin-api/find-posts/?query=${this.state.searchQuery}`);
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const json = await response.json();
      this.setState({ 
        posts: json.posts,
        
      });
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value }, () => {
      this.fetchPosts();
    });
  };

  handlePostClick = (postId) => {
    this.setState(prevState => {
      const selectedPostIds = Array.isArray(prevState.selectedPostIds) ? prevState.selectedPostIds : [];
      const isSelected = selectedPostIds.includes(postId);
      const updatedPostIds = isSelected
        ? selectedPostIds.filter(id => id !== postId)
        : [...selectedPostIds, postId];

      this.props.onChange(updatedPostIds);
      return { selectedPostIds: updatedPostIds };
    });
  };

  render() {
    return (
      <div>
      <div>Search for a post</div>
        <input type="text" value={this.state.searchQuery} onChange={this.handleSearchChange} placeholder="Search posts..." className='_Input-input_1v7zr_46'/>
        
        {this.state.searchQuery && (
          <div>
          {this.state.posts.map((post) => (
            <div key={post.id} onClick={() => this.handlePostClick(post.id)}
              style={{ cursor: 'pointer', padding: '5px', border: '1px solid #ccc', margin: '5px 0', backgroundColor: this.state.selectedPostIds.includes(post.id) ? '#e0e0e0' : '#fff'}}>
              {post.title}
            </div>
          ))}
        </div>
        )}
        <div>or</div>
        <div>Select a post</div>
        <select value={this.state.selectedPostIds} onChange={(e) => this.handlePostClick(e.target.value)}  className='_Input-input_1v7zr_46'>
        <option value="">Select a post</option>
          {this.state.posts.map((post) => (
            <option key={post.id} value={post.id}>
              {post.title}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default PostSelector;
