import React from 'react';

class Error404Page extends React.Component {
    constructor() {
        super();
    }

    render() {
        return <div className='container-fluid'>
            <div className='bg-white'>
                <div className='container'>
                    <h2 className='neutral-charcoal text-center fw-bold' style={{marginTop: '120px', marginBottom: '120px'}}>404</h2>
                    
                    <p className='neutral-charcoal n-fs-3 fw-medium mt-5 text-center'>Sidan kunde inte hittas.</p>
                    <p className='neutral-charcoal n-fs-4 fw-medium text-center'>Du kan försöka hitta det du letar efter i menyn ovan.</p>
                    <p className='neutral-charcoal n-fs-4 fw-medium text-center' style={{marginBottom: '120px'}}>Om du istället vill mejla oss kan du <a className='supp-spruce' href="mailto:info@treebula.se"><u>klicka här</u></a>.</p>
                </div>
            </div>
        </div>
    }
}

export default Error404Page;