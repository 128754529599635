import React from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

const Card = styled.div`
    padding-top: 100px;
`

const Person = styled.div`
    width: 150px;
    height: 150px;
    top: -75px;
`

class ForestCataloguePage extends React.Component {
    constructor() {
        super();

        this.state = {
            data: null,
            muni: null,
            loading: false
        }
    }

    fetchData(e) {
        e.preventDefault();

        const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }

        this.setState({ loading: true })

        fetch(`/forest-catalogue-api/${this.state.muni}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        }).then(resp => resp.json()).then((json) => {
            this.setState({ loading: false, data: json })
        });
    }

    render() {
        return (<div className="container bg-white">
                <VBLoadingScreen show_loading={this.state.loading} />

                {/* HERO */}
                <div className="row align-items-start mb-5 mt-5">
                    <div className="col-12 col-md-6">
                        <h1 className="text-break text-start  mb-3  n-fs-1 n-lg-h2 ">Hitta skogsbank</h1>

                        <p className="my-4 me-5">Hitta skogsbank höj lönsamheten i ditt skogsbruk med rätt bank. Hos bankerna finns lång erfarenhet och mycket kompetens om skog som kan hjälpa dig höja lönsamheten i ditt skogsbruk. Hitta rätt bankkontakt genom att välja kommun och klicka på ”hitta bankkontakt”</p>

                        <p className="fw-medium">Frågor? <a href="tel:+468339944" className="neutral-charcoal">08-33 99 44</a></p>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="mx-4">
                            <form onSubmit={(e) => { this.fetchData(e) }}>
                                <VBDropdown
                                    label={<label className='subtitle pb-1'>Kommun</label>}
                                    placeholder="Välj kommun"
                                    options={Window.react_data.munis}
                                    onChange={(val) => { this.setState({ muni: val.value }) }} />

                                <button type="submit" className="n-btn btn-core mt-4 w-100">Hitta bankkontakt</button>
                            </form>
                        </div>
                    </div>
                </div>

                {this.state.data && <div className="row justify-content-center">
                    {this.state.data.map(i => {
                        return <div className="col-12 col-md-6 col-xl-4 my-5">

                            <div className={`w-100 position-relative h-100 overflow-hidden bg-neutral-stone-500`} style={{ borderRadius: "32px" }}>
                                <div className="bg-white rounded-5 my-3" style={{ width: "calc(100% - 40px)", margin: "0 auto" }}>
                                    <img className="d-block text-center" style={{ objectFit: "contain", height: "300px", width: "calc(100% - 40px)", margin: "0 auto" }} src={`${i.bank_logo}`} />
                                </div>

                                <div className="mx-3 pb-3 d-flex flex-wrap" style={{ height: "calc(100% - 320px)" }}>
                                    <div className={`w-100 bg-white-500 mb-3 p-3`} style={{ borderRadius: "32px" }}>
                                        <div className="mb-3" >
                                            <p className={`mb-0 n-fs-7 neutral-charcoal`}>{i.title ? i.title : "bank"}</p>
                                            <h4 className={`mb-0 fw-bold n-fs-5 neutral-charcoal`}>{i.title ? `${i.name && i.name} ${i.last_name && i.last_name}` : i.bank_name}</h4>
                                        </div>

                                        <div>
                                            {i.phone && <p className={`neutral-charcoal n-fs-7 mb-0 n-link`}><a href={`tel:${i.phone}`}>{i.phone}</a></p>}
                                            {i.email && <p className={`neutral-charcoal n-fs-7 mb-0 n-link`}><a href={`mailto:${i.email}`}>{i.email}</a></p>}
                                            {i.link && <p className={`neutral-charcoal n-fs-7 mb-0 n-link`}><a href={`${i.link}`}>Hitta kontakt hos banken</a></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/*<Card className="row justify-content-center text-center position-relative bg-neutral-cloud-600 rounded-5 h-100 pb-5 mx-2">
                                <Person className="rounded-circle bg-primary-main position-absolute overflow-hidden">
                                    {i.img ? 
                                        <img src={i.img} class="img-fluid pt-2" style={{scale: "1.3"}} />
                                            :
                                        <img src={`${Window.static_path}img/icons/${i.gender == 0 ? 'user-female.svg' : i.gender == 1 ? 'user-male.svg' : 'user.svg'}`} class="img-fluid tertiary-main-filter pt-4" style={{scale: "1.1"}} />
                                    }
                                </Person>
                        
                                {i.name || i.last_name ? <h5 className="text-uppercase fw-bold mt-3">{i.name && i.name} {i.last_name && i.last_name}</h5>: null}
                                {i.bank_name || i.title ? <p className="grey mb-3">{i.title ? i.title : i.bank_name}</p>: null}

                                {i.bank_logo && <div><img src={i.bank_logo} className="mb-3" style={{maxWidth: "180px", maxHeight: "70px"}} /></div>}
                                {i.link && <div><a href={i.link} target="_blank" className="subtitle primary-dark hover-primary-main-filter"><img src={`${Window.static_path}img/icons/world.svg`} height="19px" className="primary-dark-filter me-1 mb-1" />Hitta kontakt hos banken</a></div>}

                                {i.email && <div><a href={`mailto:${i.email}`} className="subtitle primary-dark hover-primary-main-filter mt-3 mb-1"><img src={`${Window.static_path}img/icons/envelope.svg`} height="19px" className="primary-dark-filter me-1" /> {i.email}</a></div>}
                                {i.phone && <div><a href={`tel:${i.phone}`} className="subtitle primary-dark hover-primary-main-filter"><img src={`${Window.static_path}img/icons/phone.svg`} height="19px" className="primary-dark-filter me-1 mb-1" />{i.phone}</a></div>}
                                </Card>*/}
                        </div>
                    })}
                </div>}
        </div>)
    }
}

export default ForestCataloguePage;