import React from 'react';
import { CalculateSpacing } from '../editor/Helpers';
import MediaSelector from '../editor/fields/MediaSelector';
import * as Opt from '../editor/Helpers'
import RadiusEditor from '../editor/fields/RadiusEditor';

class Image extends React.Component {
    constructor() {
        super();
    }

    render() {
        return <div style={{width:this.props.width, height:this.props.height}} className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} ${this.props.align} ${CalculateSpacing(this.props.spacing)}`}><img style={{width:this.props.width, height:this.props.height}} alt={this.props.alt} src={`${this.props.src}`} className={ `img-fluid ${(Array.isArray(this.props.borderRadius)?this.props.borderRadius:[]).reduce((acum, itm) => acum+itm.radius+" ", "")}`} /></div>
    }
}

Image.defaultProps = {
    visibility: "",
    src: "https://placehold.co/600x400",
    alt: "scenic image",
    align: 'justify-content-start',
    width:"100%",
    height: "100%",
    spacing: [],
    borderRadius: []
}

Image.fields={
    src: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
    },
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
      },
    width: {
        type:"text"
    },
    height: {
        type:"text"
    },
    alt: {
        type:"text"
    },
    align: {
        type: "select",
        options: Opt.contentJustify
    },
    spacing: Opt.spacingOptions,
    borderRadius: {
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    }
}

export default Image;