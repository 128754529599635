import React from 'react';
import Joyride from 'react-joyride';
class HelpOverblick extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            helpMapTools: false,
            steps: [      
                {
                    target: '#edit_volume',
                    content: 'Här kan du redigera volym på alla avdelningar',
                    disableBeacon: true,
                },
               
                {
                    target: '#price_chart',
                    content: 'Du kan klicka på linjerna för att få mer information',
                    disableBeacon: true,
                },
                {
                    target: '#solar-energy',
                    content: 'Här kan du se hur många hektar som är lämplig för solenergiproduktion',
                    disableBeacon: true,
                },
                {
                    target: '#carbon',
                    content: 'Här kan du se hur många ton koldioxid din fastighet innehåller',
                    disableBeacon: true,
                },
                
                {
                    target: '#edit_bonitet',
                    content: 'Här kan du redigera bonitet på alla avdelningar',  
                    disableBeacon: true, 
                },
                {
                    target: '#alder_chart',
                    content: 'Du kan klicka på staplarna för att få mer information',
                    disableBeacon: true,
                },

            ]

        }
    }
    handleJoyrideClose = () => {
        this.props.onClose();
    };

    render() {
        const customLocale = {
            back: 'Tillbaka',
            close: 'Stäng',
            last: 'Kom igång',
            next: 'Nästa',
            open: 'öppna',
            skip: (
                <button style={{borderRadius:'4px', borderColor: 'black', backgroundColor: 'white', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px'}} onClick={this.handleJoyrideClose}>Hoppa över</button>
            )
        };
        return (<React.Fragment>
            <Joyride
                continuous={true}
                floaterProps={{ placement: "left" }}
                steps={this.state.steps}
                close={this.handleJoyrideClose}
                scrollDuration={0}
                showSkipButton={true}
                showProgress={true}
                spotlightClicks={true}
                locale={customLocale}
                styles={{
                    options: {
                        textColor: '#2a2e33',
                        primaryColor:'#023436',
                        overlayColor: 'rgba(0, 0, 0, 0.6)',
                    },
                }}
                callback={(data) => {
                    if (data.status === 'ready') {
                        this.handleJoyrideClose();
                    }
                    if (data.action === 'close') {
                        this.handleJoyrideClose();
                    }
                }} 
                />

        </React.Fragment>)
    }
}
export default HelpOverblick;