import React, { Fragment } from 'react';

import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBCheckbox from '@virkesborsen/vb-js-commons/lib/components/new/vbCheckbox';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import Modal from 'react-bootstrap/Modal';

import { validateEmail, validateForm, userExists, loginUser, registerUser, getKlarnaCheckout } from '../api';
import * as Opt from '../components/editor/Helpers';
import ColorSelector from '../components/editor/fields/ColorSelector';
import LoginFormModal from './LoginFormModal';

class PrimakundForm extends React.Component {
    constructor(props) {
        super();

        let url = new URL(window.location);

        let coupon_code = "";
        let sub_service = props.sub_service ? props.sub_service : "year";

        if (url.searchParams.get("coupon_code")) {
            coupon_code = url.searchParams.get("coupon_code");
        }

        if (url.searchParams.get("subscription_length")) {
            sub_service = url.searchParams.get("subscription_length");
        }

        this.state = {
            isReg: null,
            isLogged: Window.user_data.isLogged,
            isPremium: Window.user_data.isPremium,
            subscription: sub_service,
            coupon: coupon_code,
            formData: {},
            errors: {},
            loading: false,
            errorModal: false,
            loginFormModal:false,
            disableForms: false
        }

        this.timeout = 0;
        this.klarnaCheckout = React.createRef();
        this.container = React.createRef();
    }

    componentDidMount() {
        let url = new URL(window.location);
        if (url.searchParams.get("instant_generate") && this.state.isLogged) {
            getKlarnaCheckout(this.klarnaCheckout.current, this.state.coupon, this.state.subscription).then(() => {
                setTimeout(() => {
                    const klarnaContent = document.getElementById('klarnaContent');
                    klarnaContent.scrollIntoView();
                }, 100);
                
            })
        } else if (url.searchParams.get("load_form")) {
            setTimeout(() => {
                const klarnaContent = document.getElementById('klarnaContent');
                    klarnaContent.scrollIntoView();
            }, 100);
        }
    }

    checkEmail(val) {
        // Check if email is a valid email before checking if the user exists
        if (validateEmail(val)) {
            // clear timeout if the user is still typing before timers is over
            if (this.timeout) { clearTimeout(this.timeout) };

            this.timeout = setTimeout(() => {
                userExists(val).then(resp => {
                    if (resp.ok) {
                        resp.json().then((json) => {
                            this.setState({ isReg: json.exists })
                        })
                    } else {
                        this.setState({ errorModal: true })
                    }
                })
            }, 100)
        }
    }

    
    loginForm() {
        return (<div className="d-flex justify-content-center mt-4">
            <button type="button" className="n-btn btn-forest-green" onClick={()=>{this.setState({loginFormModal:true})}}>Logga in</button>
        </div>)
    }

    loadKlarnaCheckout(coupon, subscription) {
        this.setState({ loading: true })
        getKlarnaCheckout(this.klarnaCheckout.current, coupon, subscription).then((json) => {
            if (json.errors) {
                this.setState({ errors: json.errors })
            } else {
                this.setState({ disableForms: true })
            }
            this.setState({ loading: false })
                setTimeout(() => {
                    const klarnaContent = document.getElementById('klarnaContent');
                    klarnaContent.scrollIntoView();
                }, 400);
        });
    }

    handleKlarnaSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true })

        getKlarnaCheckout(this.klarnaCheckout.current, this.state.coupon, this.state.subscription).then((json) => {
            if (json.errors) {
                this.setState({ errors: json.errors })
            }
            this.setState({ loading: false })
        })
    }

    klarnaForm() {
        return (<form className="row justify-content-center mt-3" onSubmit={(e) => this.handleKlarnaSubmit(e)}>
            <div style={{maxWidth:"513px"}}>
                <div className='col-12'>
                    <VBCheckbox
                        label={<span className="mb-3">Jag har en rabattkod.</span>}
                        initial={false}
                        onClick={() => { this.setState({ hasCouponCode: !this.state.hasCouponCode })}} />

                    {this.state.hasCouponCode && <VBInput
                        placeholder="Rabattkod"
                        initial={this.state.coupon}
                        error={this.state.errors.coupon_code}
                        onFocus={() => this.setState({ errors: { ...this.state.errors, coupon_code: '' } })}
                        onChange={(val) => { this.setState({ coupon: val }); this.klarnaCheckout.current.innerHTML = ""; }} />}
                </div>

                <div className="d-flex justify-content-center align-items-center" style={{marginTop:"40px"}}>
                    <a href='' className="n-btn btn-forest-green" style={{cursor: "pointer"}} onClick={(e)=>{this.handleKlarnaSubmit(e)}}>Uppgradera till Plus<span><img className={`ms-2`} style={{ width: "18px", height: "18px" }} src={Window.static_path+"img/icons/arrow.svg"} /></span></a>
                </div>
            </div>
        </form>)
    }

    render() {
        return (<div className="container">
            <div className="row">
                <div className="col-12">
                    <section ref={this.container} className={`px-3 py-5 bg-${this.props.backgroundColor} ${this.props.textColor}`} style={{ borderRadius: `var(--Radius-16, 64px)`, padding: "80px 64px", gap: "80px", }} id='klarnaContent'>
                    <VBLoadingScreen show_loading={this.state.loading} />
                    <div className={`row justify-content-center`}>
                        <div className="col-12 col-md-10 col-lg-6 text-center">
                            <p className='n-fs-3 fw-medium'>{this.props.title}</p>

                            {!this.state.disableForms ? this.state.isLogged ? <p className="n-fs-6">{this.props.paragraph} </p> : <p className="n-fs-6 mb-0">Du behöver vara inloggad eller registrerad kund för att bli Pluskund. Kom igång nedan.</p> : null}
                        </div>
                    </div>

                    {/* THE SEPERATE CHECKS IS TO DUE A BUG IN EDITING INPUTS ON BOTH FORMS AT THE SAME TIME */}
                    {this.state.isLogged && !this.state.disableForms ? this.klarnaForm() : null}

                    {!this.state.isLogged && !this.state.disableForms ? this.loginForm() : null} 

                    <LoginFormModal
                        visible={this.state.loginFormModal}
                        onHide={() => this.setState({loginFormModal: false})}
                        next_url={window.location.pathname + "?instant_generate=1"}
                        completed={() => {
                            this.setState({isLogged:true,loginFormModal:false, loading:false})                       
                        }}

                        />

                    <div className={`row justify-content-center ${this.props.textColor && this.props.textColor} `}>
                        <div className="col-12 col-md-10 col-lg-8">
                            {this.state.coupon_code == "PRIMASEB" && this.state.isLogged ? <span className='caption'>SEB bjuder dig som använder denna rabattkod på gratis Pluskund dom tre första månaderna. På grund av tekniska begränsningar så kommer det se ut som att du endast fick 1 månad gratis fram tills 2021-06-23 och sen kommer du se den fullständiga längden</span> : null}
                            <div ref={this.klarnaCheckout} className="shadow mt-5"></div>
                        </div>
                    </div>

                    <Modal show={this.state.errorModal} onHide={() => this.setState({ errorModal: false })} contentClassName="bg-neutral-cloud-200 p-2" centered>
                        <Modal.Body>
                            <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
                            <p className='n-fs-5 neutral-charcoal py-3'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal" style={{cursor: "pointer"}}>08-33 99 44</a> så hjälper vi dig vidare.</p>
                            <button type="button" className="n-btn float-end" style={{background:"#FF5B6C", border:"None"}} onClick={() => this.setState({ errorModal: false })}>Fortsätt</button>
                        </Modal.Body>
                    </Modal>
                </section>
                </div>
            </div>
        </div>)
    }
}
PrimakundForm.defaultProps = {
    title: "Uppgradera till Pluskund",
    paragraph: "Har du en rabattkod? Ange nedan innan du går vidare till betaluppgifter.",
    backgroundColor: "neutral-stone-200",
    textColor: "neutral-charcoal",
}

PrimakundForm.fields = {
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
      },
    textColor: {
        type: "select",
        options: Opt.colorVariables
    },
    title: { type: "text" },
    paragraph: { type: "text" },   
}

export default PrimakundForm;