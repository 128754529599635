
const NHPill = (props) => {

    let bg = 'bg-neutral-cloud'
    if(props.bg){
        bg = props.bg
    }

    let font_size = "n-fs-7"
    if(props.font_size){
        font_size = props.font_size
    }

    let font_weight = "fw-medium"
    if(props.font_weight){
        font_weight = props.font_weight
    }

    return <a href={props.link} style={{"cursor":"pointer"}} className={`${bg}-300 hover-${bg}-500 d-inline-flex align-items-center mb-0 px-2 py-1 ${font_size} ${font_weight} corners-1-all ${props?.extra_class}`}>
    <img src={props.icon} style={{height:"18px"}} className="pe-1" />
    <span>{props.text}</span>
    {props.delete ? <span className="ps-2 n-fs-6 semantic-danger-500" onClick={props.delete}>✖</span> : null}
    </a>;
}

export default NHPill;