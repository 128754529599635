import React from 'react'
import PropTypes from "prop-types";

function ChartSection({ title, description, component, children, page }) {
    if (page === 'sbp') {
        return (
            <div className='container-fluid'>
                <div className='flex-row d-flex justify-content-between primary-dark'>
                    <div className='col-9'>
                        {title}
                        <p className='subtitle'>{description}</p>
                    </div>
                    {component && <div className='col-3 justify-content-start justify-content-lg-center align-items-center'>
                        {component}
                    </div>}
                </div>
                {children && <div className='container-fluid'>
                    {children}
                </div>}
            </div>
        )

    }
    else {
        return (
            <div className='container position-relative primary-dark'>
                <h2 style={{ zIndex: 1 }}>{title}</h2>
                <div className='row justify-content-between'>
                    <div className='col-12 col-lg-6 py-5' style={{ zIndex: 1 }}>
                        <p className='subtitle'>{description}</p>
                    </div>
                    {component && <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
                        {component}
                    </div>}
                </div>
                {children}
            </div>
        )
    }

}

ChartSection.propTypes = {
    title: PropTypes.string || PropTypes.node,
    description: PropTypes.string || PropTypes.node,
    component: PropTypes.node,
    children: PropTypes.node,
};

ChartSection.defaultProps = {
    title: undefined,
    description: undefined,
    component: undefined,
    children: undefined
}

export default ChartSection;