import React from 'react';
import * as Opt from '../editor/Helpers';
import ColorSelector from '../editor/fields/ColorSelector';

/* This is for blog's social media */

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleCopyLinkClick = this.handleCopyLinkClick.bind(this);
    this.handleShareFacebookClick = this.handleShareFacebookClick.bind(this);
    this.handleShareTwitterClick = this.handleShareTwitterClick.bind(this);
    this.handleShareLinkedInClick = this.handleShareLinkedInClick.bind(this);

  }

  handleCopyLinkClick() {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        alert('URL copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy URL: ', error);
      });
  }

  handleShareFacebookClick() {
    // Open Facebook Share Dialog
    window.FB.ui({
      method: 'share',
      href: window.location.href,
    }, function(response){});
  }

  handleShareTwitterClick() {
    // Open Twitter Web Intent for sharing
    const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`;
    window.open(tweetUrl, '_blank');
  }

  handleShareLinkedInClick() {
    // Open LinkedIn Share Dialog
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`;
    window.open(linkedinUrl, '_blank');
  }

  handleShareInstagramClick() {
    const url = window.location.href;
    const instagramUrl = `https://www.instagram.com/?url=${encodeURIComponent(url)}`;
    window.open(instagramUrl, '_blank');
  }

  render() {
    return (
      <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'} p-0 bg-${this.props.backgroundColor}`}>
        <div className={`container`}>
          <div className="row">
            <div className='col-12'>
              <p className={`justify-content-start n-fs-5 fw-medium ${this.props.headerColor}`}>{this.props.header}</p>
              <div className='d-flex justify-content-start align-items-center'>
                {/* copy link icon */}
                <a className={`me-2 rounded-circle bg-${this.props.backgroundIconsColor} d-flex align-items-center justify-content-center`} target="_blank" onClick={this.handleCopyLinkClick} style={{ cursor: "pointer", width:'40px', height: '40px' }}>
                  <img width="20px" height="20px" src={`${Window.static_path}img/social-media/copylink.svg`} className={`max-h-50 ${this.props.iconsColor}`} />
                </a>
                {/* share on LinkedIn */}
                <a className={`me-2 rounded-circle bg-${this.props.backgroundIconsColor} d-flex align-items-center justify-content-center`} target="_blank" style={{ cursor: "pointer", width:'40px', height: '40px' }} onClick={this.handleShareLinkedInClick}>
                  <img width="20px" height="20px" src={`${Window.static_path}img/social-media/blackLinkedIn.svg`} className={`max-h-50 ${this.props.iconsColor}`} />
                </a>
                {/* share on Twitter */}
                <a className={`me-2 rounded-circle bg-${this.props.backgroundIconsColor} d-flex align-items-center justify-content-center `} target="_blank" style={{ cursor: "pointer", width:'40px', height: '40px' }} onClick={this.handleShareTwitterClick}>
                  <img width="20px" height="17px" src={`${Window.static_path}img/social-media/blackX.svg`} className={`max-h-50 ${this.props.iconsColor}`} />
                </a>
                {/* share on FB */}
                <a href="https://www.facebook.com/sharer/sharer.php?u=example.org" className={`me-2 rounded-circle bg-${this.props.backgroundIconsColor} d-flex align-items-center justify-content-center`} target="_blank" style={{ cursor: "pointer", width:'40px', height: '40px' }}>
                  <img width="28px" height="28px" src={`${Window.static_path}img/social-media/NewFb.svg`} className={`max-h-50 ${this.props.iconsColor}`} />
                </a>
                {/* share on Ig */}
                <a className={`me-2 rounded-circle bg-${this.props.backgroundIconsColor} d-flex align-items-center justify-content-center`} target="_blank" style={{ cursor: "pointer", width:'40px', height: '40px' }} onClick={this.handleShareInstagramClick}>
                  <img width="24px" height="24px" src={`${Window.static_path}img/social-media/IgNew.svg`} className={`max-h-50 ${this.props.iconsColor}`} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SocialMedia.defaultProps = {
  visibility: "",
  backgroundColor: 'white-500',
  header: 'Share this post',
  headerColor: "neutral-charcoal",
  iconsColor: "neutral-charcoal-filter",
  backgroundIconsColor: "neutral-cloud",
}

SocialMedia.fields = {
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  header: { type: "text" },
  headerColor: {
    type: "select",
    options: Opt.colorVariables
  },
  iconsColor: {
    type: "select",
    options: Opt.filterOptions
  },
  backgroundIconsColor: {
    type: "select",
    options: Opt.filterOptions
  },
}

export default SocialMedia;